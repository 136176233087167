import { Box } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import { Button, TextField } from "../../../../common";
import { useValidateForm } from "../../../../../hooks";
import { SelectBox } from "../../../../common";
import { AccountProfileInfo } from "../../../../../types";
import { editAccountProfileBodySchema } from "../../../../../validations";
import { useState } from "react";
import { isCreateAccountModeAtom } from "../../../../../atoms";
import { useAtom } from "jotai";
import { STATES } from "../../../../../constants";

type EditAccountProfileBodyProps = {
  defaultValues?: AccountProfileInfo;
  handleEdit: (
    data: Omit<AccountProfileInfo, "logo" | "accountName" | "accountId">
  ) => void;
  handleDiscard: () => void;
};

export const EditAccountProfileBody = ({
  defaultValues,
  handleEdit,
  handleDiscard,
}: EditAccountProfileBodyProps) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [isCreateAccountMode] = useAtom(isCreateAccountModeAtom);

  const { register, handleSubmit, errors } = useValidateForm(
    editAccountProfileBodySchema
  );

  const handleChange = () => {
    setHasChanges(true);
  };

  return (
    <Box
      onSubmit={handleSubmit(handleEdit)}
      component={"form"}
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        direction={{ lg: "row", xs: "column" }}
        sx={{
          width: "100%",
          height: "100%",
          px: 3,
          py: 3,
          alignItems: "center",
        }}
      >
        <Grid
          container
          size={{ lg: 5, xs: 12 }}
          gap={3}
          sx={{
            px: { md: 4, xs: 0, sm: 2 },
            py: 0,
            flexDirection: "column",
          }}
        >
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues?.contactPerson}
              label="Contact Person"
              name="contactPerson"
              error={errors["contactPerson"]}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues?.email}
              label="Email"
              name="email"
              error={errors["email"]}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              type="number"
              register={register}
              defaultValue={defaultValues?.contactNumber}
              label="Contact Number"
              name="contactNumber"
              error={errors["contactNumber"]}
              onChange={handleChange}
              allowScientificNotation={false}
              required
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={
                defaultValues?.website !== "N/A"
                  ? defaultValues?.website
                  : undefined
              }
              label="Website (Optional)"
              name="website"
              error={errors["website"]}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          size={{ lg: 5, xs: 12 }}
          gap={3}
          sx={{
            px: { md: 4, xs: 0, sm: 2 },
            py: 0,
            flexDirection: "column",
          }}
        >
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues?.addressLine1}
              label="Address Line 1"
              name="addressLine1"
              error={errors["addressLine1"]}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={
                defaultValues?.addressLine2 !== "N/A"
                  ? defaultValues?.addressLine2
                  : undefined
              }
              label="Address Line 2 (Optional)"
              name="addressLine2"
              error={errors["addressLine2"]}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            container
            size={12}
            sx={{
              flexDirection: "row",
            }}
          >
            <Grid size={{ sm: 4, xs: 12 }}>
              <TextField
                register={register}
                defaultValue={defaultValues?.city}
                label="City"
                name="city"
                error={errors["city"]}
                onChange={handleChange}
                required
                helperTextSx={{
                  whiteSpace: "nowrap !important",
                }}
              />
            </Grid>
            <Grid size={{ sm: 4, xs: 12 }}>
              <SelectBox
                register={register}
                defaultValue={defaultValues?.state}
                label="State"
                name="state"
                dropdown={STATES}
                error={errors["state"]}
                onChange={handleChange}
                required
                helperTextSx={{
                  whiteSpace: "nowrap !important",
                }}
              />
            </Grid>
            <Grid size={{ sm: 4, xs: 12 }}>
              <TextField
                type="number"
                register={register}
                defaultValue={defaultValues?.zip}
                label="Zip"
                name="zip"
                error={errors["zip"]}
                onChange={handleChange}
                required
                helperTextSx={{
                  whiteSpace: "nowrap !important",
                }}
              />
            </Grid>
          </Grid>
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues?.instruction}
              label="Instructions"
              name="instruction"
              error={errors["instruction"]}
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
        <Grid
          container
          size={{ xs: 12, lg: 2 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            pl: { md: 4, xs: 0, sm: 2, lg: 0 },
            pr: { md: 4, xs: 0, sm: 2, lg: 2 },
            pt: { lg: 0, xs: 2 },
            pb: 0,
            height: { lg: "100%", xs: "fit-content" },
          }}
        >
          {!isCreateAccountMode && (
            <Button
              label="Discard"
              variantType={"secondary"}
              sx={{
                padding: "0.5rem",
                width: "100%",
              }}
              onClick={handleDiscard}
            />
          )}
          <Button
            type={hasChanges ? "submit" : undefined}
            disabled={!hasChanges || isCreateAccountMode}
            label="Save Changes"
            variantType={
              hasChanges && !isCreateAccountMode ? "primary" : "secondary"
            }
            sx={{
              padding: "0.5rem",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
