import { Chip, ChipProps, SvgIconProps } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import { InvoicePaymentStatus } from "../../../enums";

type InvoicePaymentStatusChipProps = ChipProps & {
  status?: InvoicePaymentStatus;
};

type InvoicePaymentStatusChipIconProps = SvgIconProps & {
  status?: InvoicePaymentStatus;
};

const InvoicePaymentStatusChipIcon: React.FC<
  InvoicePaymentStatusChipIconProps
> = ({ status, ...props }) => {
  if (status === InvoicePaymentStatus.Overdue) {
    return <ErrorOutlineIcon {...props} />;
  }

  if (status === InvoicePaymentStatus.Pending) {
    return <AccessAlarmIcon {...props} />;
  }

  if (status === InvoicePaymentStatus.Paid) {
    return <CheckCircleOutlineIcon {...props} />;
  }
};

export const InvoicePaymentStatusChip: React.FC<
  InvoicePaymentStatusChipProps
> = ({ status = InvoicePaymentStatus.Paid, ...props }) => {
  const getColor = (status: InvoicePaymentStatus) => {
    if (
      status === InvoicePaymentStatus.Overdue ||
      status === InvoicePaymentStatus.Pending
    ) {
      return "#eb4040";
    } else if (status === InvoicePaymentStatus.Paid) {
      return "#40aa0e";
    }
  };

  const getBgColor = (status: InvoicePaymentStatus) => {
    if (
      status === InvoicePaymentStatus.Overdue ||
      status === InvoicePaymentStatus.Pending
    ) {
      return "#ffd7d7";
    } else if (status === InvoicePaymentStatus.Paid) {
      return "#dbff8e";
    }
  };

  return (
    <Chip
      {...props}
      sx={{
        borderRadius: 0,
        color: getColor(status),
        fontWeight: 600,
        backgroundColor: getBgColor(status),
        textTransform: "capitalize",
      }}
      label={status}
      icon={
        <InvoicePaymentStatusChipIcon
          status={status}
          sx={{ color: `${getColor(status)} !important` }}
        />
      }
    />
  );
};
