import React, { useEffect, useState } from "react";
import { FleetForm } from "../../fleet";
import { Modal } from "./Modal";
import { FleetInfo } from "../../../types";
import { useValidateForm } from "../../../hooks";
import { createFleetSchema } from "../../../validations";
import { Box } from "@mui/material";

type AddOrEditFleetModalProps = {
  open: boolean;
  title: string;
  fleet?: FleetInfo;
  primaryButtonText: string;
  onClose: () => void;
  onSave: (fleet: FleetInfo | undefined) => void;
};

export const AddOrEditFleetModal: React.FC<AddOrEditFleetModalProps> = ({
  open,
  title,
  fleet,
  primaryButtonText,
  onClose,
  onSave,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { register, handleSubmit, errors, reset } =
    useValidateForm(createFleetSchema);

  useEffect(() => {
    setIsModalOpen(open);
    reset();
  }, [open]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
    reset();
  };

  const handleSecondaryButtonClick = () => {
    handleCloseModal();
  };

  const handleCreate = (data: any) => {
    onSave({ ...data, accountFleetId: fleet?.accountFleetId });
  };

  return (
    <Box
      onSubmit={handleSubmit(handleCreate)}
      component={"form"}
      id="fleetForm"
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={title}
        content={
          <FleetForm register={register} errors={errors} fleet={fleet} />
        }
        form={"fleetForm"}
        primaryButtonText={primaryButtonText}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={handleSecondaryButtonClick}
        sx={{
          modal: { width: { lg: "40%", xs: "80%", sm: "50%" } },
        }}
      />
    </Box>
  );
};
