import {
  Navigate,
  Outlet,
  Route as ReactRoute,
  Routes,
} from "react-router-dom";
import { Route } from "../types";

type RouterProps = {
  routes?: Route[];
  errorRoute?: Route;
  errorRouteIgnore?: Route[];
} & (
  | { redirectRoutes?: undefined; redirectTo?: undefined }
  | { redirectRoutes: Route[]; redirectTo: string }
);

export const Router = ({
  routes,
  errorRoute,
  errorRouteIgnore,
  redirectRoutes,
  redirectTo,
}: RouterProps) => {
  const renderChildRoutes = (index: any, childRoutes: Route[]) => {
    return childRoutes.map((childRoute, childIndex) => {
      return (
        <ReactRoute key={`${index}_child_${childIndex}`} path={childRoute.path}>
          <ReactRoute index element={childRoute.component} />
          {childRoute.childRoutes &&
            renderChildRoutes(childIndex, childRoute.childRoutes)}
        </ReactRoute>
      );
    });
  };

  return (
    <Routes>
      {routes &&
        routes.map(({ childRoutes, ...route }, index) => {
          return (
            <ReactRoute key={index} path={route.path} element={<Outlet />}>
              <ReactRoute index element={route.component} />
              {childRoutes && renderChildRoutes(index, childRoutes)}
            </ReactRoute>
          );
        })}
      {redirectRoutes &&
        redirectTo &&
        redirectRoutes.map((route, index) => {
          return (
            <ReactRoute
              key={index}
              path={route.path}
              element={<Navigate to={redirectTo} />}
            />
          );
        })}
      {errorRoute && (
        <>
          {errorRouteIgnore &&
            errorRouteIgnore.map((route, index) => {
              return (
                <ReactRoute key={index} path={route.path} element={<></>} />
              );
            })}
          <ReactRoute path={errorRoute.path} element={errorRoute.component} />
        </>
      )}
    </Routes>
  );
};
