import { DropdownItem, WashType } from "../types";

export class WashTypeModel {
  public static buildWashTypeDropDown(
    washTypes: WashType[] | undefined
  ): DropdownItem[] {
    if (!washTypes) {
      return [];
    }
    return washTypes.map((washType) => ({
      key: washType.name,
      value: washType.id,
    }));
  }
}
