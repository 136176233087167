import React from "react";
import {
  isCreateOrganizationModeAtom,
  organizationDetailSelectedAtom,
  selectedOrgIdAtom,
} from "../../atoms";
import { useAtom } from "jotai";
import { OrganizationDetail } from "./OrganizationDetail";
import { OrganizationLayout } from "./OrganizationLayout";

export const Organization = () => {
  const [isOrganizationDetailSelected] = useAtom(
    organizationDetailSelectedAtom
  );
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [isCreateOrganizationMode] = useAtom(isCreateOrganizationModeAtom);

  if (
    (isOrganizationDetailSelected && selectedOrgId) ||
    isCreateOrganizationMode
  ) {
    return <OrganizationDetail />;
  } else {
    return <OrganizationLayout />;
  }
};
