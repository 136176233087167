import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { SearchIcon } from "../icons";
import { MdKeyboardArrowRight } from "react-icons/md";
import { theme } from "../../utils";

const SearchBase = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  lineHeight: 0,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    lineHeight: 0,
  },
}));

type SearchProps = {
  handleSearch?: React.MouseEventHandler;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Search = ({ handleSearch, onInputChange }: SearchProps) => {
  return (
    <>
      <SearchBase
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          sx={{
            lineHeight: 0,
            px: 0,
            borderBottom: "1px solid #686868",
            transform: "translateY(1px)",
          }}
          onChange={onInputChange}
        />
        {handleSearch && (
          <Box
            onClick={handleSearch}
            sx={{
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              height: "25px",
              width: "25px",
            }}
          >
            <MdKeyboardArrowRight
              style={{
                fontSize: "25px",
                color: "white",
              }}
            />
          </Box>
        )}
      </SearchBase>
    </>
  );
};
