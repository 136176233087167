import { env } from "./env-loader";

export const config = {
  environment: process.env.NODE_ENV || "development",
  api: {
    baseUrl: env("REACT_APP_BASE_URL", "https://dev-api.sprayzapp.com"),
    authService: {
      login: "/auth-service/v2/login",
      logoff: "/auth-service/v2/logoff",
    },
    accountService: {
      accountLogoUploadSignedUrl:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/files/{fileName}/logo-upload-url",
      bulkDeleteAccountMiscCharges:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/miscellaneous-charges",
      bulkDeleteAccountWashCharges:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-charges",
      createAccount: "account-service-new/v2/organizations/{orgId}/accounts",
      createAccountContact:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/contacts",
      createAccountFinancial:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/financial",
      createAccountFleet:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/fleets",
      createAccountLocation:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations",
      createAccountMiscCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/miscellaneous-charges",
      createWashTypeSplInstruction:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-charge-special-instruction",
      deleteAccount:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}",
      deleteAccountLocation:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}",
      deleteAccountMiscCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/miscellaneous-charges/{washTypeId}",
      deleteAccountWashCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-charges/{washTypeId}",
      deleteAccountFleet:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/fleets",
      deleteContact:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/contacts/{contactId}",
      getAccountInfo:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/info",
      getAccountFleets:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/fleets",
      getAccountLocations:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations",
      getAccountWashMiscCharges:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-misc-charges",
      getAllAccounts: "account-service-new/v2/organizations/{orgId}/accounts",
      updateAccountFinancial:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/financial",
      updateAccountFleet:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/fleets/{accountFleetId}",
      updateAccountLocation:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}",
      updateAccountMiscCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/miscellaneous-charges/{washTypeId}",
      updateAccountWashCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-charges/{washTypeId}",
      updateContact:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/contacts/{contactId}",
      updateProfileAndAddress:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/profile-address",
      updateTripMinimum:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/trip-minimum",
      uploadFleet:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/fleet-upload-url",
      uploadStatus:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/fleet-upload-status",
    },
    invoiceService: {
      createInvoiceNote:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/notes",
      downloadInvoicesCsv:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/download-invoices-csv",
      getAllInvoices:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices",
      getInvoiceNotes:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/notes",
      getInvoiceOverview:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}",
      previewInvoice:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/preview",
    },
    organizationService: {
      createOrganisation: "organisation-service/v2/organizations",
      deleteOrganisation: "organisation-service/v2/organizations/{orgId}",
      getAllOrganizations: "organisation-service/v2/organizations",
      getOrganisation: "organisation-service/v2/organizations/{orgId}",
      getOrgLogoUploadSignedUrl:
        "organisation-service/v2/organizations/{orgId}/files/{fileName}/logo-upload-url",
      updateOrganisation: "organisation-service/v2/organizations/{orgId}",
      updateOrganisationAddress:
        "organisation-service/v2/organizations/{orgId}/address",
    },
    reportService: {
      getSuperAdminDashboard:
        "report-service/v2/organizations/{orgId}/dashboard",
      getUserDashboard:
        "report-service/v2/organizations/{orgId}/accounts/{accountId}/dashboard",
    },
    userService: {
      getAllLeads: "user-service/v2/organizations/{orgId}/leads",
      getUser: "user-service/v2/organizations/{orgId}/users/{userId}",
      updateUser: "user-service/v2/organizations/{orgId}/users/{userId}",
      uploadProfilePic:
        "user-service/v2/organizations/{orgId}/users/{userId}/files/{fileName}/profile-pic-upload-url",
      updateUserProfilePic:
        "user-service/v2/organizations/{orgId}/users/{userId}/profile-pic",
      changePassword:
        "user-service/v2/organizations/{orgId}/users/{id}/change-password",
      forgotPassword: "user-service/v2/forgot-password",
      validateResetPasswordToken:
        "user-service/v2/is-password-reset-token-valid",
      resetPassword:
        "user-service/v2/organizations/{orgId}/users/{id}/change-password-using-token",
    },
    washInventoryService: {
      getAllWashes:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/washes",
      downloadWashesCsv:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/download-washes-csv",
      updateWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-inventories/{id}",
      deleteWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/wash-inventories/{id}",
      deleteWashInventories:
        "wash-inventory-service/v2/organizations/{orgId}/wash-inventories",
      bulkUpdateWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/wash-inventories",
    },
    washTypeService: {
      getAllMiscellaneousWashTypes:
        "wash-type-service/v2/organizations/{orgId}/miscellaneous-wash-types",
      getAllWashTypes: "wash-type-service/v2/organizations/{orgId}/wash-types",
    },
  },
  images: {
    logo: {
      baseUrl: "https://sprayzapp-images.s3.us-west-2.amazonaws.com/",
      default: "1/409/default.png",
    },
  },
};
