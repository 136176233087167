import React, { ChangeEventHandler, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  DataGridProps,
  GridPaginationModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { DataGridFooter } from "./fragments";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    addBtnLabel: string;
    removeBtnLabel: string;
    hasAddIcon?: boolean;
    hasRemoveIcon?: boolean;
    disableAddBtn?: boolean;
    disableRemoveBtn?: boolean;
    confirmationModalTitle?: string;
    confirmationModalMessage?: string;
    totalRecords: number;
    paginationModel: GridPaginationModel;
    handleChangePage: (
      event: React.MouseEvent<HTMLButtonElement> | null,
      page: number
    ) => void;
    handleChangeRowsPerPage: ChangeEventHandler<
      HTMLTextAreaElement | HTMLInputElement
    >;
    rowsPerPageOptions: any;
    onAddButtonClick?: () => void;
    handleConfirmRemove?: () => void;
  }
}

type FooterProps = {
  addBtnLabel?: string;
  removeBtnLabel?: string;
  hasAddIcon?: boolean;
  hasRemoveIcon?: boolean;
  disableAddBtn?: boolean;
  disableRemoveBtn?: boolean;
  confirmationModalTitle?: string;
  confirmationModalMessage?: string;
  onAddButtonClick?: () => void;
  handleConfirmRemove?: () => void;
};

interface DataGridDemoProps extends DataGridProps {
  columns: GridColDef[];
  rows: any[];
  loading?: boolean;
  paginationModel: GridPaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>;
  pageSize?: number;
  pageSizeOptions?: number[];
  totalRecords?: number;
  footerProps?: FooterProps;
  onSelectedRowIdsChange?: (selectedIds: any[]) => void;
  onSelectedRowsChange?: (rows: any[]) => void;
}

export const DataGrid: React.FC<DataGridDemoProps> = ({
  columns,
  rows,
  loading = false,
  pageSizeOptions = [10, 15, 25, 50, 100],
  totalRecords,
  footerProps,
  paginationModel,
  setPaginationModel,
  onSelectedRowIdsChange,
  onSelectedRowsChange,
  sx,
  ...props
}) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    pageNumber: number
  ) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: pageNumber,
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
    }));
  };

  const handleRowSelectionChange = (ids: GridRowSelectionModel) => {
    setSelectionModel(ids);
    const selectedIds = ids.map((id) => id);
    const selectedIdsSet = new Set(selectedIds);
    const selectedRows = rows.filter((row) => selectedIdsSet.has(row.id));

    if (onSelectedRowIdsChange) {
      onSelectedRowIdsChange(selectedIds);
    }

    if (onSelectedRowsChange) {
      onSelectedRowsChange(selectedRows);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <MuiDataGrid
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          ...sx,
        }}
        {...props}
        rows={rows}
        columns={columns}
        loading={loading}
        paginationModel={paginationModel}
        pageSizeOptions={pageSizeOptions}
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleRowSelectionChange}
        slots={{
          footer: DataGridFooter,
        }}
        slotProps={{
          footer: {
            handleChangePage,
            handleChangeRowsPerPage,
            totalRecords: totalRecords ? totalRecords : rows.length,
            rowsPerPageOptions: pageSizeOptions,
            paginationModel,
            ...footerProps,
          },
        }}
      />
    </Box>
  );
};
