export const TrashIcon = ({ width = "15", height = "19" }) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.92857 5V16C1.92857 17.1046 2.76004 18 3.78571 18H11.2143C12.24 18 13.0714 17.1046 13.0714 16V5M1.92857 5H1M1.92857 5H3.78571M13.0714 5H14M13.0714 5H11.2143M3.78571 5V3C3.78571 1.89543 4.61718 1 5.64286 1H9.35714C10.3828 1 11.2143 1.89543 11.2143 3V5M3.78571 5H11.2143M5.64286 9V14M9.35714 9V14"
        stroke="#686868"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
