import { Box } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import { useState } from "react";
import { CustomerInfo } from "../../../../types";
import { useValidateForm } from "../../../../hooks";
import { editUserProfileBodySchema } from "../../../../validations/schema";
import { Button, TextField } from "../../../common";
import Loading from "../../../common/loading/Loading";

type EditUserProfileInfoProps = {
  defaultValues: CustomerInfo;
  loading: boolean;
  handleEdit: (
    data: Pick<
      CustomerInfo,
      "firstName" | "lastName" | "emailId" | "phoneNumber"
    >
  ) => void;
  handleDiscard: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditUserProfileInfo = ({
  defaultValues,
  loading,
  handleEdit,
  handleDiscard,
}: EditUserProfileInfoProps) => {
  const [hasChanges, setHasChanges] = useState(false);

  const { register, handleSubmit, errors } = useValidateForm(
    editUserProfileBodySchema
  );

  const handleChange = () => {
    setHasChanges(true);
  };

  return (
    <Box
      onSubmit={handleSubmit(handleEdit)}
      component={"form"}
      noValidate
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Grid
        container
        spacing={2}
        direction={{ lg: "row", xs: "column" }}
        sx={{
          width: "100%",
          height: "100%",
          px: 3,
          py: 3,
          alignItems: "center",
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? "none" : "auto",
        }}
      >
        <Grid
          container
          size={{ lg: 5, xs: 12 }}
          gap={3}
          sx={{
            px: { md: 4, xs: 0, sm: 2 },
            py: 0,
            flexDirection: "column",
          }}
        >
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues.firstName}
              label="First Name"
              name="firstName"
              error={errors["firstName"]}
              onChange={handleChange}
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues.emailId}
              label="Email"
              name="emailId"
              error={errors["emailId"]}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          size={{ lg: 5, xs: 12 }}
          gap={3}
          sx={{
            px: { md: 4, xs: 0, sm: 2 },
            py: 0,
            flexDirection: "column",
          }}
        >
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues.lastName}
              label="Last Name"
              name="lastName"
              error={errors["lastName"]}
              onChange={handleChange}
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues.phoneNumber}
              label="Phone Number"
              name="phoneNumber"
              error={errors["phoneNumber"]}
              onChange={handleChange}
              allowScientificNotation={false}
            />
          </Grid>
        </Grid>
        <Grid
          container
          size={{ xs: 12, lg: 2 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            pl: { md: 4, xs: 0, sm: 2, lg: 0 },
            pr: { md: 4, xs: 0, sm: 2, lg: 2 },
            pt: { lg: 0, xs: 2 },
            pb: 0,
            height: { lg: "100%", xs: "fit-content" },
          }}
        >
          <Button
            label="Discard"
            variantType={"secondary"}
            sx={{
              padding: "0.5rem",
              width: "100%",
            }}
            onClick={() => {
              handleDiscard(false);
            }}
          />
          <Button
            type={hasChanges ? "submit" : undefined}
            disabled={!hasChanges}
            label="Save Changes"
            variantType={hasChanges ? "primary" : "secondary"}
            sx={{
              padding: "0.5rem",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
      {loading && (
        <Loading
          message=""
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        />
      )}
    </Box>
  );
};
