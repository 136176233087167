import { GridColDef } from "@mui/x-data-grid";

export const exportBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100);
};

export const convertToCSV = (
  columns: GridColDef[],
  rows: any[],
  excludedHeaders: string[] = []
): string => {
  const filteredColumns = columns.filter((col) => {
    const headerName = col.headerName;
    return headerName && !excludedHeaders.includes(headerName);
  });

  const header = filteredColumns.map((col) => col.headerName).join(",") + "\n";
  const csvRows = rows
    .map((row) => filteredColumns.map((col) => row[col.field]).join(","))
    .join("\n");

  return header + csvRows;
};

export const cleanString = (data: string, replaceWith: string = "_") => {
  return data.trim().replace(/\s+/g, replaceWith);
};

export const searchObjects = <T extends Record<string, any>>(
  arrayOfObjects: T[],
  excludeColumns: string[],
  searchKey: string
): T[] => {
  const trimmedSearchKey = searchKey.toLowerCase().trim();

  return arrayOfObjects.filter((item) => {
    return Object.keys(item).some((key) => {
      if (excludeColumns.includes(key)) return false;
      const value = item[key];
      return (
        value !== null &&
        value !== undefined &&
        value.toString().toLowerCase().includes(trimmedSearchKey)
      );
    });
  });
};

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
