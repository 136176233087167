import React from "react";
import { useAtom } from "jotai";
import { userAtom } from "../../../atoms";
import { Box } from "@mui/material";
import { ChangeUserPasswordModal } from "./fragments";
import { useNavigate } from "react-router-dom";
import { LoginRoute } from "../../../routes";

export const ChangePassword: React.FC = () => {
  const [user, setUser] = useAtom(userAtom);
  const navigate = useNavigate();
  const handleSuccess = () => {
    if (user) {
      setUser({ ...user, isPasswordReset: false });
      navigate(LoginRoute.path);
    }
  };

  return (
    <Box>
      <ChangeUserPasswordModal
        open={true}
        onClose={() => {}}
        onSuccess={handleSuccess}
        hideBackdrop={true}
      />
    </Box>
  );
};
