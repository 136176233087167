import {
  Box,
  Card,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AccountStatusChip } from "../chips";
import { TrashIcon } from "../../icons";
import { BusinessInfo } from "../../../types";

type BusinessInfoTileProps = {
  data: BusinessInfo;
  handleClick?: (id: any) => any;
  handleDelete?: (id: any) => any;
};

export const BusinessInfoTile = ({
  data,
  handleClick,
  handleDelete,
}: BusinessInfoTileProps) => {
  const { id, name, contactPerson, contactNumber, status, logo } = data;

  const isScreenSize1500 = useMediaQuery("(max-width:1500px)");

  return (
    <Card
      sx={{
        width: "100%",
        paddingX: "2rem",
        paddingTop: "1rem",
        paddingBottom: "0.938rem",
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        ":hover": {
          cursor: "pointer",
          boxShadow: "0px 0px 8px 0px #00000040",
        },
      }}
      onClick={() => {
        handleClick && handleClick(id);
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            paddingBottom: "0.3rem",
            fontWeight: "700",
            fontSize: "16px",
            width: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {name && (
            <Tooltip title={name} arrow placement="top">
              <div style={{ width: "fit-content" }}>{name}</div>
            </Tooltip>
          )}
        </Typography>
        <Box
          onClick={(event) => {
            event.stopPropagation();
            handleDelete && handleDelete(id);
          }}
        >
          <TrashIcon />
        </Box>
      </Box>
      <Divider
        sx={{ padding: 0, borderBottomWidth: "1px", borderColor: "#A1A1A1" }}
      />
      <Grid
        size={12}
        spacing={3}
        container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: "1rem",
        }}
      >
        <Grid
          size={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isScreenSize1500 && { flex: 1 }),
          }}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              width: isScreenSize1500 ? "80px" : "90px",
              height: isScreenSize1500 ? "80px" : "90px",
              objectFit: "contain",
              padding: "0.2rem 0rem",
            }}
            alt="logo"
          />
        </Grid>
        <Grid
          size={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            ...(isScreenSize1500 && { flex: 1 }),
          }}
        >
          <Box sx={{ overflow: "hidden" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                width: "fit-content",
              }}
            >
              {contactPerson && (
                <Tooltip title={contactPerson} arrow placement="top">
                  <div style={{ width: "fit-content" }}>{contactPerson}</div>
                </Tooltip>
              )}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                whiteSpace: "nowrap",
                fontSize: "1rem",
              }}
            >
              {contactNumber && (
                <Tooltip title={contactNumber} arrow placement="top">
                  <div style={{ width: "fit-content" }}>{contactNumber}</div>
                </Tooltip>
              )}
            </Typography>
          </Box>
          <Box>
            <AccountStatusChip status={status} />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
