import { InvoiceNote, Message } from "../types";

export class InvoiceModel {
  public static buildInvoiceNote(invoiceNote: InvoiceNote): Message {
    return {
      text: invoiceNote.message,
      isOwn: invoiceNote.isOwn,
      timestamp: invoiceNote.createdDate,
      userName: invoiceNote.fullName,
      profilePic: invoiceNote.profilePic,
    };
  }

  public static buildInvoiceNotes(invoiceNotes: InvoiceNote[]): Message[] {
    return invoiceNotes.map((item) => this.buildInvoiceNote(item));
  }
}
