import { Box, Divider, Grid2 as Grid, Typography } from "@mui/material";
import { EditIcon, TrashIcon2 as TrashIcon } from "../../icons";
import { AccountLocation } from "../../../types";

type LocationItemProps = {
  data: AccountLocation;
  allowDelete?: boolean;
  handleEdit: (location: AccountLocation) => void;
  handleDelete: (location: AccountLocation) => void;
};

export const LocationItem = ({
  data,
  allowDelete = true,
  handleEdit,
  handleDelete,
}: LocationItemProps) => {
  return (
    <Grid
      container
      size={12}
      direction={"column"}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        p: 4,
        width: "100%",
        mb: 3,
      }}
      gap={3}
    >
      <Grid
        size={12}
        container
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Grid size={9}>
          <Typography textAlign={"left"}>{data.locationName}</Typography>
        </Grid>
        <Grid
          size={3}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 4,
          }}
        >
          <Box
            onClick={() => {
              handleEdit(data);
            }}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <EditIcon width={"21px"} active={true} />
          </Box>
          {allowDelete && (
            <Box
              onClick={() => {
                handleDelete(data);
              }}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              <TrashIcon width={"21px"} active={true} />
            </Box>
          )}
        </Grid>
        <Divider sx={{ width: "100%" }} />
      </Grid>
      <Grid size={{ lg: 6, md: 12, xs: 12 }}>
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "17px",
            color: "#000",
          }}
        >
          {data.addressLine1}
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "17px",
            color: "#000",
          }}
        >
          {data.addressLine2}
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "17px",
            color: "#000",
          }}
        >
          {data.city}, {data.state} {data.zip}
        </Typography>
      </Grid>
      <Grid size={{ lg: 9, md: 12, xs: 12 }}>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "left",
          }}
        >
          <span style={{ color: "#000" }}>Instructions: </span>
          {data.instruction && data.instruction.trim() !== ""
            ? data.instruction
            : "N/A"}
        </Typography>
      </Grid>
    </Grid>
  );
};
