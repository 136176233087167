import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Typography } from "@mui/material";

type ProgressComponentProps = {
  progressText: string;
  progress: number;
};

export const ProgressComponent: React.FC<ProgressComponentProps> = ({
  progress,
  progressText,
}) => {
  const showUpArrow = progress > 0;
  const showDownArrow = progress < 0;

  return (
    <>
      {showUpArrow || showDownArrow ? (
        <>
          {showUpArrow ? (
            <ArrowUpwardIcon
              color="success"
              sx={{ fontSize: "16px", marginRight: "4px" }}
            />
          ) : showDownArrow ? (
            <ArrowDownwardIcon
              color="error"
              sx={{ fontSize: "16px", marginRight: "4px" }}
            />
          ) : null}
          <Typography variant="body2" color="textSecondary">
            {progress} {progressText}{" "}
          </Typography>
        </>
      ) : null}
    </>
  );
};
