import { Chip, ChipProps } from "@mui/material";
import React from "react";
import { ActiveStatus } from "../../../enums";

interface StatusChipProps extends ChipProps {
  status: ActiveStatus;
}

const AccountStatusChip: React.FC<StatusChipProps> = ({
  status,
  ...otherProps
}) => {
  const buttonClassName = `account-status-btn ${status}`;

  return <Chip {...otherProps} label={status} className={buttonClassName} />;
};

export default AccountStatusChip;
