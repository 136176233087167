import { z } from "zod";

const phoneNumberSchema = z
  .string()
  .min(1, "This field is required")
  .min(10, "Invalid number")
  .max(10, "Invalid number")
  .regex(/^[0-9]+$/, "Invalid number");

const USPostalCodeSchema = z
  .string()
  .min(1, "This field is required")
  .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "invalid zip format");

const urlSchema = z.union([
  z
    .string()
    .regex(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/,
      "Invalid url"
    ),
  z.literal(""),
]);

export const loginSchema = z.object({
  username: z.string().trim().min(1, "username is required"),
  password: z
    .string()
    .trim()
    .min(1, "password is required")
    .min(6, "password must be at least 6 characters"),
});

export const createContactSchema = z.object({
  title: z.string().trim().min(1, "This field is required"),
  name: z.string().trim().min(1, "This field is required"),
  email: z.string().email().min(1, "This field is required"),
  number: phoneNumberSchema,
  isEmailRequired: z
    .string()
    .min(1, "This field is required")
    .refine((value) => ["true", "false"].includes(value), {
      message: "Invalid value",
    }),
  isSmsRequired: z
    .string()
    .min(1, "This field is required")
    .refine((value) => ["true", "false"].includes(value), {
      message: "Invalid value",
    }),
  isLoginRequired: z.string().optional(),
});

export const createFleetSchema = z.object({
  washTypeId: z.number().min(1, { message: "This field is required" }),
  vehicleNumber: z.string().trim().min(1, "This field is required"),
  isPreSelected: z.boolean().optional(),
});

export const createLocationSchema = z.object({
  locationName: z.string().min(1, "This field is required"),
  addressLine1: z.string().min(1, "This field is required"),
  addressLine2: z.string().trim().optional(),
  city: z.string().min(1, "This field is required"),
  state: z.string().min(1, "This field is required"),
  zip: USPostalCodeSchema,
  instruction: z.string().optional(),
});

export const editAccountProfileHeaderSchema = z.object({
  accountName: z.string().trim().min(1, "This field is required"),
  logo: z.any().optional(),
});

export const editAccountProfileBodySchema = z.object({
  contactPerson: z.string().trim().min(1, "This field is required"),
  email: z.string().email().min(1, "This field is required"),
  contactNumber: z.string().min(1, "This field is required"),
  website: urlSchema,
  addressLine1: z.string().trim().min(1, "This field is required"),
  addressLine2: z.string().trim().optional(),
  city: z.string().trim().min(1, "This field is required"),
  state: z.string().min(1, "This field is required"),
  zip: USPostalCodeSchema,
  instruction: z.string().trim().min(1, "This field is required"),
});

export const editUserProfileBodySchema = z.object({
  firstName: z.string().min(1, "This field is required"),
  lastName: z.string().min(1, "This field is required"),
  emailId: z.string().email().min(1, "This field is required"),
  phoneNumber: z.string().min(1, "This field is required"),
});

export const editWashInventorySchema = z.object({
  endTime: z.string().min(1, "This field is required"),
  washTypeId: z.number().min(1, "This field is required"),
  leadId: z.number().min(1, "This field is required"),
  vehicleNumber: z.string().trim().min(1, "This field is required"),
});

export const bulkEditWashInventorySchema = z
  .object({
    endTime: z.string().optional(),
    washTypeId: z.number().optional(),
    leadId: z.number().optional(),
    vehicleNumber: z.string().trim().optional(),
  })
  .refine((data) => !(data.endTime && data.washTypeId && data.vehicleNumber), {
    message: "",
    path: ["endTime"],
  })
  .refine((data) => !(data.endTime && data.washTypeId && data.vehicleNumber), {
    message: "",
    path: ["washTypeId"],
  })
  .refine((data) => !(data.endTime && data.washTypeId && data.vehicleNumber), {
    message: "You can't update Date, Fleet Number, and Wash Type together",
    path: ["vehicleNumber"],
  });

export const editWashChargeSchema = z.object({
  washTypeId: z.number({
    required_error: "This field is required",
    invalid_type_error: "This field is required",
  }),
  washRate: z
    .string()
    .min(1, "This field is required")
    .refine((value) => !value || /^\d+(\.\d+)?$/.test(value), {
      message: "Invalid",
    }),
  frequency: z.string().min(1, "This field is required"),
  instructions: z.string(),
});

export const editMiscChargeSchema = z.object({
  washTypeId: z.number({
    required_error: "This field is required",
    invalid_type_error: "This field is required",
  }),
  washRate: z
    .string()
    .min(1, "This field is required")
    .refine((value) => !value || /^\d+(\.\d+)?$/.test(value), {
      message: "Invalid",
    }),
});

export const editBillingCycleSchema = z.object({
  proposedInvoiceFrequency: z.string().min(1, "This field is required"),
  proposedInvoiceDate: z.any(),
});

const passwordSchema = z
  .string()
  .min(8, { message: "Password must be at least 8 characters long." })
  .max(20, { message: "Password must not exceed 20 characters." })
  .refine((password) => /[A-Z]/.test(password), {
    message: "Password must contain at least one uppercase letter.",
  })
  .refine((password) => /[a-z]/.test(password), {
    message: "Password must contain at least one lowercase letter.",
  })
  .refine((password) => /[0-9]/.test(password), {
    message: "Password must contain at least one number.",
  })
  .refine((password) => /[!@#$%^&*?_]/.test(password), {
    message: "Password must contain at least one special character.",
  });

export const editAccountFinancialBasicSchema = z.object({
  quickbooksId: z.string().trim().min(1, "This field is required"),
  purchaseOrder: z.string().trim().min(1, "This field is required"),
});

export const changePasswordSchema = (
  isOldPasswordRequired: boolean,
  isUserNameRequired: boolean
) =>
  z
    .object({
      userName: isUserNameRequired
        ? z.string().min(1, "This field is required")
        : z.string().optional(),
      oldPassword: isOldPasswordRequired
        ? z.string().min(1, "This field is required")
        : z.string().optional(),
      newPassword: passwordSchema,
      confirmNewPassword: z.string().min(1, "This field is required"),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: "Passwords do not match",
      path: ["confirmNewPassword"],
    });

export const forgotPasswordSchema = z.object({
  userName: z.string().trim().min(1, "Username is required"),
});

export const createAccountFinancialBasicSchema = z.object({
  quickbooksId: z.string().min(1, "This field is required"),
  purchaseOrder: z.string().min(1, "This field is required"),
  invoiceFrequency: z.string().min(1, "This field is required"),
});

export const editOrganizationProfileHeaderSchema = z.object({
  name: z.string().trim().min(1, "This field is required"),
  logo: z.any().optional(),
});

export const editOrganizationProfileBodySchema = z.object({
  contactPerson: z.string().trim().min(1, "This field is required"),
  contactNumber: phoneNumberSchema,
  officeNumber: phoneNumberSchema,
  mobileNumber: phoneNumberSchema,
  timezoneId: z.number().min(1, "This field is required"),
});

export const editQuickbooksIntegrationSchema = z.object({
  quickBooksClientId: z.string().trim().min(1, "This field is required"),
  quickBooksRealmId: z.string().trim().min(1, "This field is required"),
  quickBooksSecretId: z.string().trim().min(1, "This field is required"),
});

export const editPaymentGatewayIntegrationSchema = z.object({
  quickBooksClientId: z.string().trim().min(1, "This field is required"),
  quickBooksSecretId: z.string().trim().min(1, "This field is required"),
});

export const editOrganizationAddressSchema = z.object({
  instruction: z.string().trim().optional(),
  email: z.string().email().optional(),
  website: urlSchema,
  addressLine1: z.string().trim().min(1, "This field is required"),
  addressLine2: z.string().trim().optional(),
  city: z.string().trim().min(1, "This field is required"),
  state: z.string().min(1, "This field is required"),
  zip: USPostalCodeSchema,
});
