import React, { useState } from "react";
import { Box, Grid2 as Grid } from "@mui/material";
import Cropper from "react-easy-crop";

type Props = {
  image: string;
  onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void;
};

export const CropContainer = ({ image, onCropComplete }: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom: any) => {
    setZoom(zoom);
  };

  return (
    <Grid
      container
      size={12}
      direction={"column"}
      sx={{
        height: {
          xs: "300px",
          md: "400px",
        },
      }}
    >
      <Grid
        size={12}
        flex={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            // cropShape="round"
            showGrid={true}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
