import {
  Box,
  Divider,
  Grid2 as Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { OrganizationIntegrationsInfo } from "../../../types";
import { EditIcon } from "../../icons";

type ViewQuickbooksIntegrationInfoProps = {
  data?: OrganizationIntegrationsInfo;
  isLoading: boolean;
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewQuickbooksIntegrationInfo = ({
  data,
  isLoading = false,
  handleEditMode,
}: ViewQuickbooksIntegrationInfoProps) => {
  return (
    <Grid
      container
      size={{ sm: 12, md: 6 }}
      direction={"column"}
      spacing={4}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        p: 4,
        position: "relative",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: "600", pb: 1 }}>Quickbooks</Typography>
        <Divider />
      </Box>
      <Box
        onClick={() => handleEditMode(true)}
        sx={{
          ":hover": {
            cursor: "pointer",
          },
          position: "absolute",
          right: "1rem",
          top: "1rem",
        }}
      >
        <EditIcon width={"21px"} active={true} />
      </Box>
      <Grid container size={12}>
        <Grid container size={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {isLoading || !data ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Quickbooks Client ID
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                    wordWrap: "break-word",
                  }}
                >
                  {data.quickbooks && data.quickbooks.quickBooksClientId
                    ? data.quickbooks.quickBooksClientId
                    : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid container size={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {isLoading || !data ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Quickbooks Realm ID
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                    wordWrap: "break-word",
                  }}
                >
                  {data.quickbooks && data.quickbooks.quickBooksRealmId
                    ? data.quickbooks.quickBooksRealmId
                    : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid container size={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {isLoading || !data ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Quickbooks Secret ID
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                    wordWrap: "break-word",
                  }}
                >
                  {data.quickbooks && data.quickbooks.quickBooksSecretId
                    ? data.quickbooks.quickBooksSecretId
                    : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
