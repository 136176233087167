import { Box, Divider, Grid2 as Grid } from "@mui/material";
import { Button, SelectBox, TextField } from "../../common";
import { useAtom } from "jotai";
import { isCreateLocationComponentActiveAtom } from "../../../atoms";
import { useValidateForm } from "../../../hooks";
import { createLocationSchema } from "../../../validations";
import { AccountLocation } from "../../../types";
import { useState } from "react";
import { STATES } from "../../../constants";

type CreateLocationProps = {
  defaultValues?: AccountLocation;
  handleCreate: (location: Omit<AccountLocation, "locationNumber">) => void;
  handleDiscard?: () => void;
};

export const CreateLocation = ({
  defaultValues,
  handleCreate,
  handleDiscard,
}: CreateLocationProps) => {
  const [, setLocationCreateComponentActive] = useAtom(
    isCreateLocationComponentActiveAtom
  );
  const [hasChanges, setHasChanges] = useState(false);

  const { register, handleSubmit, errors, reset } =
    useValidateForm(createLocationSchema);

  const handleClickDiscard = () => {
    setLocationCreateComponentActive(false);
  };

  const handleChange = () => {
    setHasChanges(true);
  };

  return (
    <Box
      onSubmit={handleSubmit(handleCreate)}
      component={"form"}
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        size={12}
        direction={"row"}
        gap={2}
        sx={{
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px",
          p: 4,
          mb: 3,
        }}
      >
        <Grid
          container
          size={12}
          spacing={3.5}
          gap={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid size={{ sm: 6, xs: 12 }}>
            <TextField
              label="Address Line 1"
              name="addressLine1"
              register={register}
              error={errors["addressLine1"]}
              defaultValue={defaultValues?.addressLine1}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid size={{ sm: 6, xs: 12 }}>
            <TextField
              label="Address Line 2 (Optional)"
              name="addressLine2"
              register={register}
              error={errors["addressLine2"]}
              defaultValue={defaultValues?.addressLine2}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          size={12}
          spacing={2.5}
          gap={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid size={{ sm: 4, xs: 12 }}>
            <TextField
              label="City"
              name="city"
              register={register}
              error={errors["city"]}
              defaultValue={defaultValues?.city}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid size={{ sm: 4, xs: 12 }}>
            <SelectBox
              register={register}
              defaultValue={defaultValues?.state}
              label="State"
              name="state"
              dropdown={STATES}
              error={errors["state"]}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid size={{ sm: 4, xs: 12 }}>
            <TextField
              type="number"
              label="Zip"
              name="zip"
              register={register}
              error={errors["zip"]}
              defaultValue={defaultValues?.zip}
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
        <Grid
          container
          size={12}
          spacing={3}
          gap={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid size={{ sm: 6, xs: 12 }}>
            <TextField
              label="Location Name"
              name="locationName"
              register={register}
              error={errors["locationName"]}
              defaultValue={defaultValues?.locationName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid size={{ sm: 6, xs: 12 }}>
            <TextField
              label="Instructions"
              name="instruction"
              register={register}
              error={errors["instruction"]}
              defaultValue={defaultValues?.instruction}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container size={12} spacing={4}>
          <Divider sx={{ width: "100%" }} />
        </Grid>
        <Grid
          container
          size={12}
          spacing={4}
          gap={2}
          sx={{
            justifyContent: { lg: "flex-end", xs: "space-between" },
          }}
        >
          <Grid size={{ lg: 3, sm: 6, xs: 12 }}>
            <Button
              variantType="secondary"
              label="Discard"
              sx={{
                padding: "0.5rem",
                width: "100%",
              }}
              onClick={() => {
                reset();
                setHasChanges(false);
                handleDiscard ? handleDiscard() : handleClickDiscard();
              }}
            />
          </Grid>
          <Grid size={{ lg: 3, sm: 6, xs: 12 }}>
            <Button
              type={hasChanges ? "submit" : undefined}
              disabled={!hasChanges}
              label="Save Location"
              variantType={hasChanges ? "primary" : "secondary"}
              sx={{
                padding: "0.5rem",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
