import { Box } from "@mui/material";
import { useAtom } from "jotai";
import {
  contentWidthAtom,
  selectedOrgIdAtom,
  setActiveMenuItemFromPathAtom,
} from "../../atoms";
import { Route, User } from "../../types";
import { Topbar } from "../topbar";
import {
  ChangePasswordRoute,
  DashboardErrorRoute,
  openRoutes,
  Router,
} from "../../routes/";
import { Role } from "../../enums";
import {
  CustomerSidebar,
  OrganizationAdminSidebar,
  SuperAdminSidebar,
} from "../sidebar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type DashboardLayoutProps = {
  routes: Route[];
  user: User;
};

export const DashboardLayout = ({ routes, user }: DashboardLayoutProps) => {
  const [contentWidth] = useAtom(contentWidthAtom);
  const [, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setActiveMenuItemFromPath] = useAtom(setActiveMenuItemFromPathAtom);
  const location = useLocation();

  useEffect(() => {
    if (routes) setActiveMenuItemFromPath(location.pathname, routes);
  }, [location, routes]);

  useEffect(() => {
    if (user) setSelectedOrgId(user.orgId);
  }, [user]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
      }}
    >
      <Box sx={{ height: "100%" }}>
        {user.roleCode === Role.SuperAdmin ? (
          <SuperAdminSidebar menu={routes} />
        ) : user.roleCode === Role.Admin ? (
          <OrganizationAdminSidebar menu={routes} />
        ) : (
          <CustomerSidebar menu={routes} />
        )}
      </Box>
      <Box sx={{ width: contentWidth }}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          {user && <Topbar userName={`${user.firstName} ${user.lastName}`} />}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            p: 3,
          }}
        >
          {user.isPasswordReset ? (
            <Router
              routes={[ChangePasswordRoute]}
              redirectRoutes={[...routes, ...openRoutes]}
              redirectTo={ChangePasswordRoute.path}
              errorRoute={DashboardErrorRoute}
            />
          ) : (
            <Router
              routes={routes}
              redirectRoutes={openRoutes}
              redirectTo={routes[0].path}
              errorRoute={DashboardErrorRoute}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
