import { defaultProfileImage } from "../assets";
import { ActiveStatus } from "../enums";
import {
  DropdownItem,
  Organization,
  OrganizationAddressInfo,
  OrganizationInfo,
  OrganizationIntegrationsInfo,
  OrganizationProfileInfo,
} from "../types";
import { BusinessInfo } from "../types";

export class OrganizationModel {
  public static buildOrganizationBusinessInfo(
    organizations: Organization[]
  ): BusinessInfo[] {
    return organizations.map((org) => ({
      id: org.orgId,
      name: org.name,
      contactPerson: org.contactPerson,
      contactNumber: org.contactNumber,
      logo: org.logo || defaultProfileImage,
      status: org.isActive ? ActiveStatus.Active : ActiveStatus.Inactive,
    }));
  }

  public static buildOrganizationDropdown(
    organizations: Organization[]
  ): DropdownItem[] {
    return organizations.map((org) => ({
      key: org.name,
      value: org.orgId,
    }));
  }

  public static buildOrganizationProfile(
    organizationInfo: OrganizationInfo
  ): OrganizationProfileInfo {
    return {
      orgId: organizationInfo.orgId,
      name: organizationInfo.name,
      logo: organizationInfo.logo || defaultProfileImage,
      isActive: organizationInfo.isActive
        ? ActiveStatus.Active
        : ActiveStatus.Inactive,
      contactPerson: organizationInfo.contactPerson,
      contactNumber: organizationInfo.contactNumber,
      officeNumber: organizationInfo.officeNumber,
      mobileNumber: organizationInfo.mobileNumber,
      timezoneId: organizationInfo.timezoneId,
      timezone: organizationInfo.timezone,
    };
  }

  public static buildOrganizationIntegrations(
    organizationInfo: OrganizationInfo
  ): OrganizationIntegrationsInfo {
    return {
      quickbooks: {
        quickBooksClientId: organizationInfo.quickBooksClientId,
        quickBooksRealmId: organizationInfo.quickBooksRealmId,
        quickBooksSecretId: organizationInfo.quickBooksSecretId,
      },
    };
  }

  public static buildOrganizationAddresses(
    organizationInfo: OrganizationInfo
  ): OrganizationAddressInfo {
    return {
      primaryAddress: organizationInfo.primaryAddress,
      remittanceAddress: organizationInfo.remittanceAddress,
    };
  }
}
