import { GridComparatorFn } from "@mui/x-data-grid";

export const stringComparator: GridComparatorFn<string> = (v1, v2) => {
  const stringComparison = compareAlphaNumeric(
    v1.toLowerCase(),
    v2.toLowerCase()
  );
  return stringComparison;
};

export const compareAlphaNumeric = (a: string, b: string): number => {
  const zero = "0".charCodeAt(0);

  const isWhitespace = (code: number): boolean => {
    return code <= 32;
  };

  const isDigit = (code: number): boolean => {
    return 48 <= code && code <= 57;
  };

  const ma = a.length;
  const mb = b.length;
  let ia = 0;
  let ib = 0;
  let ca: number, cb: number; // character code
  let za: number, zb: number; // leading zero count
  let na: number, nb: number; // number length
  let sa: boolean, sb: boolean; // number sign
  let bias: number;

  while (ia < ma && ib < mb) {
    ca = a.charCodeAt(ia);
    cb = b.charCodeAt(ib);
    za = zb = 0;
    na = nb = 0;
    sa = sb = true;
    bias = 0;

    // skip over leading spaces
    while (isWhitespace(ca)) {
      ia += 1;
      ca = a.charCodeAt(ia);
    }
    while (isWhitespace(cb)) {
      ib += 1;
      cb = b.charCodeAt(ib);
    }

    // compare digits with other symbols
    if (isDigit(ca) && !isDigit(cb)) {
      return -1;
    }
    if (!isDigit(ca) && isDigit(cb)) {
      return 1;
    }

    // compare negative and positive
    if (!sa && sb) {
      return -1;
    }
    if (sa && !sb) {
      return 1;
    }

    // count leading zeros
    while (ca === zero) {
      za += 1;
      ia += 1;
      ca = a.charCodeAt(ia);
    }
    while (cb === zero) {
      zb += 1;
      ib += 1;
      cb = b.charCodeAt(ib);
    }

    // count numbers
    while (isDigit(ca) || isDigit(cb)) {
      if (isDigit(ca) && isDigit(cb) && bias === 0) {
        if (sa) {
          if (ca < cb) {
            bias = -1;
          } else if (ca > cb) {
            bias = 1;
          }
        } else {
          if (ca > cb) {
            bias = -1;
          } else if (ca < cb) {
            bias = 1;
          }
        }
      }
      if (isDigit(ca)) {
        ia += 1;
        na += 1;
        ca = a.charCodeAt(ia);
      }
      if (isDigit(cb)) {
        ib += 1;
        nb += 1;
        cb = b.charCodeAt(ib);
      }
    }

    // compare number length
    if (sa) {
      if (na < nb) {
        return -1;
      }
      if (na > nb) {
        return 1;
      }
    } else {
      if (na > nb) {
        return -1;
      }
      if (na < nb) {
        return 1;
      }
    }

    // compare numbers
    if (bias) {
      return bias;
    }

    // compare leading zeros
    if (sa) {
      if (za > zb) {
        return -1;
      }
      if (za < zb) {
        return 1;
      }
    } else {
      if (za < zb) {
        return -1;
      }
      if (za > zb) {
        return 1;
      }
    }

    // compare ascii codes
    if (ca < cb) {
      return -1;
    }
    if (ca > cb) {
      return 1;
    }

    ia += 1;
    ib += 1;
  }

  // compare length
  if (ma < mb) {
    return -1;
  }
  if (ma > mb) {
    return 1;
  }

  return 0;
};
