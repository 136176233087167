import React from "react";
import { Box, Typography } from "@mui/material";
import { Modal } from "./Modal";

type ConfirmDeleteModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  icon: React.ReactNode;
  title: string;
  message: string;
};

export const ConfirmationModal: React.FC<ConfirmDeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  icon,
  title,
  message,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column", sm: "column" },
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            padding: { lg: "30px", xs: "10px", sm: "10px" },
            textAlign: "center",
            gap: 2,
          }}
        >
          {icon}
          <Typography>{message}</Typography>
        </Box>
      }
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={onConfirm}
      onSecondaryButtonClick={onClose}
      sx={{
        modal: {
          width: { lg: "35%", xs: "80%", sm: "50%" },
        },
      }}
    />
  );
};
