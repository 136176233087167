import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Box, Grid2, IconButton, InputAdornment } from "@mui/material";
import { changePasswordSchema } from "../../../../validations";
import { useValidateForm } from "../../../../hooks";
import { Modal, TextField, Toast } from "../../../common";
import { useChangeUserPasswordMutation } from "../../../../api/userService";
import { userAtom } from "../../../../atoms";
import { ToastOptions } from "../../../../types";
import Loading from "../../../common/loading/Loading";
import { PasswordRequirementTooltip } from "./PasswordRequirementTooltip";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type ChangeUserPasswordModalProps = {
  open: boolean;
  hideBackdrop?: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

export const ChangeUserPasswordModal: React.FC<
  ChangeUserPasswordModalProps
> = ({ open, hideBackdrop = false, onClose, onSuccess }) => {
  const [user] = useAtom(userAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { register, handleSubmit, errors, reset } = useValidateForm(
    changePasswordSchema(true, false)
  );
  const changePasswordMutation = useChangeUserPasswordMutation();
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [password, setPassword] = useState("");
  const [toolTipPlacement, setToolTipPlacement] = useState<
    "top" | "bottom" | "right" | "left" | "right-end"
  >("right");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    setIsModalOpen(open);
    reset();
  }, [open]);

  useEffect(() => {
    if (changePasswordMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Password Changed successfully.",
        severity: "success",
      });
      onClose();
      if (onSuccess) onSuccess();
    }
    if (changePasswordMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    changePasswordMutation.isSuccess,
    changePasswordMutation.isError,
    changePasswordMutation.data,
    changePasswordMutation.error,
  ]);

  const handleCloseModal = () => {
    onClose();
    reset();
  };

  const handleSecondaryButtonClick = () => {
    handleCloseModal();
  };

  const handleResetPassword = (formData: any) => {
    const data = {
      ...formData,
      userName: user?.userName,
    };
    changePasswordMutation.mutate({
      orgId: user?.orgId || 0,
      id: user?.id || 0,
      data,
    });
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setToolTipPlacement("right");
  };

  const handleInfoIconHover = (event: React.MouseEvent<HTMLElement> | null) => {
    setAnchorEl(event ? event.currentTarget : null);
    setToolTipPlacement("right-end");
  };

  const handleBlur = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      onSubmit={handleSubmit(handleResetPassword)}
      component={"form"}
      id="changePasswordForm"
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Toast setOptions={setToastOptions} options={toastOptions} />
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={"Change Password"}
        content={
          <Grid2
            container
            size={12}
            direction={"column"}
            spacing={3}
            sx={{
              p: 4,
              mb: 2,
              width: "100%",
            }}
          >
            <Grid2>
              <Box sx={{ mb: 3 }}>
                <TextField
                  label={"Old Password"}
                  type={showOldPassword ? "text" : "password"}
                  name={"oldPassword"}
                  register={register}
                  error={errors.oldPassword}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleOldPasswordVisibility}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <TextField
                  label={"New Password"}
                  type={showNewPassword ? "text" : "password"}
                  name={"newPassword"}
                  register={register}
                  error={errors.newPassword}
                  onKeyDown={handleKeydown}
                  showInfoIcon={true}
                  onInfoIconHover={handleInfoIconHover}
                  onBlurCapture={handleBlur}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleNewPasswordVisibility}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOff fontSize="small" />
                            ) : (
                              <Visibility fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <PasswordRequirementTooltip
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  password={password}
                  placement={toolTipPlacement}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <TextField
                  label={"Confirm Password"}
                  type="password"
                  name={"confirmNewPassword"}
                  error={errors.confirmNewPassword}
                  register={register}
                />
              </Box>
            </Grid2>
            {changePasswordMutation.isLoading && (
              <Loading
                message=" "
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                }}
              />
            )}
          </Grid2>
        }
        form={"changePasswordForm"}
        primaryButtonText={"Change Password"}
        disablePrimaryButton={changePasswordMutation.isLoading}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={handleSecondaryButtonClick}
        sx={{
          modal: { width: { lg: "30%", xs: "80%", sm: "50%" } },
        }}
        hideBackdrop={hideBackdrop}
      />
    </Box>
  );
};
