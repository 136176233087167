import { atom } from "jotai";
import { WashInventoryFilters } from "../types";
import { getDateRange } from "../utils";

const currentDate = getDateRange("thisMonth");

export const washInventoryFiltersAtom = atom<WashInventoryFilters>({
  fromDate: currentDate.startDate,
  toDate: currentDate.endDate,
});

export const washInventorySelectedDateRangeAtom = atom<string>("thisMonth");
