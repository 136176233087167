import { useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import { QueryOptions, WashType } from "../types";
import { DEFAULT_QUERY_OPTIONS } from "../constants";

export const useFetchWashTypesQuery = (
  orgId: number,
  fetchOnMount: boolean = true
) => {
  const fetchWashTypes = async (): Promise<WashType[]> => {
    const path = config.api.washTypeService.getAllWashTypes.replace(
      "{orgId}",
      orgId.toString()
    );
    const response = await client.get<any>(path);
    return response.data.Washtypes;
  };

  return useQuery(["washTypes", orgId], fetchWashTypes, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useFetchMiscWashTypesQuery = (
  orgId: number,
  queryOptions?: QueryOptions<WashType[]>
) => {
  const fetchMiscWashTypes = async (): Promise<WashType[]> => {
    const path =
      config.api.washTypeService.getAllMiscellaneousWashTypes.replace(
        "{orgId}",
        orgId.toString()
      );

    const response = await client.get<{
      Washtypes: WashType[];
    }>(path);

    return response.data.Washtypes;
  };

  return useQuery(["miscWashTypes", orgId], fetchMiscWashTypes, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};
