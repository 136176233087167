import { Box, Typography } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import { CustomerInfo } from "../../../../types";
import { EditIcon } from "../../../icons";
import Loading from "../../../common/loading/Loading";

type ViewUserProfileInfoProps = {
  data: CustomerInfo;
  loading: boolean;
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewUserProfileInfo = ({
  data,
  loading,
  handleEditMode,
}: ViewUserProfileInfoProps) => {
  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <Grid
        container
        spacing={{ xs: 0, sm: 2 }}
        sx={{
          width: "100%",
          height: "100%",
          px: 3,
          py: 3,
          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? "none" : "auto",
        }}
      >
        <Box
          onClick={() => handleEditMode(true)}
          sx={{
            ":hover": {
              cursor: "pointer",
            },
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
        >
          <EditIcon width={"21px"} active={true} />
        </Box>
        <Grid
          container
          size={{ xs: 12, sm: 6 }}
          gap={3}
          sx={{
            px: { xs: 0, sm: 4 },
            pt: 3,
            pb: { xs: 0, sm: 3 },
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#606060",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{
                color: "#000",
                textAlign: "left",
              }}
            >
              {data.firstName + " " + data.lastName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#606060",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              Phone Number
            </Typography>
            <Typography
              sx={{
                color: "#000",
                textAlign: "left",
              }}
            >
              {data.phoneNumber}
            </Typography>
          </Box>
        </Grid>
        <Grid
          container
          size={{ xs: 12, sm: 6 }}
          gap={3}
          sx={{
            px: { xs: 0, sm: 4 },
            py: 4,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#606060",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                color: "#000",
                textAlign: "left",
              }}
            >
              {data.emailId}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#606060",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              Role
            </Typography>
            <Typography
              sx={{
                color: "#000",
                textAlign: "left",
              }}
            >
              {data.roleName}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {loading && (
        <Loading
          message=""
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        />
      )}
    </Box>
  );
};
