import { Box, Grid2 } from "@mui/material";
import { DataGrid, Toast } from "../common";
import {
  InvoiceFilters,
  InvoiceInfo,
  InvoiceItem,
  InvoiceRequestParams,
  ToastOptions,
} from "../../types";
import { downloadFile, theme } from "../../utils";
import { useAtom } from "jotai";
import {
  invoiceDataGridPaginationAtom,
  selectedAccountAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  userAtom,
} from "../../atoms";
import {
  useFetchDownloadInvoicesCsvQuery,
  useFetchInvoicesQuery,
} from "../../api/invoiceService";
import { GridColDef, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate, getDateRange } from "../../utils/dateUtil";
import { InvoiceActionIcons, InvoiceToolbar } from "./fragments";
import { InvoiceStatus, Role } from "../../enums";

export const Invoice = () => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const [invoiceList, setInvoiceList] = useState<InvoiceInfo>();
  const [invoicePaginationModel, setInvoicePaginationModel] = useAtom(
    invoiceDataGridPaginationAtom
  );
  const [selectedInvoiceNumbers, setSelectedInvoiceNumbers] = useState<
    string[]
  >([]);

  const [invoiceFilters, setInvoiceFilters] = useState<InvoiceFilters>({
    fromDate: getDateRange("today").startDate,
    toDate: getDateRange("today").endDate,
    status: InvoiceStatus.All,
    sortBy: "invoiceDate",
    sortOrder: "desc",
  });
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortOrder: GridSortDirection = sortModel[0].sort;
      if (sortOrder) {
        setInvoiceFilters({
          ...invoiceFilters,
          sortBy: sortField,
          sortOrder: sortOrder,
        });
      }
    } else {
      setInvoiceFilters({
        ...invoiceFilters,
        sortBy: "",
        sortOrder: "",
      });
    }
  };
  const buildInvoiceRequestParams = (): InvoiceRequestParams => {
    return {
      orgId: selectedOrgId || 0,
      accountId: selectedAccountId || 0,
      filters: {
        page: invoiceFilters?.page ? invoiceFilters?.page + 1 : 1,
        pageSize: invoiceFilters?.pageSize,
        fromDate: invoiceFilters?.fromDate,
        toDate: invoiceFilters?.toDate,
        status:
          invoiceFilters?.status !== InvoiceStatus.All
            ? invoiceFilters?.status
            : "",
        sortBy: invoiceFilters?.sortBy,
        sortOrder: invoiceFilters?.sortOrder,
      },
    };
  };
  const downloadInvoicesQuery = useFetchDownloadInvoicesCsvQuery(
    buildInvoiceRequestParams(),
    { enabled: false }
  );
  const fetchInvoicesQuery = useFetchInvoicesQuery(buildInvoiceRequestParams());

  const columns: GridColDef[] = [
    {
      field: "invoiceDate",
      headerName: "Date",
      width: 150,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "MM/DD/YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 200,
      editable: false,
    },
    {
      field: "invoiceAmount",
      headerName: "Amount",
      width: 150,
      editable: false,
      valueGetter: (value) => {
        return `$ ${parseFloat(value).toFixed(2)}`;
      },
    },
    {
      field: "invoiceStatus",
      headerName: "Status",
      width: 150,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <InvoiceActionIcons
          invoiceNumber={params.row.invoiceNumber}
          orgId={params.row.orgId}
          accountId={params.row.accountId}
        />
      ),
    },
  ];

  const handleRowSelectionChange = (rows: InvoiceItem[]) => {
    setSelectedInvoiceNumbers(rows.map((e) => e.invoiceNumber));
  };

  const handleRefresh = () => {
    fetchInvoicesQuery.refetch();
  };

  const handleDownload = () => {
    downloadInvoicesQuery.refetch();
  };

  useEffect(() => {
    if (downloadInvoicesQuery.isSuccess && downloadInvoicesQuery.data) {
      const accountName = selectedAccount ? selectedAccount.accountName : "";
      const fileName =
        [
          user?.roleCode === Role.SuperAdmin ? "" : accountName || "unknown",
          invoiceFilters.fromDate || "",
          invoiceFilters.fromDate && invoiceFilters.toDate ? "to" : "",
          invoiceFilters.toDate || "",
        ]
          .filter(Boolean)
          .join("_") + "_invoice.csv";
      const blob = new Blob([downloadInvoicesQuery.data], {
        type: "text/csv",
      });
      const linkToCsv = URL.createObjectURL(blob);
      if (linkToCsv) downloadFile(linkToCsv, fileName);
    }
  }, [
    downloadInvoicesQuery.isSuccess,
    downloadInvoicesQuery.data,
    downloadInvoicesQuery.dataUpdatedAt,
  ]);

  useEffect(() => {
    if (fetchInvoicesQuery.isSuccess) {
      setInvoiceList(fetchInvoicesQuery.data);
    }
  }, [fetchInvoicesQuery.isSuccess, fetchInvoicesQuery.data]);

  useEffect(() => {
    setInvoiceFilters({
      ...invoiceFilters,
      page: invoicePaginationModel.page,
      pageSize: invoicePaginationModel.pageSize,
    });
  }, [invoicePaginationModel]);

  return (
    <Box
      sx={{
        marginTop: "50px",
        width: "100%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <Toast setOptions={setToastOptions} options={toastOptions} />
      <Box>
        <Grid2
          container
          direction={"column"}
          sx={{
            marginTop: "0px",
            marginBottom: "8px",
            backgroundColor: "#f5f5f5",
            borderRadius: "10px",
          }}
        >
          <InvoiceToolbar
            invoiceNumbers={selectedInvoiceNumbers}
            invoiceFilters={invoiceFilters}
            setInvoiceFilters={setInvoiceFilters}
            onRefresh={handleRefresh}
            onDownload={handleDownload}
          />
        </Grid2>
        <Grid2 container direction={"column"} spacing={2}>
          <DataGrid
            columns={columns}
            rows={invoiceList?.items || []}
            loading={
              fetchInvoicesQuery.isLoading ||
              fetchInvoicesQuery.isRefetching ||
              downloadInvoicesQuery.isLoading ||
              downloadInvoicesQuery.isRefetching
            }
            sortModel={[
              {
                field: invoiceFilters.sortBy || "",
                sort: (invoiceFilters.sortOrder as GridSortDirection) || null,
              },
            ]}
            disableColumnFilter
            disableColumnMenu
            onSortModelChange={handleSortModelChange}
            paginationModel={invoicePaginationModel}
            setPaginationModel={setInvoicePaginationModel}
            onSelectedRowsChange={handleRowSelectionChange}
            totalRecords={invoiceList?.totalItems}
            paginationMode="server"
            sortingMode="server"
            rowCount={invoiceList?.totalItems ? invoiceList?.totalItems : 0}
            pageSize={invoiceList?.pageSize}
            sx={{
              border: "none",
              backgroundColor: theme.palette.secondary.light,
              padding: "20px",
              paddingTop: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              height: "70vh",
            }}
          />
        </Grid2>
      </Box>
    </Box>
  );
};
