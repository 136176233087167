import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const UserTooltipContainer = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    border: "1px solid #616161EB",
  },
}));

export const UserTooltip = ({
  title,
  children,
  arrow,
  placement,
}: TooltipProps) => {
  return (
    <UserTooltipContainer title={title} arrow={arrow} placement={placement}>
      {children}
    </UserTooltipContainer>
  );
};
