import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { WashesGroup } from "../../../types";
import { Box } from "@mui/material";
import { theme } from "../../../utils";

const chartSetting = {
  yAxis: [
    {
      label: "Number of Washes",
    },
  ],
  height: 400,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-10px, 0)",
    },
    padding: "10px",
    overflow: "hidden",
  },
};

type WashesByDayBarChartProps = {
  washData: WashesGroup[];
  label: string;
};

export const WashesByDayBarChart: React.FC<WashesByDayBarChartProps> = ({
  washData = [],
  label,
}) => {
  return (
    <Box
      sx={{
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <BarChart
        dataset={washData}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "key",
            tickPlacement: "extremities",
            tickLabelPlacement: "middle",
            label: label,
          },
        ]}
        series={[
          {
            dataKey: "numberOfWashes",
          },
        ]}
        {...chartSetting}
      />
    </Box>
  );
};
