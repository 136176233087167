import {
  Box,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Button } from "../button";
import { FilterItem } from "../../../types";
import { TextField } from "../text-field";
import { MdClose } from "react-icons/md";
import { theme } from "../../../utils";
import { DatePicker, DateRangePicker } from "../date-picker";
import { SelectBox } from "../select";
import moment from "moment";
import { useEffect, useState } from "react";

type FilterProps = {
  filters: FilterItem[];
  filterValues: { [key: string]: any };
  setFilterValues: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  onApplyFilters: () => void;
  onClose: () => void;
  preservedFilters?: string[];
};

export const Filter = ({
  filters,
  filterValues,
  setFilterValues,
  onApplyFilters,
  onClose,
  preservedFilters = [],
}: FilterProps) => {
  const [tempFilterValues, setTempFilterValues] = useState(filterValues);
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);
  const handleChange = (key: string, value: any) => {
    setTempFilterValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleApplyFilters = () => {
    setFilterValues(tempFilterValues);
    setHasAppliedFilters(true);
  };

  useEffect(() => {
    if (hasAppliedFilters) {
      onApplyFilters();
      setHasAppliedFilters(false);
    }
  }, [hasAppliedFilters, filterValues]);

  const handleResetFilters = () => {
    setTempFilterValues((prev) => {
      const updatedFilters = { ...prev };
      filters.forEach((filter) => {
        if (!preservedFilters.includes(filter.keyword)) {
          delete updatedFilters[filter.keyword];
        }
      });
      return updatedFilters;
    });
  };

  const handleResetFilter = (key: string) => {
    if (!preservedFilters.includes(key)) {
      setTempFilterValues((prev) => {
        const updatedFilters = { ...prev };
        delete updatedFilters[key];
        return updatedFilters;
      });
    }
  };

  const renderFilterField = (filter: FilterItem, index: number) => {
    switch (filter.type) {
      case "text":
      case "number":
        return (
          <TextField
            key={index}
            label=""
            type={filter.type}
            value={tempFilterValues[filter.keyword] || ""}
            onChange={(e) => handleChange(filter.keyword, e.target.value)}
            fullWidth
            name=""
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "25px",
              },
              "& fieldset": {
                border: "none",
                borderBottom: "1px solid #D2D2D2",
                borderRadius: "0px",
              },
            }}
          />
        );
      case "list":
        return (
          <SelectBox
            label=""
            name={filter.keyword}
            dropdown={filter.values ? filter.values : []}
            value={tempFilterValues[filter.keyword] || filter.selectedKey || ""}
            onChange={(e) => handleChange(filter.keyword, e.target.value)}
            sx={{
              height: "30px",
              padding: "10px",
              paddingLeft: "0px",
              "& fieldset": {
                border: "none",
                borderBottom: "1px solid #D2D2D2",
                borderRadius: "0px",
              },
            }}
          />
        );
      case "date":
        return (
          <DatePicker
            value={tempFilterValues[filter.keyword] || null}
            onChange={(newValue) => handleChange(filter.keyword, newValue)}
            name={filter.label}
          />
        );
      case "daterange":
        const [start, end] = tempFilterValues[filter.keyword]
          ? tempFilterValues[filter.keyword]
              .split("|")
              .map((d: string) => new Date(d))
          : [null, null];
        return (
          <DateRangePicker
            start={moment(start)}
            end={moment(end)}
            onDateChange={(startDate, endDate) => {
              handleChange(
                filter.keyword,
                startDate?.format("MM/DD/YYYY") +
                  "|" +
                  endDate?.format("MM/DD/YYYY")
              );
            }}
          />
        );
      case "boolean":
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={tempFilterValues[filter.keyword] || false}
                onChange={(e) => handleChange(filter.keyword, e.target.checked)}
                sx={{
                  height: "25px",
                  "& .MuiSvgIcon-root": {
                    fontSize: 16,
                  },
                }}
              />
            }
            label={filter.label}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setTempFilterValues(filterValues);
  }, [onClose, filterValues]);

  return (
    <Box
      sx={{
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        width: "360px",
        backgroundColor: theme.palette.secondary.light,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: "30px",
          borderBottom: "1px solid #D2D2D2",
          paddingRight: "5px",
          paddingLeft: "10px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "15px",
            lineHeight: "18px",
          }}
        >
          Filter By
        </Typography>
        <MdClose
          style={{ width: "22px", height: "22px", cursor: "pointer" }}
          onClick={() => {
            onClose();
            setTempFilterValues(filterValues);
          }}
        />
      </Box>

      {filters.map((filter, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: "#FAFAFA",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "4px",
            mt: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>{filter.label}</Typography>
            {!preservedFilters.includes(filter.keyword) && (
              <Link
                sx={{ fontSize: "12px", cursor: "pointer" }}
                onClick={() => handleResetFilter(filter.keyword)}
              >
                Reset
              </Link>
            )}
          </Box>
          <Box sx={{ paddingBottom: "10px", paddingTop: "5px" }}>
            {renderFilterField(filter, index)}
          </Box>
        </Box>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Link
          sx={{ fontSize: "12px", cursor: "pointer" }}
          onClick={handleResetFilters}
        >
          Reset all
        </Link>
        <Button
          sx={{ width: "130px", height: "30px" }}
          variantType={"primary"}
          onClick={handleApplyFilters}
          label={"Apply Filters"}
        />
      </Box>
    </Box>
  );
};
