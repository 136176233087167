export const TrashIcon2 = ({
  width = "27",
  height = "27",
  style = {},
  active = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M24.3939 4.64646H18.5859V1.16162C18.5859 0.853536 18.4635 0.558075 18.2456 0.340229C18.0278 0.122384 17.7323 0 17.4242 0H8.13131C7.82323 0 7.52777 0.122384 7.30993 0.340229C7.09208 0.558075 6.9697 0.853536 6.9697 1.16162V4.64646H1.16162C0.853536 4.64646 0.558075 4.76885 0.340229 4.98669C0.122384 5.20454 0 5.5 0 5.80808C0 6.11616 0.122384 6.41162 0.340229 6.62947C0.558075 6.84731 0.853536 6.9697 1.16162 6.9697H2.45914L4.65576 24.538C4.69086 24.8189 4.82732 25.0773 5.03951 25.2647C5.2517 25.452 5.525 25.5555 5.80808 25.5556H19.7475C20.0306 25.5555 20.3039 25.452 20.516 25.2647C20.7282 25.0773 20.8647 24.8189 20.8998 24.538L23.0964 6.9697H24.3939C24.702 6.9697 24.9975 6.84731 25.2153 6.62947C25.4332 6.41162 25.5556 6.11616 25.5556 5.80808C25.5556 5.5 25.4332 5.20454 25.2153 4.98669C24.9975 4.76885 24.702 4.64646 24.3939 4.64646ZM9.29293 2.32323H16.2626V4.64646H9.29293V2.32323ZM18.7218 23.2323H6.83379L4.80096 6.9697H20.7546L18.7218 23.2323Z"
        fill={active ? "#3e8bd2" : "#a1a1a1"}
      />
    </svg>
  );
};
