import { Grid2 as Grid } from "@mui/material";
import { OrganizationIntegrationsInfo } from "../../types";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  useFetchOrganizationInfoQuery,
  useUpdateOrganizationMutation,
} from "../../api";
import { selectedOrgIdAtom, toastOptionsAtom } from "../../atoms";
import { OrganizationModel } from "../../models";
import {
  EditPaymentGatewayIntegrationInfo,
  EditQuickbooksIntegrationInfo,
  ViewPaymentGatewayIntegrationInfo,
  ViewQuickbooksIntegrationInfo,
} from "./fragments";

export const Integrations = () => {
  const [isQuickbooksIntegrationEditMode, setIsQuickbooksIntegrationEditMode] =
    useState(false);
  const [
    isPaymentGatewayIntegrationEditMode,
    setIsPaymentGatewayIntegrationEditMode,
  ] = useState(false);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);

  const [integrationsInfo, setIntegrationsInfo] =
    useState<OrganizationIntegrationsInfo>();
  const fetchOrganizationInfoQuery =
    useFetchOrganizationInfoQuery(selectedOrgId);
  const updateOrganizationMutation = useUpdateOrganizationMutation();

  useEffect(() => {
    if (fetchOrganizationInfoQuery.isSuccess) {
      const integrationsInfo = OrganizationModel.buildOrganizationIntegrations(
        fetchOrganizationInfoQuery.data
      );
      setIntegrationsInfo(integrationsInfo);
    }
  }, [
    fetchOrganizationInfoQuery.isSuccess,
    fetchOrganizationInfoQuery.isError,
    fetchOrganizationInfoQuery.data,
    fetchOrganizationInfoQuery.error,
    fetchOrganizationInfoQuery.isLoading,
  ]);

  useEffect(() => {
    if (updateOrganizationMutation.isSuccess) {
      fetchOrganizationInfoQuery.refetch();

      if (isPaymentGatewayIntegrationEditMode)
        setIsPaymentGatewayIntegrationEditMode(false);
      if (isQuickbooksIntegrationEditMode)
        setIsQuickbooksIntegrationEditMode(false);

      setToastOptions({
        open: true,
        message: "Information updated",
        severity: "success",
      });
    }

    if (updateOrganizationMutation.isError) {
      if (isPaymentGatewayIntegrationEditMode)
        setIsPaymentGatewayIntegrationEditMode(false);
      if (isQuickbooksIntegrationEditMode)
        setIsQuickbooksIntegrationEditMode(false);

      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateOrganizationMutation.isSuccess,
    updateOrganizationMutation.isError,
    updateOrganizationMutation.isLoading,
    updateOrganizationMutation.data,
    updateOrganizationMutation.error,
  ]);

  const handleDiscardEditPaymentGatewayIntegrationInfo = () => {
    setIsPaymentGatewayIntegrationEditMode(false);
  };

  const handleDiscardEditQuickbooksIntegrationInfo = () => {
    setIsQuickbooksIntegrationEditMode(false);
  };

  const handleEditPaymentGatewayIntegrationInfo = (data: any) => {
    if (isPaymentGatewayIntegrationEditMode)
      setIsPaymentGatewayIntegrationEditMode(false);
    updateOrganizationMutation.mutate({
      orgId: selectedOrgId,
      ...data,
    });
  };

  const handleEditQuickbooksIntegrationInfo = (data: any) => {
    if (isQuickbooksIntegrationEditMode)
      setIsQuickbooksIntegrationEditMode(false);
    updateOrganizationMutation.mutate({
      orgId: selectedOrgId,
      ...data,
    });
  };

  return (
    <Grid
      container
      direction={"row"}
      spacing={3}
      gap={3}
      sx={{
        direction: "row",
        borderRadius: "10px",
        px: "4px",
      }}
    >
      {!isQuickbooksIntegrationEditMode ? (
        <ViewQuickbooksIntegrationInfo
          data={integrationsInfo}
          isLoading={
            fetchOrganizationInfoQuery.isLoading ||
            fetchOrganizationInfoQuery.isFetching ||
            updateOrganizationMutation.isLoading
          }
          handleEditMode={setIsQuickbooksIntegrationEditMode}
        />
      ) : (
        integrationsInfo && (
          <EditQuickbooksIntegrationInfo
            data={integrationsInfo}
            handleEdit={handleEditQuickbooksIntegrationInfo}
            handleDiscard={handleDiscardEditQuickbooksIntegrationInfo}
          />
        )
      )}
      {!isPaymentGatewayIntegrationEditMode ? (
        <ViewPaymentGatewayIntegrationInfo
          data={integrationsInfo}
          isLoading={
            fetchOrganizationInfoQuery.isLoading ||
            fetchOrganizationInfoQuery.isFetching ||
            updateOrganizationMutation.isLoading
          }
          handleEditMode={setIsPaymentGatewayIntegrationEditMode}
        />
      ) : (
        integrationsInfo && (
          <EditPaymentGatewayIntegrationInfo
            data={integrationsInfo}
            handleEdit={handleEditPaymentGatewayIntegrationInfo}
            handleDiscard={handleDiscardEditPaymentGatewayIntegrationInfo}
          />
        )
      )}
    </Grid>
  );
};
