import { useAtom } from "jotai";
import {
  Backdrop,
  Box,
  Grid2 as Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { GridColDef } from "@mui/x-data-grid";
import {
  ActionIconsV2,
  Button,
  DataGrid,
  Modal,
  Tabs,
  TextField,
} from "../common";
import {
  miscChargesDataGridPaginationAtom,
  miscWashTypesDropDownAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
  userPermissionAtom,
  washChargesDataGridPaginationAtom,
  washTypesDropDownAtom,
} from "../../atoms";
import { EditIcon } from "../icons";
import { useEffect, useState } from "react";
import {
  AccountLocationCharge,
  ChargeInformationRow,
  DropdownItem,
  TabItem,
  UserPermission,
} from "../../types";
import {
  useBulkDeleteMiscChargesMutation,
  useBulkDeleteWashChargesMutation,
  useCreateMiscChargeMutation,
  useCreateWashChargeMutation,
  useDeleteMiscChargeMutation,
  useDeleteWashChargeMutation,
  useFetchAccountLocationsQuery,
  useFetchAccountWashMiscChargesQuery,
  useUpdateMiscChargeMutation,
  useUpdateTripMinimumMutation,
  useUpdateWashChargeMutation,
} from "../../api";
import { AccountModel, LocationModel, WashTypeModel } from "../../models";
import { useStateWithPrevious } from "../../hooks";
import { MiscChargeForm, WashChargeForm } from "./fragments";
import { useFetchMiscWashTypesQuery, useFetchWashTypesQuery } from "../../api";
import {
  AllowedAccess,
  useHasPermission,
} from "@zdistancelab-packages/ui-permission-validator";
import { Action, Module, Role } from "../../enums";

export const PriceDetails = () => {
  const washChargesCols: GridColDef<ChargeInformationRow[][number]>[] = [
    {
      field: "washType",
      headerName: "Wash Type",
      width: 200,
    },
    {
      field: "washRate",
      headerName: "Wash Rate",
      width: 150,
      valueGetter: (value) => {
        return `$ ${parseFloat(value).toFixed(2)}`;
      },
    },
    {
      field: "frequency",
      headerName: "Frequency",
      width: 150,
    },
    {
      field: "instructions",
      headerName: "Instructions",
      width: 200,
    },
  ];
  const miscChargesCols: GridColDef<ChargeInformationRow[][number]>[] = [
    {
      field: "washType",
      headerName: "Miscellaneous Type",
      width: 200,
    },
    {
      field: "washRate",
      headerName: "Miscellaneous Rate",
      width: 200,
      valueGetter: (value) => {
        return `$ ${parseFloat(value).toFixed(2)}`;
      },
    },
  ];
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [washChargesPaginationModel, setWashChargesPaginationModel] = useAtom(
    washChargesDataGridPaginationAtom
  );
  const [miscChargesPaginationModel, setMiscChargesPaginationModel] = useAtom(
    miscChargesDataGridPaginationAtom
  );
  const [, setWashTypesDropDown] = useAtom(washTypesDropDownAtom);
  const [, setMiscWashTypesDropDown] = useAtom(miscWashTypesDropDownAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [isTripMinFieldEditable, setIsTripMinFieldEditable] = useState(false);
  const [prevTripMinValue, tripMinValue, setTripMinValue] =
    useStateWithPrevious("0.00");
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [locationsTabItems, setLocationsTabItems] = useState<TabItem[]>([]);
  const [washChargesRows, setWashChargesRows] = useState<
    ChargeInformationRow[]
  >([]);
  const [miscChargesRows, setMiscChargesRows] = useState<
    ChargeInformationRow[]
  >([]);
  const [accountLocationCharge, setAccountLocationCharge] =
    useState<AccountLocationCharge | null>(null);
  const [isCreateWashChargeModalOpen, setIsCreateWashChargeModalOpen] =
    useState(false);
  const [isCreateMiscChargeModalOpen, setIsCreateMiscChargeModalOpen] =
    useState(false);
  const [selectedWashChargeWashTypeIds, setSelectedWashChargeWashTypeIds] =
    useState<number[]>([]);
  const [selectedMiscChargeWashTypeIds, setSelectedMiscChargeWashTypeIds] =
    useState<number[]>([]);
  const fetchAccountWashMiscChargesQuery = useFetchAccountWashMiscChargesQuery(
    selectedOrgId,
    selectedAccountId || 0
  );
  const fetchAccountLocationsQuery = useFetchAccountLocationsQuery(
    selectedOrgId,
    selectedAccountId || 0
  );
  const fetchWashTypesQuery = useFetchWashTypesQuery(selectedOrgId);
  const fetchMiscWashTypesQuery = useFetchMiscWashTypesQuery(selectedOrgId);
  const updateTripMinMutation = useUpdateTripMinimumMutation();
  const updateWashChargeMutation = useUpdateWashChargeMutation();
  const updateMiscChargeMutation = useUpdateMiscChargeMutation();
  const deleteWashChargeMutation = useDeleteWashChargeMutation();
  const deleteMiscChargeMutation = useDeleteMiscChargeMutation();
  const createWashChargeMutation = useCreateWashChargeMutation();
  const createMiscChargeMutation = useCreateMiscChargeMutation();
  const bulkDeleteWashChargesMutation = useBulkDeleteWashChargesMutation();
  const bulkDeleteMiscChargesMutation = useBulkDeleteMiscChargesMutation();
  const [userPermission] = useAtom<UserPermission>(userPermissionAtom);
  const hasWritePermission = useHasPermission(userPermission, [
    { module: Module.AccountPricing, action: Action.Write },
  ]);
  const hasDeletePermission = useHasPermission(userPermission, [
    { module: Module.AccountPricing, action: Action.Delete },
  ]);

  if (hasWritePermission || hasDeletePermission) {
    washChargesCols.push({
      field: "Actions",
      headerName: "Actions",
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <ActionIconsV2
          onEdit={handleEditWashCharge}
          onDelete={handleDeleteWashCharge}
          rowData={params.row}
          hasWritePermission={hasWritePermission}
          hasDeletePermission={hasDeletePermission}
          formProps={{
            form: WashChargeForm,
            name: "editWashChargeForm",
          }}
          editProps={{
            title: "Edit Wash Charge",
          }}
          deleteProps={{
            title: "Delete Wash Charge",
          }}
        />
      ),
    });
    miscChargesCols.push({
      field: "Actions",
      headerName: "Actions",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <ActionIconsV2
          onEdit={handleEditMiscCharge}
          onDelete={handleDeleteMiscCharge}
          rowData={params.row}
          hasWritePermission={hasWritePermission}
          hasDeletePermission={hasDeletePermission}
          formProps={{
            form: MiscChargeForm,
            name: "editMiscChargeForm",
          }}
          editProps={{
            title: "Edit Miscellaneous Charge",
          }}
          deleteProps={{
            title: "Delete Miscellaneous Charge",
          }}
        />
      ),
    });
  }
  useEffect(() => {
    if (fetchWashTypesQuery.isSuccess) {
      const dropdown: DropdownItem[] = WashTypeModel.buildWashTypeDropDown(
        fetchWashTypesQuery.data
      );

      setWashTypesDropDown(dropdown);
    }
  }, [
    fetchWashTypesQuery.isSuccess,
    fetchWashTypesQuery.isError,
    fetchWashTypesQuery.isLoading,
    fetchWashTypesQuery.data,
    fetchWashTypesQuery.error,
  ]);

  useEffect(() => {
    if (fetchMiscWashTypesQuery.isSuccess) {
      const dropdown: DropdownItem[] = WashTypeModel.buildWashTypeDropDown(
        fetchMiscWashTypesQuery.data
      );

      setMiscWashTypesDropDown(dropdown);
    }
  }, [
    fetchMiscWashTypesQuery.isSuccess,
    fetchMiscWashTypesQuery.isError,
    fetchMiscWashTypesQuery.isLoading,
    fetchMiscWashTypesQuery.data,
    fetchMiscWashTypesQuery.error,
  ]);

  useEffect(() => {
    if (deleteWashChargeMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();

      setToastOptions({
        open: true,
        message: "Wash charge deleted",
        severity: "success",
      });
    }

    if (deleteWashChargeMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteWashChargeMutation.isSuccess,
    deleteWashChargeMutation.isError,
    deleteWashChargeMutation.isLoading,
    deleteWashChargeMutation.data,
    deleteWashChargeMutation.error,
  ]);

  useEffect(() => {
    if (bulkDeleteWashChargesMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();

      setToastOptions({
        open: true,
        message: "Wash charges deleted",
        severity: "success",
      });
    }

    if (bulkDeleteWashChargesMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    bulkDeleteWashChargesMutation.isSuccess,
    bulkDeleteWashChargesMutation.isError,
    bulkDeleteWashChargesMutation.isLoading,
    bulkDeleteWashChargesMutation.data,
    bulkDeleteWashChargesMutation.error,
  ]);

  useEffect(() => {
    if (bulkDeleteMiscChargesMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();

      setToastOptions({
        open: true,
        message: "Miscellaneous charges deleted",
        severity: "success",
      });
    }

    if (bulkDeleteMiscChargesMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    bulkDeleteMiscChargesMutation.isSuccess,
    bulkDeleteMiscChargesMutation.isError,
    bulkDeleteMiscChargesMutation.isLoading,
    bulkDeleteMiscChargesMutation.data,
    bulkDeleteMiscChargesMutation.error,
  ]);

  useEffect(() => {
    if (deleteMiscChargeMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();

      setToastOptions({
        open: true,
        message: "Miscellaneous charge deleted",
        severity: "success",
      });
    }

    if (deleteMiscChargeMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteMiscChargeMutation.isSuccess,
    deleteMiscChargeMutation.isError,
    deleteMiscChargeMutation.isLoading,
    deleteMiscChargeMutation.data,
    deleteMiscChargeMutation.error,
  ]);

  useEffect(() => {
    if (fetchAccountLocationsQuery.isSuccess) {
      const locationsTabItems = LocationModel.buildLocationsTab(
        fetchAccountLocationsQuery.data
      );

      setLocationsTabItems(locationsTabItems);
      setSelectedLocation(locationsTabItems[0].value);
    }
  }, [
    fetchAccountLocationsQuery.isSuccess,
    fetchAccountLocationsQuery.isError,
    fetchAccountLocationsQuery.isLoading,
    fetchAccountLocationsQuery.data,
    fetchAccountLocationsQuery.error,
  ]);

  useEffect(() => {
    if (fetchAccountWashMiscChargesQuery.isSuccess) {
      setAccountLocationCharge(fetchAccountWashMiscChargesQuery.data);
    }
  }, [
    fetchAccountWashMiscChargesQuery.isSuccess,
    fetchAccountWashMiscChargesQuery.isError,
    fetchAccountWashMiscChargesQuery.data,
    fetchAccountWashMiscChargesQuery.error,
    fetchAccountWashMiscChargesQuery.isLoading,
  ]);

  useEffect(() => {
    if (accountLocationCharge) {
      const miscCharges = AccountModel.getMiscChargesForLocation(
        accountLocationCharge.locations,
        selectedLocation
      );
      const washTypeCharges = AccountModel.getWashTypeChargesForLocation(
        accountLocationCharge.locations,
        selectedLocation
      );
      const washChargesRows =
        AccountModel.buildChargeInformationRows(washTypeCharges);
      const miscChargesRows =
        AccountModel.buildChargeInformationRows(miscCharges);
      const tripMin = AccountModel.getTripMinimum(miscCharges);

      setWashChargesRows(washChargesRows);
      setMiscChargesRows(miscChargesRows);

      if (tripMin) {
        setTripMinValue(tripMin.specialRate.toFixed(2));
      } else {
        setTripMinValue("0.00");
      }
    }
  }, [accountLocationCharge, selectedLocation]);

  useEffect(() => {
    if (createWashChargeMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Wash charge created",
        severity: "success",
      });
    }

    if (createWashChargeMutation.isError) {
      if (createWashChargeMutation.error.status === 409) {
        setToastOptions({
          open: true,
          message: "Duplicate entry not allowed",
          severity: "error",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    createWashChargeMutation.isSuccess,
    createWashChargeMutation.isError,
    createWashChargeMutation.isLoading,
    createWashChargeMutation.data,
    createWashChargeMutation.error,
  ]);

  useEffect(() => {
    if (createMiscChargeMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Miscellaneous charge created",
        severity: "success",
      });
    }

    if (createMiscChargeMutation.isError) {
      if (createMiscChargeMutation.error.status === 409) {
        setToastOptions({
          open: true,
          message: "Duplicate entry not allowed.",
          severity: "error",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    createMiscChargeMutation.isSuccess,
    createMiscChargeMutation.isError,
    createMiscChargeMutation.isLoading,
    createMiscChargeMutation.data,
    createMiscChargeMutation.error,
  ]);

  useEffect(() => {
    if (updateWashChargeMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Wash charge updated",
        severity: "success",
      });
    }

    if (updateWashChargeMutation.isError) {
      if (updateWashChargeMutation.error.status === 409) {
        setToastOptions({
          open: true,
          message: "Duplicate entry not allowed",
          severity: "error",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    updateWashChargeMutation.isSuccess,
    updateWashChargeMutation.isError,
    updateWashChargeMutation.isLoading,
    updateWashChargeMutation.data,
    updateWashChargeMutation.error,
  ]);

  useEffect(() => {
    if (updateMiscChargeMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Miscellaneous charge updated",
        severity: "success",
      });
    }

    if (updateMiscChargeMutation.isError) {
      if (updateMiscChargeMutation.error.status === 409) {
        setToastOptions({
          open: true,
          message: "Duplicate entry not allowed",
          severity: "error",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    updateMiscChargeMutation.isSuccess,
    updateMiscChargeMutation.isError,
    updateMiscChargeMutation.isLoading,
    updateMiscChargeMutation.data,
    updateMiscChargeMutation.error,
  ]);

  useEffect(() => {
    if (!updateTripMinMutation.isLoading) {
      setIsTripMinFieldEditable(false);
    }

    if (updateTripMinMutation.isSuccess) {
      fetchAccountWashMiscChargesQuery.refetch();
      setToastOptions({
        open: true,
        message: "Trip minimum updated",
        severity: "success",
      });
    }

    if (updateTripMinMutation.isError) {
      setTripMinValue(prevTripMinValue);
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateTripMinMutation.isSuccess,
    updateTripMinMutation.isError,
    updateTripMinMutation.isLoading,
    updateTripMinMutation.data,
    updateTripMinMutation.error,
  ]);

  const handleEditTripMin = () => {
    setIsTripMinFieldEditable(true);
  };

  const handleSaveTripMin = () => {
    const tripMinimumValue = parseFloat(tripMinValue).toFixed(2);
    setTripMinValue(tripMinimumValue);

    updateTripMinMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      rate: Number(tripMinimumValue),
    });
  };

  const handleEditWashCharge = (
    data: { washTypeId: number } & Omit<ChargeInformationRow, "washType">
  ) => {
    updateWashChargeMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      washTypeId: data.id,
      washCharge: {
        washTypeId: data.washTypeId,
        specialRate: data.washRate,
        washFrequency: data.frequency,
        instructions: data.instructions,
      },
    });
  };

  const handleEditMiscCharge = (data: {
    washRate: number;
    washTypeId: number;
    id: number;
  }) => {
    updateMiscChargeMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      washTypeId: data.id,
      washCharge: {
        specialRate: data.washRate,
        washTypeId: data.washTypeId,
      },
    });
  };

  const handleDeleteWashCharge = ({ id }: { id: number }) => {
    deleteWashChargeMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      washTypeId: id,
    });
  };

  const handleDeleteMiscCharge = ({ id }: { id: number }) => {
    deleteMiscChargeMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      washTypeId: id,
    });
  };

  const handleOpenCreateWashChargeModal = () =>
    setIsCreateWashChargeModalOpen(true);

  const handleCloseCreateWashChargeModal = () =>
    setIsCreateWashChargeModalOpen(false);

  const handleOpenCreateMiscChargeModal = () =>
    setIsCreateMiscChargeModalOpen(true);

  const handleCloseCreateMiscChargeModal = () =>
    setIsCreateMiscChargeModalOpen(false);

  const handleCreateWashCharge = (
    data: { washRate: number; washTypeId: number } & Omit<
      ChargeInformationRow,
      "washRate" | "id" | "washType"
    >
  ) => {
    createWashChargeMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      charge: {
        washTypeId: data.washTypeId,
        specialRate: data.washRate,
        washFrequency: data.frequency,
        instructions: data.instructions,
      },
    });
    handleCloseCreateWashChargeModal();
  };

  const handleCreateMiscCharge = (data: {
    washRate: number;
    washTypeId: number;
  }) => {
    createMiscChargeMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      charge: {
        washTypeId: data.washTypeId,
        specialRate: data.washRate,
      },
    });
    handleCloseCreateMiscChargeModal();
  };

  const handleSelectedWashChargeWashTypeIdsChange = (
    selectedRowIds: number[]
  ) => {
    setSelectedWashChargeWashTypeIds(selectedRowIds);
  };

  const handleSelectedMiscChargeWashTypeIdsChange = (
    selectedRowIds: number[]
  ) => {
    setSelectedMiscChargeWashTypeIds(selectedRowIds);
  };

  const handleConfirmDeleteWashCharges = () => {
    bulkDeleteWashChargesMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      washTypeIds: selectedWashChargeWashTypeIds,
    });
  };

  const handleConfirmDeleteMiscCharges = () => {
    bulkDeleteMiscChargesMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      locationNumber: selectedLocation,
      washTypeIds: selectedMiscChargeWashTypeIds,
    });
  };

  return (
    <>
      <Grid
        container
        direction={"column"}
        spacing={3}
        sx={{
          width: "100%",
          height: "100%",
          direction: "row",
          borderRadius: "10px",
          px: "4px",
          position: "relative",
        }}
      >
        {!fetchAccountWashMiscChargesQuery.isLoading &&
          fetchAccountWashMiscChargesQuery.isSuccess &&
          locationsTabItems.length === 0 && (
            <Backdrop
              sx={{
                color: "#000",
                zIndex: 1000,
                position: "absolute",
                opacity: 0,
                backgroundImage:
                  "linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5))",
                backgroundColor: "rgba(0, 0, 0, 0)",
              }}
              open={true}
            >
              <Typography sx={{ p: 2, fontSize: { xs: "15px", sm: "18px" } }}>
                Please add a location first
              </Typography>
            </Backdrop>
          )}
        <Grid container size={12} direction={"row"}>
          <Tabs
            defaultTab="No location"
            isLoading={
              fetchAccountLocationsQuery.isLoading ||
              fetchAccountLocationsQuery.isFetching
            }
            tabItems={locationsTabItems}
            onTabChange={setSelectedLocation}
            value={selectedLocation}
          />
        </Grid>
        <Grid
          container
          gap={2}
          direction={
            isTripMinFieldEditable ? { xs: "column", sm: "row" } : "row"
          }
        >
          <Grid
            container
            size={{ md: 4, sm: 4.5, xs: isTripMinFieldEditable ? 12 : 10 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                position: "relative",
              }}
            >
              <TextField
                labelSx={{
                  color: "black",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
                type="number"
                label="Trip Minimum"
                name="tripMin"
                value={tripMinValue}
                disabled={!isTripMinFieldEditable}
                allowScientificNotation={false}
                scientificNotationKeys={["e", "E", "+", "-"]}
                onChange={(e) => {
                  setTripMinValue(e.target.value.toString());
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  },
                }}
                sx={{
                  ".MuiInputBase-input": {
                    pl: "0 !important",
                    ":hover": {
                      cursor: !isTripMinFieldEditable ? "not-allowed" : "auto",
                    },
                  },
                }}
              />
            </Box>
          </Grid>
          {!isTripMinFieldEditable ? (
            <Grid
              container
              size={{ md: 4, sm: 6, xs: isTripMinFieldEditable ? 12 : 2 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={
                  locationsTabItems.length !== 0 ? handleEditTripMin : undefined
                }
              >
                <AllowedAccess
                  roles={[Role.Admin, Role.SuperAdmin, Role.Customer]}
                  permissions={[
                    { module: Module.AccountPricing, action: Action.Write },
                  ]}
                >
                  <EditIcon
                    width={"21px"}
                    style={{
                      transform: "translateY(40%)",
                    }}
                    active={locationsTabItems.length !== 0}
                  />
                </AllowedAccess>
              </Box>
            </Grid>
          ) : (
            <Grid
              container
              direction={"row"}
              size={{ md: 8, sm: 7, xs: isTripMinFieldEditable ? 12 : 10 }}
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", sm: "flex-start" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleSaveTripMin}
              >
                <Button
                  label={"Save"}
                  variantType="primary"
                  icon={SaveIcon}
                  disabled={updateTripMinMutation.isLoading}
                  sx={{
                    padding: "1rem",
                    minWidth: "120px",
                    height: "30px",
                    transform: { xs: undefined, sm: "translateY(40%)" },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  setIsTripMinFieldEditable(false);
                }}
              >
                <Button
                  label={"Discard"}
                  variantType="secondary"
                  icon={CancelIcon}
                  sx={{
                    padding: "1rem",
                    minWidth: "120px",
                    height: "30px",
                    transform: { xs: undefined, sm: "translateY(40%)" },
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container size={12} direction={"row"} gap={0.5}>
          <Typography
            sx={{ color: "black", fontSize: "15px", fontWeight: 400 }}
          >
            Wash Charges
          </Typography>
          <DataGrid
            rows={washChargesRows}
            columns={washChargesCols}
            paginationModel={washChargesPaginationModel}
            setPaginationModel={setWashChargesPaginationModel}
            loading={
              createWashChargeMutation.isLoading ||
              updateWashChargeMutation.isLoading ||
              deleteWashChargeMutation.isLoading ||
              bulkDeleteWashChargesMutation.isLoading ||
              fetchAccountWashMiscChargesQuery.isFetching ||
              fetchAccountWashMiscChargesQuery.isLoading
            }
            sx={{
              px: 2,
              height: "360px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              boxShadow: "0px 0px 4px 0px #00000040",
            }}
            onSelectedRowIdsChange={handleSelectedWashChargeWashTypeIdsChange}
            footerProps={{
              addBtnLabel: hasWritePermission ? "Add" : "",
              removeBtnLabel: hasDeletePermission ? "Delete" : "",
              hasAddIcon: true,
              hasRemoveIcon: true,
              disableAddBtn: locationsTabItems.length === 0,
              disableRemoveBtn:
                selectedWashChargeWashTypeIds.length > 0 ? false : true,
              onAddButtonClick: handleOpenCreateWashChargeModal,
              handleConfirmRemove: handleConfirmDeleteWashCharges,
              confirmationModalTitle: "Delete Wash Charges",
              confirmationModalMessage: "Delete selected items?",
            }}
          ></DataGrid>
        </Grid>
        <Grid container size={12} direction={"row"} gap={0.5}>
          <Typography
            sx={{ color: "black", fontSize: "15px", fontWeight: 400 }}
          >
            Misc Charges
          </Typography>
          <DataGrid
            rows={miscChargesRows}
            columns={miscChargesCols}
            paginationModel={miscChargesPaginationModel}
            setPaginationModel={setMiscChargesPaginationModel}
            loading={
              createMiscChargeMutation.isLoading ||
              updateMiscChargeMutation.isLoading ||
              deleteMiscChargeMutation.isLoading ||
              bulkDeleteMiscChargesMutation.isLoading ||
              fetchAccountWashMiscChargesQuery.isFetching ||
              fetchAccountWashMiscChargesQuery.isLoading
            }
            sx={{
              px: 2,
              height: "360px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              boxShadow: "0px 0px 4px 0px #00000040",
            }}
            onSelectedRowIdsChange={handleSelectedMiscChargeWashTypeIdsChange}
            footerProps={{
              addBtnLabel: hasWritePermission ? "Add" : "",
              removeBtnLabel: hasDeletePermission ? "Delete" : "",
              hasAddIcon: true,
              hasRemoveIcon: true,
              disableAddBtn: locationsTabItems.length === 0,
              disableRemoveBtn:
                selectedMiscChargeWashTypeIds.length > 0 ? false : true,
              onAddButtonClick: handleOpenCreateMiscChargeModal,
              handleConfirmRemove: handleConfirmDeleteMiscCharges,
              confirmationModalTitle: "Delete Miscellaneous Charges",
              confirmationModalMessage: "Delete selected items?",
            }}
          ></DataGrid>
        </Grid>
      </Grid>
      <Modal
        open={isCreateWashChargeModalOpen}
        onClose={handleCloseCreateWashChargeModal}
        title={"Add Wash Charge"}
        content={
          <WashChargeForm
            id={"createWashChargeForm"}
            handleEdit={handleCreateWashCharge}
          ></WashChargeForm>
        }
        form={"createWashChargeForm"}
        primaryButtonText="Confirm"
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={handleCloseCreateWashChargeModal}
        sx={{
          modal: {
            width: { lg: "30%", xs: "80%", sm: "50%" },
          },
        }}
      />
      <Modal
        open={isCreateMiscChargeModalOpen}
        onClose={handleCloseCreateMiscChargeModal}
        title={"Add Miscellaneous Charge"}
        content={
          <MiscChargeForm
            id={"createMiscChargeForm"}
            handleEdit={handleCreateMiscCharge}
          ></MiscChargeForm>
        }
        form={"createMiscChargeForm"}
        primaryButtonText="Confirm"
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={handleCloseCreateMiscChargeModal}
        sx={{
          modal: {
            width: { lg: "30%", xs: "80%", sm: "50%" },
          },
        }}
      />
    </>
  );
};
