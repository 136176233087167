import React, { useEffect, useState } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { Button, TextField, Toast } from "../../common";
import { useValidateForm } from "../../../hooks";
import { forgotPasswordSchema } from "../../../validations";
import { AuthPageLayout } from "../../layouts";
import { ForgotPasswordPayload, ToastOptions } from "../../../types";
import { useForgotUserPasswordMutation } from "../../../api/userService";

export const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const forgotPasswordMutation = useForgotUserPasswordMutation();
  const { register, handleSubmit, errors, reset } =
    useValidateForm(forgotPasswordSchema);

  const handleResetPassword = async (formData: ForgotPasswordPayload) => {
    forgotPasswordMutation.mutate(formData);
    setLoading(true);
  };
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  useEffect(() => {
    if (forgotPasswordMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "A password reset link has been sent to your email address.",
        severity: "success",
      });
    }
    if (forgotPasswordMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    forgotPasswordMutation.isSuccess,
    forgotPasswordMutation.isError,
    forgotPasswordMutation.data,
    forgotPasswordMutation.error,
    forgotPasswordMutation.isLoading,
  ]);

  return (
    <AuthPageLayout
      content={
        <>
          <Toast setOptions={setToastOptions} options={toastOptions} />
          <Typography
            component="h1"
            variant="h6"
            sx={{
              mb: 1,
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            Forgot Password
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              mb: 2,
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            Please enter your username, and we will send you a link to reset
            your password.
          </Typography>
          <Box sx={{ width: "100%", mb: 5 }}>
            <form onSubmit={handleSubmit(handleResetPassword)}>
              <Grid2 size={12} container spacing={4}>
                <Grid2
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    label={"Username"}
                    type={"text"}
                    name={"userName"}
                    labelVariant="body2"
                    labelColor="textSecondary"
                    register={register}
                    error={errors.userName}
                    required
                  />
                </Grid2>
              </Grid2>
              <Box
                sx={{
                  paddingTop: "1rem",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  label={"Submit"}
                />
              </Box>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  padding: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                {errorMessage && (
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      color: "#da2902",
                      position: "absolute",
                      top: 0,
                    }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            </form>
          </Box>
        </>
      }
    />
  );
};
