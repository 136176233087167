import { Box, Grid2 as Grid } from "@mui/material";
import { AccountMenuItem } from "../../types";
import { OrganizationProfile } from "../profile";
import { OrganizationAccordion } from "./OrganizationAccordion";
import { Integrations } from "../integrations";
import { OrganizationAddress } from "../address";

const organizationMenus: AccountMenuItem[] = [
  {
    name: "profile",
    displayName: "Profile",
    component: <OrganizationProfile />,
  },
  {
    name: "integrations",
    displayName: "Integrations",
    component: <Integrations />,
  },
  {
    name: "addresses",
    displayName: "Addresses",
    component: <OrganizationAddress />,
  },
];

export const organizationMenuDefault: AccountMenuItem = organizationMenus[0];

export const OrganizationDetail = () => {
  return (
    <Box
      sx={{
        minHeight: "715px",
        display: "flex",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Grid
        container
        direction={"column"}
        spacing={2}
        gap={0}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          direction: "row",
          py: "1rem",
        }}
      >
        {organizationMenus.map((menu, index) => (
          <OrganizationAccordion key={index} menu={menu} />
        ))}
      </Grid>
    </Box>
  );
};
