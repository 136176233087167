import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Message } from "../../../types";
import { Chat } from "../../chat";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useCreateInvoiceNoteMutation,
  useFetchInvoiceNotesQuery,
} from "../../../api/invoiceService";
import { InvoiceModel } from "../../../models";
import { useAtom } from "jotai";
import { toastOptionsAtom, userAtom } from "../../../atoms";

export const InvoiceNotes = () => {
  const params = useParams<{ invoiceNumber: "string" }>();
  const [queryParams] = useSearchParams();
  const [invoiceNotes, setInvoiceNotes] = useState<Message[]>();
  const [user] = useAtom(userAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const fetchInvoiceNotesQuery = useFetchInvoiceNotesQuery(
    Number(queryParams.get("orgId")) || 0,
    Number(queryParams.get("accountId")) || 0,
    params.invoiceNumber || ""
  );
  const createInvoiceNoteMutation = useCreateInvoiceNoteMutation();

  useEffect(() => {
    if (fetchInvoiceNotesQuery.isSuccess) {
      const invoiceNotes = InvoiceModel.buildInvoiceNotes(
        fetchInvoiceNotesQuery.data
      );
      setInvoiceNotes(invoiceNotes);
    }
  }, [
    fetchInvoiceNotesQuery.isSuccess,
    fetchInvoiceNotesQuery.isError,
    fetchInvoiceNotesQuery.data,
    fetchInvoiceNotesQuery.error,
    fetchInvoiceNotesQuery.isLoading,
  ]);

  useEffect(() => {
    if (createInvoiceNoteMutation.isError) {
      setToastOptions({
        open: true,
        message: "Cannot add note at this moment",
        severity: "error",
      });

      setInvoiceNotes((prev) => {
        return prev && prev.slice(0, -1);
      });
    }
  }, [
    createInvoiceNoteMutation.isSuccess,
    createInvoiceNoteMutation.isError,
    createInvoiceNoteMutation.data,
    createInvoiceNoteMutation.error,
    createInvoiceNoteMutation.isLoading,
  ]);

  const handleSend = (message: string) => {
    createInvoiceNoteMutation.mutate({
      orgId: Number(queryParams.get("orgId")) || 0,
      accountId: Number(queryParams.get("accountId")) || 0,
      invoiceNumber: params.invoiceNumber || "",
      message,
    });
    setInvoiceNotes((prev) => {
      return prev && user
        ? [
            ...prev,
            {
              isOwn: true,
              text: message,
              timestamp: new Date().toISOString(),
              userName: `${user.firstName} ${user.lastName}`,
              profilePic: user.profilePic,
            },
          ]
        : undefined;
    });
  };

  return (
    <Box sx={{ height: "50vh" }}>
      <Chat
        isLoading={fetchInvoiceNotesQuery.isLoading}
        messages={invoiceNotes}
        messagePlaceholder={"Add a note"}
        handleSend={handleSend}
      />
    </Box>
  );
};
