import { DropdownItem, LeadUser } from "../types";

export class LeadUserModel {
  public static buildUserDropDown(
    leadUsers: LeadUser[] | undefined
  ): DropdownItem[] {
    if (!leadUsers) {
      return [];
    }
    return leadUsers.map((user) => ({
      key: user.leadName,
      value: user.leadId,
    }));
  }
}
