import { useMutation } from "react-query";
import { LoginCredentials, LoginResponse, LogoffRequest } from "../types";
import { client } from "./axios";
import { config } from "../config";

export const useLoginMutation = () => {
  const mutation = useMutation(
    async ({ username, password }: LoginCredentials) => {
      const path = config.api.authService.login;
      const response = await client.post<LoginResponse>(path, {
        userName: username,
        password,
      });
      const user = response.data.response;

      return user;
    }
  );

  return mutation;
};

export const useLogoffMutation = () => {
  const mutation = useMutation(async ({ userName }: LogoffRequest) => {
    const path = config.api.authService.logoff;
    const response = await client.post(path, {
      userName,
    });
    return response;
  });

  return mutation;
};
