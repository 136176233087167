import { useRef, useState } from "react";
import { Box, IconButton, Menu } from "@mui/material";
import { theme } from "../../../utils";
import { Button } from "../button";
import { DefaultActionMenuItem } from "../../../types";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type DefaultActionMenuProps = {
  menu: DefaultActionMenuItem[];
};
export const DefaultActionMenu = ({ menu }: DefaultActionMenuProps) => {
  const [open, setOpen] = useState<any>();
  const defaultActionButtonRef = useRef<null | HTMLElement>(null);
  const defaultMenu = menu.find((e) => e.default);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: 0.8 }}>
        <Box
          sx={{ position: "relative", width: "100%" }}
          ref={defaultActionButtonRef}
        >
          {defaultMenu && (
            <Button
              label={defaultMenu.label}
              icon={defaultMenu.icon}
              sx={{
                py: "0.2rem",
                width: "100%",
                minWidth: "160px",
                borderRadius: 0,
                minHeight: "33px",
              }}
              onClick={() => {
                handleClose();
                defaultMenu.action();
              }}
            />
          )}
          <Menu
            anchorEl={defaultActionButtonRef.current}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              mt: 0.8,
            }}
            slotProps={{
              paper: {
                sx: {
                  minWidth: defaultActionButtonRef?.current?.offsetWidth,
                },
              },
            }}
          >
            {menu
              .filter((e) => !e.default)
              .map(({ label, icon: Icon, action }, index) => (
                <MenuItem
                  key={index}
                  sx={{ py: 1 }}
                  onClick={() => {
                    handleClose();
                    action();
                  }}
                >
                  {Icon && (
                    <ListItemIcon>
                      <Icon fontSize="small" />
                    </ListItemIcon>
                  )}
                  <Typography sx={{ fontSize: "0.875rem" }}>{label}</Typography>
                </MenuItem>
              ))}
          </Menu>
        </Box>
        <IconButton
          disableRipple
          sx={{
            px: "0.4rem",
            borderRadius: 0,
            py: "0.2rem",
            width: "fit-content",
            minHeight: "33px",
            boxShadow:
              "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            background: theme.palette.primary.main,
            color: "white",
          }}
          onClick={handleClick}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
    </>
  );
};
