import { atomWithStorage } from "jotai/utils";
import { User, UserPermission } from "../types";
import { storage } from "./storage";
import { SESSION_STORAGE_USER_KEY } from "../constants";
import { atom } from "jotai";

const getDefaultValue = () => {
  const user = sessionStorage.getItem(SESSION_STORAGE_USER_KEY);

  if (!user) return null;

  try {
    return JSON.parse(user) as User;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e: any) {
    return null;
  }
};

export const userAtom = atomWithStorage<User | null>(
  SESSION_STORAGE_USER_KEY,
  getDefaultValue(),
  storage
);

export const userPermissionAtom = atom<UserPermission>((get) => {
  const user = get(userAtom);

  return user
    ? { roleCode: user.roleCode, permissions: user.permissions }
    : { roleCode: "", permissions: [] };
});
