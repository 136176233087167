import { useState } from "react";

export const useStateWithPrevious = <T>(
  initial: T
): [T, T, (newValue: T) => void] => {
  const [value, setValue] = useState(initial);
  const [prev, setPrev] = useState(initial);

  const setValueAndPrev = (newValue: T): void => {
    if (newValue === value) return;
    setPrev(value);
    setValue(newValue);
  };

  return [prev, value, setValueAndPrev];
};
