import { useEffect, useState } from "react";
import { InvoiceStatus } from "../../../enums";
import { DefaultActionMenuItem } from "../../../types";
import { isReceivePaymentFormActiveAtom } from "../../../atoms";
import { useAtom } from "jotai";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import SendIcon from "@mui/icons-material/Send";
import PaymentIcon from "@mui/icons-material/Payment";
import { DefaultActionMenu } from "../../common";
import { INVOICE_STATUS_TARGET_ACTIONS } from "../../../constants";

type InvoiceActionsProps = {
  status: InvoiceStatus;
  invoiceNumbers: string[];
  orgId: number;
  accountId: number;
};

export const InvoiceActions = ({
  status,
  invoiceNumbers,
  orgId,
  accountId,
}: InvoiceActionsProps) => {
  const [invoiceActions, setInvoiceActions] = useState<DefaultActionMenuItem[]>(
    []
  );
  const [, setIsReceivePaymentFormActive] = useAtom(
    isReceivePaymentFormActiveAtom
  );
  const allInvoiceActions: {
    [key in InvoiceStatus]?: DefaultActionMenuItem;
  } = {
    [InvoiceStatus.Cancelled]: {
      label: "Cancel Invoice",
      icon: CancelIcon,
      action: () => {
        console.log("clicked Cancel");
      },
    },
    [InvoiceStatus.OnHold]: {
      label: "Hold Invoice",
      icon: HourglassTopIcon,
      action: () => {
        console.log("clicked Invoice");
      },
    },
    [InvoiceStatus.Pending]: {
      label: "To Pending",
      icon: PendingIcon,
      action: () => {
        console.log("clicked Pending");
      },
    },
    [InvoiceStatus.InProgress]: {
      label: "To Progress",
      icon: RestartAltIcon,
      action: () => {
        console.log("clicked Progress");
      },
    },
    [InvoiceStatus.PaymentReceived]: {
      label: "Receive Payment",
      icon: PaymentIcon,
      action: () => {
        setIsReceivePaymentFormActive(true);
      },
    },
    [InvoiceStatus.SendToCustomer]: {
      label: "To Customer",
      icon: SendIcon,
      action: () => {
        console.log("clicked Customer");
      },
    },
  };

  useEffect(() => {
    setIsReceivePaymentFormActive(false);
  }, []);

  useEffect(() => {
    setInvoiceActions(getInvoiceActions(status));
  }, [status]);

  const getInvoiceActions = (
    status: InvoiceStatus
  ): DefaultActionMenuItem[] => {
    const makeDefault = (
      item?: DefaultActionMenuItem
    ): DefaultActionMenuItem | undefined => {
      return item
        ? {
            default: true,
            ...item,
          }
        : item;
    };

    if (
      !Object.keys(INVOICE_STATUS_TARGET_ACTIONS).includes(status) ||
      !INVOICE_STATUS_TARGET_ACTIONS[status]
    ) {
      return [];
    }

    const { defaultAction, targetActions } =
      INVOICE_STATUS_TARGET_ACTIONS[status];

    const invoiceActions = [
      makeDefault(allInvoiceActions[defaultAction]),
      ...targetActions.map((key) => allInvoiceActions[key]),
    ].filter((e) => e !== undefined);

    return invoiceActions;
  };

  return <DefaultActionMenu menu={invoiceActions} />;
};
