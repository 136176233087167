import { Box, CircularProgress, Grid2 as Grid } from "@mui/material";
import { BusinessInfoTile } from "../common/tiles";
import {
  useDeleteOrganizationMutation,
  useFetchOrganizationsQuery,
} from "../../api";
import { useAtom } from "jotai";
import {
  isCreateOrganizationModeAtom,
  organizationDetailSelectedAtom,
  organizationInfoAtom,
  organizationNotExistsFlagAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
} from "../../atoms";
import { ChangeEvent, useEffect, useState } from "react";
import { OrganizationModel } from "../../models";
import { BusinessViewToolbar } from "../toolbar";
import { ConfirmationModal } from "../common";
import { theme } from "../../utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export const OrganizationLayout = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizations, setOrganizations] = useAtom(organizationInfoAtom);
  const [organizationNotExistFlag] = useAtom(organizationNotExistsFlagAtom);
  const [, setIsOrganizationDetailSelected] = useAtom(
    organizationDetailSelectedAtom
  );
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [, setIsCreateOrganizationMode] = useAtom(isCreateOrganizationModeAtom);
  const fetchOrganizationsQuery = useFetchOrganizationsQuery();
  const deleteOrganizationMutation = useDeleteOrganizationMutation();
  const filteredOrganizations = organizations.filter((item) =>
    item.name.toLowerCase().includes(searchTerm)
  );
  useEffect(() => {
    if (
      fetchOrganizationsQuery.isSuccess &&
      !(
        fetchOrganizationsQuery.isFetching ||
        fetchOrganizationsQuery.isRefetching
      )
    ) {
      const organizations = OrganizationModel.buildOrganizationBusinessInfo(
        fetchOrganizationsQuery.data
      );
      setOrganizations(organizations);
    }

    if (fetchOrganizationsQuery.isError || fetchOrganizationsQuery.isLoading) {
      setOrganizations([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  useEffect(() => {
    if (deleteOrganizationMutation.isSuccess) {
      fetchOrganizationsQuery.refetch();

      setToastOptions({
        open: true,
        message: "Organization deleted successfully",
        severity: "success",
      });
    }

    if (deleteOrganizationMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteOrganizationMutation.isSuccess,
    deleteOrganizationMutation.isError,
    deleteOrganizationMutation.isLoading,
    deleteOrganizationMutation.data,
    deleteOrganizationMutation.error,
  ]);

  const handleClick = (orgId: number) => {
    setIsOrganizationDetailSelected(true);
    setSelectedOrgId(orgId);
  };

  const handleCreate = () => {
    setIsCreateOrganizationMode(true);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setSelectedOrgId(0);
    setIsModalOpen(false);
  };

  const handleClickDelete = (id: number) => {
    setSelectedOrgId(id);
    handleOpenModal();
  };

  const handleConfirmDelete = () => {
    deleteOrganizationMutation.mutate({
      orgId: selectedOrgId,
    });
    handleCloseModal();
  };

  return (
    <>
      <Box
        sx={{
          height: "fit-content",
          minHeight: "90vh",
          display: "flex",
          width: "100%",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <BusinessViewToolbar
          addLabel={"organization"}
          handleCreate={handleCreate}
          handleSearch={handleSearch}
        />
        <Grid
          container
          direction={"row"}
          spacing={2}
          sx={{
            width: "100%",
            height: "100%",
            flexGrow: 1,
            alignItems:
              fetchOrganizationsQuery.isLoading ||
              fetchOrganizationsQuery.isRefetching ||
              fetchOrganizationsQuery.isFetching ||
              deleteOrganizationMutation.isLoading ||
              organizationNotExistFlag
                ? "center"
                : "none",
            alignContent:
              fetchOrganizationsQuery.isLoading ||
              fetchOrganizationsQuery.isRefetching ||
              fetchOrganizationsQuery.isFetching ||
              deleteOrganizationMutation.isLoading ||
              organizationNotExistFlag
                ? "none"
                : "baseline",
          }}
        >
          {fetchOrganizationsQuery.isLoading ||
          fetchOrganizationsQuery.isRefetching ||
          fetchOrganizationsQuery.isFetching ||
          deleteOrganizationMutation.isLoading ? (
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Grid>
          ) : fetchOrganizationsQuery.isSuccess ? (
            filteredOrganizations.map((item, index) => (
              <Grid key={index} size={{ lg: 4, md: 6, sm: 6, xs: 12, xl: 3 }}>
                <BusinessInfoTile
                  data={item}
                  handleClick={handleClick}
                  handleDelete={handleClickDelete}
                />
              </Grid>
            ))
          ) : organizationNotExistFlag ? (
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              Nothing to show
            </Grid>
          ) : (
            fetchOrganizationsQuery.isError && (
              <Grid
                size={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Something went wrong
              </Grid>
            )
          )}
        </Grid>
      </Box>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={"Delete Organization"}
        message={"Are you sure that you want to delete this organization?"}
      />
    </>
  );
};
