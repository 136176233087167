import { useEffect, useState } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { SummaryTile, SuperAdminDashboardResponse } from "../../types";
import { useFetchSuperAdminDashboardQuery } from "../../api";
import {
  DashboardSummaryRow,
  ProgressComponent,
  TopItemsList,
} from "./fragments";
import {
  getDate,
  getDateRange,
  getInvoiceAmountByDateRange,
  getNumberOfWashesByDate,
  getTotalNumberOfUsers,
} from "../../utils";
import { InvoiceBarChart } from "./fragments/InvoiceBarChart";

export const SuperAdminDash = () => {
  const fetchDashboardQuery = useFetchSuperAdminDashboardQuery(0);
  const currentDate = getDate("today");
  const yesterday = getDate("yesterday");
  const lastMonth = getDateRange("lastMonth");
  const thisMonth = getDateRange("thisMonth");
  const [dashboardItems, setDashboardItems] =
    useState<SuperAdminDashboardResponse>();

  useEffect(() => {
    if (fetchDashboardQuery.isSuccess) {
      setDashboardItems(fetchDashboardQuery.data);
    }
  }, [fetchDashboardQuery.isSuccess, fetchDashboardQuery.data]);

  const dashboardSummaryItems: SummaryTile[] = [
    {
      heading: "Number of Organizations",
      total: dashboardItems?.numberOfOrgs.toString(),
      isLoading: fetchDashboardQuery.isLoading,
    },
    {
      heading: "Total Invoices",
      total:
        "$ " +
        getInvoiceAmountByDateRange(
          dashboardItems?.invoicesGroupedByDate || [],
          thisMonth.startDate,
          thisMonth.endDate
        ),
      isLoading: fetchDashboardQuery.isLoading,
      footer: (
        <ProgressComponent
          progressText="since last month"
          progress={
            getInvoiceAmountByDateRange(
              dashboardItems?.invoicesGroupedByDate || [],
              thisMonth.startDate,
              thisMonth.endDate
            ) -
            getInvoiceAmountByDateRange(
              dashboardItems?.invoicesGroupedByDate || [],
              lastMonth.startDate,
              lastMonth.endDate
            )
          }
        />
      ),
    },
    {
      heading: "Washes Today",
      total: getNumberOfWashesByDate(
        dashboardItems?.washesGroupedByDate || [],
        currentDate
      ).toString(),
      isLoading: fetchDashboardQuery.isLoading,
      footer: (
        <ProgressComponent
          progressText="since last day"
          progress={
            getNumberOfWashesByDate(
              dashboardItems?.washesGroupedByDate || [],
              currentDate
            ) -
            getNumberOfWashesByDate(
              dashboardItems?.washesGroupedByDate || [],
              yesterday
            )
          }
        />
      ),
    },
    {
      heading: "Users",
      total: getTotalNumberOfUsers(
        dashboardItems?.usersGroupedByRole || []
      ).toString(),
      isLoading: fetchDashboardQuery.isLoading,
    },
  ];

  return (
    <Box
      sx={{
        height: "fit-content",
        minHeight: "90vh",
        display: "flex",
        width: "100%",
        p: 3,
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Grid2
        container
        direction={"row"}
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          flexGrow: 0.1,
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
          Dashboard
        </Typography>
      </Grid2>
      <Grid2
        container
        direction={"row"}
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          flexGrow: 0.1,
        }}
      >
        <DashboardSummaryRow tiles={dashboardSummaryItems} />
      </Grid2>
      <Grid2
        container
        direction={"row"}
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          flexGrow: 0.1,
          pt: 2,
        }}
      >
        <Grid2 size={{ lg: 9, md: 12, sm: 12, xs: 12, xl: 9 }}>
          <InvoiceBarChart
            groupedInvoice={dashboardItems?.invoicesGroupedByStatus || []}
            label={"Invoice Status"}
          />
        </Grid2>
        <Grid2 size={{ lg: 3, md: 12, sm: 12, xs: 12, xl: 3 }}>
          <TopItemsList
            title={"Top Washes"}
            items={dashboardItems?.washesGroupedByWashType || []}
            displayKey={"key"}
            displayValue={"numberOfWashes"}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
