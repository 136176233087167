import { Box } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import { Button, TextField } from "../../../../common";
import { useValidateForm } from "../../../../../hooks";
import { SelectBox } from "../../../../common";
import { DropdownItem, OrganizationProfileInfo } from "../../../../../types";
import { editOrganizationProfileBodySchema } from "../../../../../validations";
import { useState } from "react";
import { isCreateOrganizationModeAtom } from "../../../../../atoms";
import { useAtom } from "jotai";

const dropdown: DropdownItem[] = [{ key: "America/Phoenix", value: 1 }];

type EditOrganizationProfileBodyProps = {
  defaultValues?: OrganizationProfileInfo;
  handleEdit: (
    data: Pick<
      OrganizationProfileInfo,
      | "contactPerson"
      | "contactNumber"
      | "timezoneId"
      | "officeNumber"
      | "mobileNumber"
    >
  ) => void;
  handleDiscard: () => void;
};

export const EditOrganizationProfileBody = ({
  defaultValues,
  handleEdit,
  handleDiscard,
}: EditOrganizationProfileBodyProps) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [isCreateOrganizationMode] = useAtom(isCreateOrganizationModeAtom);

  const { register, handleSubmit, errors } = useValidateForm(
    editOrganizationProfileBodySchema
  );

  const handleChange = () => {
    setHasChanges(true);
  };

  return (
    <Box
      onSubmit={handleSubmit(handleEdit)}
      component={"form"}
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        direction={{ lg: "row", xs: "column" }}
        sx={{
          width: "100%",
          height: "100%",
          px: 3,
          py: 3,
          alignItems: "center",
        }}
      >
        <Grid
          container
          size={{ lg: 5, xs: 12 }}
          gap={3}
          sx={{
            px: { md: 4, xs: 0, sm: 2 },
            py: 0,
            flexDirection: "column",
          }}
        >
          <Grid container size={12}>
            <TextField
              register={register}
              defaultValue={defaultValues?.contactPerson}
              label="Contact Person"
              name="contactPerson"
              error={errors["contactPerson"]}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              type="number"
              register={register}
              defaultValue={defaultValues?.contactNumber}
              label="Contact Number"
              name="contactNumber"
              error={errors["contactNumber"]}
              onChange={handleChange}
              allowScientificNotation={false}
              required
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              type="number"
              register={register}
              defaultValue={defaultValues?.officeNumber}
              label="Office Number"
              name="officeNumber"
              error={errors["officeNumber"]}
              onChange={handleChange}
              allowScientificNotation={false}
              required
            />
          </Grid>
        </Grid>
        <Grid
          container
          size={{ lg: 5, xs: 12 }}
          gap={3}
          sx={{
            px: { md: 4, xs: 0, sm: 2 },
            py: 1.6,
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Grid container size={12}>
            <TextField
              type="number"
              register={register}
              defaultValue={defaultValues?.mobileNumber}
              label="Mobile Number"
              name="mobileNumber"
              error={errors["mobileNumber"]}
              onChange={handleChange}
              allowScientificNotation={false}
              required
            />
          </Grid>
          <Grid container size={12}>
            <SelectBox
              register={register}
              defaultValue={defaultValues?.timezoneId}
              label="Timezone"
              name="timezoneId"
              dropdown={dropdown}
              error={errors["timezoneId"]}
              onChange={handleChange}
              valueAsNumber={true}
              required
            />
          </Grid>
        </Grid>
        <Grid
          container
          size={{ xs: 12, lg: 2 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            pl: { md: 4, xs: 0, sm: 2, lg: 0 },
            pr: { md: 4, xs: 0, sm: 2, lg: 2 },
            pt: { lg: 0, xs: 2 },
            pb: 0,
            height: { lg: "100%", xs: "fit-content" },
          }}
        >
          {!isCreateOrganizationMode && (
            <Button
              label="Discard"
              variantType={"secondary"}
              sx={{
                padding: "0.5rem",
                width: "100%",
              }}
              onClick={handleDiscard}
            />
          )}
          <Button
            type={hasChanges ? "submit" : undefined}
            disabled={!hasChanges || isCreateOrganizationMode}
            label="Save Changes"
            variantType={
              hasChanges && !isCreateOrganizationMode ? "primary" : "secondary"
            }
            sx={{
              padding: "0.5rem",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
