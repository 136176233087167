import React, { useEffect, useState } from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import {
  Visibility as PreviewIcon,
  Payment as PaymentIcon,
  Download,
} from "@mui/icons-material";
import { useFetchPreviewInvoiceQuery } from "../../../api/invoiceService";
import { Modal, PreviewModal } from "../../common";
import {
  buildQueryString,
  downloadFile,
  getInvoiceDetailsRoute,
  theme,
} from "../../../utils";
import Loading from "../../common/loading/Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../../atoms";
import { ACCOUNT_DETAILS_TAB_KEY } from "../../../constants";

type InvoiceActionIconsProps = {
  invoiceNumber: string;
  accountId: number;
  orgId: number;
};

export const InvoiceActionIcons: React.FC<InvoiceActionIconsProps> = ({
  invoiceNumber,
  accountId,
  orgId,
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkToPdfData, setLinkToPdfData] = useState<string>("");
  const [fetchOnClick, setFetchOnClick] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [user] = useAtom(userAtom);

  const fetchInvoicePreviewQuery = useFetchPreviewInvoiceQuery(
    orgId,
    accountId,
    invoiceNumber,
    fetchOnClick
  );
  const pdfFileName = `${invoiceNumber}.pdf`;
  const handleOpenModal = () => {
    setFetchOnClick(true);
    setIsModalOpen(true);
  };
  const downloadPdf = () => {
    if (linkToPdfData) downloadFile(linkToPdfData, pdfFileName);
    else {
      setFetchOnClick(true);
      setIsDownloading(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFetchOnClick(false);
  };

  useEffect(() => {
    if (fetchInvoicePreviewQuery.isSuccess && fetchInvoicePreviewQuery.data) {
      const dataToArrayBuffer = new Uint8Array(fetchInvoicePreviewQuery.data);
      const linkToPdf = URL.createObjectURL(
        new Blob([dataToArrayBuffer], {
          type: "application/pdf",
        })
      );
      setLinkToPdfData(linkToPdf);
      if (isDownloading) {
        downloadFile(linkToPdf, pdfFileName);
        setIsDownloading(false);
      }
    }
  }, [fetchInvoicePreviewQuery.isSuccess, fetchInvoicePreviewQuery.data]);

  const showInvoiceDetails = () => {
    if (user) {
      const tab = queryParams.get(ACCOUNT_DETAILS_TAB_KEY);
      const route = getInvoiceDetailsRoute(user);
      const path =
        (tab ? `${tab}/` : "") +
        route.path.replace(":invoiceNumber", invoiceNumber).concat(
          buildQueryString({
            orgId,
            accountId,
          })
        );

      navigate(path);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flow",
          width: "50px",
        }}
      >
        <Tooltip title="Preview">
          <IconButton onClick={handleOpenModal} aria-label="preview">
            <PreviewIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download">
          <IconButton aria-label="download" onClick={downloadPdf}>
            <Download
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Payment">
          <IconButton aria-label="payment" onClick={showInvoiceDetails}>
            <PaymentIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
      </Box>

      {isModalOpen && (
        <PreviewModal
          open={isModalOpen}
          linkToPdfData={linkToPdfData}
          loader={fetchInvoicePreviewQuery.isLoading}
          onClose={handleCloseModal}
          fileName={pdfFileName}
        />
      )}
      {isDownloading && (
        <Modal
          open={isDownloading}
          onClose={() => setIsDownloading(false)}
          title={""}
          content={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                padding: "30px",
                textAlign: "center",
                gap: 2,
              }}
            >
              <Loading message="Downloading..."></Loading>
            </Box>
          }
          sx={{
            modal: {
              width: { lg: "40%", xs: "80%", sm: "80%" },
            },
          }}
        />
      )}
    </>
  );
};
