import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import { AccountDetail, AccountLayout } from "../components/account";
import { Fleet } from "../components/fleet";
import { Invoice, InvoiceDetails } from "../components/invoice";
import { Login } from "../components/login";
import { Organization } from "../components/organization/Organization";
import {
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  UserProfile,
} from "../components/users";
import { WashInventory } from "../components/washes";
import { Route } from "../types";
import {
  ROUTE_ADM_ACCOUNTS,
  ROUTE_ADM_DASHBOARD,
  ROUTE_ADM_INVOICES,
  ROUTE_ADM_ORGANIZATION_DETAILS,
  ROUTE_ADM_REPORTS,
  ROUTE_ADM_USERS,
  ROUTE_ADM_WASHES,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_CUST_ACCOUNT_DETAILS,
  ROUTE_CUST_DASHBOARD,
  ROUTE_CUST_FLEET,
  ROUTE_CUST_INVOICES,
  ROUTE_CUST_WASHES,
  ROUTE_ERROR,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_SADM_ACCOUNTS,
  ROUTE_SADM_DASHBOARD,
  ROUTE_SADM_INVOICES,
  ROUTE_SADM_ORGANIZATIONS,
  ROUTE_SADM_REPORTS,
  ROUTE_SADM_USERS,
  ROUTE_SADM_WASHES,
  ROUTE_USER_PROFILE,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_SADM_INVOICE_DETAILS,
  ROUTE_ADM_INVOICE_DETAILS,
  ROUTE_CUST_INVOICE_DETAILS,
  ROUTE_SADM_ACCOUNT_INVOICE_DETAILS,
  ROUTE_SADM_ACCOUNT_DETAILS,
  ROUTE_ADM_ACCOUNT_DETAILS,
  ROUTE_ADM_ACCOUNT_INVOICE_DETAILS,
  ROUTE_CREATE_ACCOUNT,
} from "./constants";
import {
  MdAccountTree,
  MdDashboard,
  MdInfoOutline,
  MdLocalCarWash,
  MdMonetizationOn,
  MdOutlineDirectionsBus,
  MdPeople,
  MdReceipt,
  MdSchema,
} from "react-icons/md";
import { DashboardErrorPage, ErrorPage } from "../components/error";
import {
  AdminDashboard,
  SuperAdminDash,
  UserDashboard,
} from "../components/dashboard";
import { OrganizationDetail } from "../components/organization";

export const LoginRoute: Route = {
  name: "Login",
  displayName: "login",
  path: ROUTE_LOGIN,
  component: <Login />,
  exact: true,
};

export const HomeRoute: Route = {
  name: "Home",
  displayName: "home",
  path: ROUTE_HOME,
  component: <></>,
};

export const ErrorRoute: Route = {
  name: "error",
  displayName: "Error",
  icon: <MdAccountTree />,
  path: ROUTE_ERROR,
  component: <ErrorPage />,
};

export const DashboardErrorRoute: Route = {
  name: "Dashboard Error",
  displayName: "Dashboard Error",
  icon: <MdAccountTree />,
  path: ROUTE_ERROR,
  component: <DashboardErrorPage />,
  isVisible: false,
};

export const CustomerAccountDetailRoute: Route = {
  name: "myDetails",
  displayName: "Account Details",
  icon: <MdInfoOutline />,
  path: ROUTE_CUST_ACCOUNT_DETAILS,
  component: <AccountDetail />,
};

export const CustomerDashboardRoute: Route = {
  name: "dashboard",
  displayName: "Dashboard",
  icon: <MdDashboard />,
  path: ROUTE_CUST_DASHBOARD,
  component: <UserDashboard />,
};

export const CustomerFleetRoute: Route = {
  name: "fleet",
  displayName: "Fleet",
  icon: <MdOutlineDirectionsBus />,
  path: ROUTE_CUST_FLEET,
  component: <Fleet />,
};

export const CustomerInvoiceDetailsRoute: Route = {
  name: "invoiceDetails",
  displayName: "Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_CUST_INVOICE_DETAILS,
  isVisible: false,
};

export const CustomerInvoicesRoute: Route = {
  name: "invoices",
  displayName: "Invoices",
  icon: <MdMonetizationOn />,
  path: ROUTE_CUST_INVOICES,
  component: <Invoice />,
  childRoutes: [CustomerInvoiceDetailsRoute],
};

export const CustomerWashesRoute: Route = {
  name: "washes",
  displayName: "Washes",
  icon: <MdLocalCarWash />,
  path: ROUTE_CUST_WASHES,
  component: <WashInventory />,
};

export const SadmDashboardRoute: Route = {
  name: "dashboard",
  displayName: "Dashboard",
  icon: <MdDashboard />,
  component: <SuperAdminDash />,
  path: ROUTE_SADM_DASHBOARD,
};

export const SadmInvoiceDetailsRoute: Route = {
  name: "invoiceDetails",
  displayName: "Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_SADM_INVOICE_DETAILS,
  isVisible: false,
};

export const SadmAccountInvoiceDetailsRoute: Route = {
  name: "accountInvoiceDetails",
  displayName: "Account Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_SADM_ACCOUNT_INVOICE_DETAILS,
  isVisible: false,
};

export const SadmAccountDetailsRoute: Route = {
  name: "accountDetails",
  displayName: "Account Details",
  icon: <MdAccountTree />,
  component: <AccountDetail />,
  path: ROUTE_SADM_ACCOUNT_DETAILS,
  childRoutes: [SadmAccountInvoiceDetailsRoute],
  isVisible: false,
};

export const SadmCreateAccountRoute: Route = {
  name: "createAccount",
  displayName: "Create Account",
  icon: <MdAccountTree />,
  component: <AccountDetail />,
  path: ROUTE_CREATE_ACCOUNT,
  isVisible: false,
};

export const SadmAccountsRoute: Route = {
  name: "accounts",
  displayName: "Accounts",
  icon: <MdAccountTree />,
  component: <AccountLayout />,
  path: ROUTE_SADM_ACCOUNTS,
  childRoutes: [SadmCreateAccountRoute, SadmAccountDetailsRoute],
};

export const SadmInvoicesRoute: Route = {
  name: "invoices",
  displayName: "Invoices",
  icon: <MdMonetizationOn />,
  component: <Invoice />,
  path: ROUTE_SADM_INVOICES,
  childRoutes: [SadmInvoiceDetailsRoute],
};

export const SadmOrganizationsRoute: Route = {
  name: "organizations",
  displayName: "Organizations",
  icon: <MdSchema />,
  component: <Organization />,
  path: ROUTE_SADM_ORGANIZATIONS,
};

export const SadmReportsRoute: Route = {
  name: "reports",
  displayName: "Reports",
  icon: <MdReceipt />,
  component: <h1>reports page</h1>,
  path: ROUTE_SADM_REPORTS,
};

export const SadmUsersRoute: Route = {
  name: "users",
  displayName: "Users",
  icon: <MdPeople />,
  component: <h1>users page</h1>,
  path: ROUTE_SADM_USERS,
};

export const SadmWashesRoute: Route = {
  name: "washes",
  displayName: "Washes",
  icon: <MdLocalCarWash />,
  component: <WashInventory />,
  path: ROUTE_SADM_WASHES,
};

export const AdminDashboardRoute: Route = {
  name: "dashboard",
  displayName: "Dashboard",
  icon: <MdDashboard />,
  component: <AdminDashboard />,
  path: ROUTE_ADM_DASHBOARD,
};

export const AdminInvoiceDetailsRoute: Route = {
  name: "invoiceDetails",
  displayName: "Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_ADM_INVOICE_DETAILS,
  isVisible: false,
};

export const AdminAccountInvoiceDetailsRoute: Route = {
  name: "accountInvoiceDetails",
  displayName: "Account Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_ADM_ACCOUNT_INVOICE_DETAILS,
  isVisible: false,
};

export const AdminAccountDetailsRoute: Route = {
  name: "accountDetails",
  displayName: "Account Details",
  icon: <MdAccountTree />,
  component: <AccountDetail />,
  path: ROUTE_ADM_ACCOUNT_DETAILS,
  childRoutes: [AdminAccountInvoiceDetailsRoute],
  isVisible: false,
};

export const AdminCreateAccountRoute: Route = {
  name: "createAccount",
  displayName: "Create Account",
  icon: <MdAccountTree />,
  component: <AccountDetail />,
  path: ROUTE_CREATE_ACCOUNT,
  isVisible: false,
};

export const AdminAccountsRoute: Route = {
  name: "accounts",
  displayName: "Accounts",
  icon: <MdAccountTree />,
  component: <AccountLayout />,
  path: ROUTE_ADM_ACCOUNTS,
  childRoutes: [AdminCreateAccountRoute, AdminAccountDetailsRoute],
};

export const AdminInvoicesRoute: Route = {
  name: "invoices",
  displayName: "Invoices",
  icon: <MdMonetizationOn />,
  component: <Invoice />,
  path: ROUTE_ADM_INVOICES,
  childRoutes: [AdminInvoiceDetailsRoute],
};

export const AdminOrganizationDetailRoute: Route = {
  name: "orgDetails",
  displayName: "Org Details",
  icon: <MdInfoOutline />,
  path: ROUTE_ADM_ORGANIZATION_DETAILS,
  component: <OrganizationDetail />,
};

export const AdminReportsRoute: Route = {
  name: "reports",
  displayName: "Reports",
  icon: <MdReceipt />,
  component: <h1>reports page</h1>,
  path: ROUTE_ADM_REPORTS,
};

export const AdminUsersRoute: Route = {
  name: "users",
  displayName: "Users",
  icon: <MdPeople />,
  component: <h1>users page</h1>,
  path: ROUTE_ADM_USERS,
};

export const AdminWashesRoute: Route = {
  name: "washes",
  displayName: "Washes",
  icon: <MdLocalCarWash />,
  component: <WashInventory />,
  path: ROUTE_ADM_WASHES,
};

export const UserProfileRoute: Route = {
  name: "profile",
  displayName: "Profile",
  icon: <Person2OutlinedIcon />,
  path: ROUTE_USER_PROFILE,
  component: <UserProfile />,
  isVisible: false,
};

export const ChangePasswordRoute: Route = {
  name: "change-password",
  displayName: "Change Password",
  path: ROUTE_CHANGE_PASSWORD,
  component: <ChangePassword />,
  isVisible: false,
};

export const ForgotPasswordRoute: Route = {
  name: "forgot-password",
  displayName: "Forgot Password",
  path: ROUTE_FORGOT_PASSWORD,
  component: <ForgotPassword />,
  isVisible: false,
};

export const ResetPasswordRoute: Route = {
  name: "reset-password",
  displayName: "Reset Password",
  path: ROUTE_RESET_PASSWORD,
  component: <ResetPassword />,
  isVisible: false,
};

export const openRoutes = [LoginRoute, ForgotPasswordRoute, ResetPasswordRoute];
export const adminRoutes = [
  AdminDashboardRoute,
  AdminOrganizationDetailRoute,
  AdminAccountsRoute,
  AdminWashesRoute,
  AdminInvoicesRoute,
  AdminUsersRoute,
  AdminReportsRoute,
  UserProfileRoute,
];
export const defaultRoutes = [
  CustomerDashboardRoute,
  CustomerAccountDetailRoute,
  CustomerFleetRoute,
  CustomerWashesRoute,
  CustomerInvoicesRoute,
  UserProfileRoute,
];
export const superAdminRoutes = [
  SadmDashboardRoute,
  SadmOrganizationsRoute,
  SadmAccountsRoute,
  SadmWashesRoute,
  SadmInvoicesRoute,
  SadmUsersRoute,
  SadmReportsRoute,
  UserProfileRoute,
];
export const allDashboardRoutes = [
  HomeRoute,
  ...defaultRoutes,
  ...superAdminRoutes,
  ...adminRoutes,
];
