import { Box, Collapse, Grid2 as Grid, Skeleton } from "@mui/material";
import { AccountLocation } from "../../types";
import { CreateLocation, LocationItem } from "./fragments";
import { useAtom } from "jotai";
import {
  isCreateLocationComponentActiveAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
} from "../../atoms";
import {
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useFetchAccountLocationsQuery,
  useUpdateLocationMutation,
} from "../../api";
import { useEffect, useState } from "react";
import { LocationModel } from "../../models";
import { ConfirmationModal } from "../common";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { theme } from "../../utils";

export const Location = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [selectedLocationToEdit, setSelectedLocationToEdit] =
    useState<AccountLocation>();
  const [selectedLocationToDelete, setSelectedLocationToDelete] =
    useState<AccountLocation>();
  const [isCreateLocationComponentActive, setIsCreateLocationComponentActive] =
    useAtom(isCreateLocationComponentActiveAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [locations, setLocations] = useState<AccountLocation[]>([]);
  const fetchAccountLocationsQuery = useFetchAccountLocationsQuery(
    selectedOrgId,
    selectedAccountId || 0
  );
  const createLocationMutation = useCreateLocationMutation();
  const updateLocationMutation = useUpdateLocationMutation();
  const deleteLocationMutation = useDeleteLocationMutation();

  useEffect(() => {
    if (fetchAccountLocationsQuery.isSuccess) {
      const locations = LocationModel.buildLocations(
        fetchAccountLocationsQuery.data
      );

      setLocations(locations);
    }
  }, [
    fetchAccountLocationsQuery.isSuccess,
    fetchAccountLocationsQuery.isError,
    fetchAccountLocationsQuery.isLoading,
    fetchAccountLocationsQuery.data,
    fetchAccountLocationsQuery.error,
  ]);

  useEffect(() => {
    setSelectedLocationToEdit(undefined);
  }, []);

  useEffect(() => {
    if (createLocationMutation.isSuccess) {
      fetchAccountLocationsQuery.refetch();

      setIsCreateLocationComponentActive(false);
      setToastOptions({
        open: true,
        message: "Location added successfully",
        severity: "success",
      });
    }

    if (createLocationMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    createLocationMutation.isSuccess,
    createLocationMutation.isError,
    createLocationMutation.isLoading,
    createLocationMutation.data,
    createLocationMutation.error,
  ]);

  useEffect(() => {
    if (updateLocationMutation.isSuccess) {
      fetchAccountLocationsQuery.refetch();

      setSelectedLocationToEdit(undefined);

      setToastOptions({
        open: true,
        message: "Location updated successfully",
        severity: "success",
      });
    }

    if (updateLocationMutation.isError) {
      setSelectedLocationToEdit(undefined);

      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateLocationMutation.isSuccess,
    updateLocationMutation.isError,
    updateLocationMutation.isLoading,
    updateLocationMutation.data,
    updateLocationMutation.error,
  ]);

  useEffect(() => {
    if (deleteLocationMutation.isSuccess) {
      fetchAccountLocationsQuery.refetch();

      setSelectedLocationToDelete(undefined);
      setToastOptions({
        open: true,
        message: "Location deleted successfully",
        severity: "success",
      });
    }

    if (deleteLocationMutation.isError) {
      setSelectedLocationToDelete(undefined);
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteLocationMutation.isSuccess,
    deleteLocationMutation.isError,
    deleteLocationMutation.isLoading,
    deleteLocationMutation.data,
    deleteLocationMutation.error,
  ]);

  const handleCreate = (location: Omit<AccountLocation, "locationNumber">) => {
    createLocationMutation.mutate({
      accountId: selectedAccountId || 0,
      orgId: selectedOrgId,
      location: location,
    });
  };

  const handleEdit = (location: Omit<AccountLocation, "locationNumber">) => {
    if (selectedLocationToEdit) {
      updateLocationMutation.mutate({
        accountId: selectedAccountId || 0,
        orgId: selectedOrgId,
        locationNumber: selectedLocationToEdit.locationNumber,
        location: location,
      });
    }
  };

  const handleDiscard = () => {
    setSelectedLocationToEdit(undefined);
  };

  const handleClickEdit = (location: AccountLocation) => {
    setSelectedLocationToEdit(location);
  };

  const handleClickDelete = (location: AccountLocation) => {
    setIsModalOpen(true);
    setSelectedLocationToDelete(location);
  };

  const handleCloseModal = () => {
    setSelectedLocationToDelete(undefined);
    setIsModalOpen(false);
  };

  const handleConfirmDelete = () => {
    if (selectedLocationToDelete) {
      deleteLocationMutation.mutate({
        accountId: selectedAccountId || 0,
        orgId: selectedOrgId,
        locationNumber: selectedLocationToDelete.locationNumber,
      });
    }
    handleCloseModal();
  };

  return (
    <>
      <Box
        sx={{
          p: "4px",
          height: "100%",
          minHeight: "213.5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!fetchAccountLocationsQuery.isLoading &&
        !fetchAccountLocationsQuery.isFetching &&
        fetchAccountLocationsQuery.isError &&
        locations.length === 0 &&
        !isCreateLocationComponentActive ? (
          <Grid
            size={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            Nothing to show
          </Grid>
        ) : (
          <Grid
            container
            size={12}
            direction={"column"}
            sx={{
              alignItems: "center",
            }}
          >
            {updateLocationMutation.isLoading ||
            deleteLocationMutation.isLoading ||
            fetchAccountLocationsQuery.isLoading ||
            fetchAccountLocationsQuery.isFetching ? (
              <Skeleton
                variant="rounded"
                sx={{ height: 213.5, width: "100%", borderRadius: "10px" }}
              />
            ) : (
              <>
                <Collapse in={isCreateLocationComponentActive} timeout={500}>
                  <div>
                    <CreateLocation handleCreate={handleCreate} />
                  </div>
                </Collapse>
                {locations.length > 0 &&
                  locations.map((item, index) => (
                    <Box key={index} sx={{ width: "100%" }}>
                      <Collapse
                        in={
                          selectedLocationToEdit?.locationNumber ===
                          item.locationNumber
                        }
                        timeout={500}
                      >
                        <div>
                          {selectedLocationToEdit && (
                            <CreateLocation
                              defaultValues={selectedLocationToEdit}
                              handleCreate={handleEdit}
                              handleDiscard={handleDiscard}
                            />
                          )}
                        </div>
                      </Collapse>
                      <Collapse
                        sx={{ width: "100%" }}
                        in={
                          !(
                            selectedLocationToEdit &&
                            selectedLocationToEdit.locationNumber ===
                              item.locationNumber
                          )
                        }
                        timeout={500}
                      >
                        <LocationItem
                          data={item}
                          allowDelete={locations.length > 1}
                          handleEdit={handleClickEdit}
                          handleDelete={handleClickDelete}
                        />
                      </Collapse>
                    </Box>
                  ))}
              </>
            )}
          </Grid>
        )}
      </Box>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={"Delete location"}
        message={"Are you sure you want to delete this location?"}
      />
    </>
  );
};
