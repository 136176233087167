import { Collapse, Grid2 as Grid, Skeleton } from "@mui/material";
import { ContactList, CreateContact } from "./fragments";
import {
  useCreateContactMutation,
  useDeleteContactMutation,
  useFetchAccountInfoQuery,
  useUpdateContactMutation,
} from "../../api";
import {
  isCreateContactComponentActiveAtom,
  selectedAccountIdAtom,
  selectedContactToDeleteAtom,
  selectedContactToEditAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
} from "../../atoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { AccountContact } from "../../types";
import { objectDiff } from "../../utils";

export const Contact = () => {
  const [, setSelectedContactToEdit] = useAtom(selectedContactToEditAtom);
  const [selectedContactToDelete, setSelectedContactToDelete] = useAtom(
    selectedContactToDeleteAtom
  );
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [isCreateContactComponentActive, setIsCreateContactComponentActive] =
    useAtom(isCreateContactComponentActiveAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [contactList, setContactList] = useState<AccountContact[]>([]);
  const fetchAccountInfoQuery = useFetchAccountInfoQuery(
    selectedOrgId,
    selectedAccountId || 0,
    {
      contacts: true,
    }
  );
  const createContactMutation = useCreateContactMutation();
  const updateContactMutation = useUpdateContactMutation();
  const deleteContactMutation = useDeleteContactMutation();

  useEffect(() => {
    setSelectedContactToEdit(undefined);
    setSelectedContactToDelete(undefined);
  }, []);

  useEffect(() => {
    if (fetchAccountInfoQuery.isSuccess) {
      setContactList(fetchAccountInfoQuery.data.accountContacts || []);
    }
  }, [
    fetchAccountInfoQuery.isSuccess,
    fetchAccountInfoQuery.isError,
    fetchAccountInfoQuery.data,
    fetchAccountInfoQuery.error,
    fetchAccountInfoQuery.isLoading,
  ]);

  useEffect(() => {
    if (createContactMutation.isSuccess) {
      fetchAccountInfoQuery.refetch();
      setIsCreateContactComponentActive(false);

      setToastOptions({
        open: true,
        message: "Contact created",
        severity: "success",
      });
    }

    if (createContactMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    createContactMutation.isSuccess,
    createContactMutation.isError,
    createContactMutation.isLoading,
    createContactMutation.data,
    createContactMutation.error,
  ]);

  useEffect(() => {
    if (updateContactMutation.isSuccess) {
      fetchAccountInfoQuery.refetch();

      setSelectedContactToEdit(undefined);

      setToastOptions({
        open: true,
        message: "Contact updated successfully",
        severity: "success",
      });
    }

    if (updateContactMutation.isError) {
      setSelectedContactToEdit(undefined);

      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateContactMutation.isSuccess,
    updateContactMutation.isError,
    updateContactMutation.isLoading,
    updateContactMutation.data,
    updateContactMutation.error,
  ]);

  useEffect(() => {
    if (deleteContactMutation.isSuccess) {
      fetchAccountInfoQuery.refetch();

      setSelectedContactToDelete(undefined);
      setToastOptions({
        open: true,
        message: "Contact deleted successfully",
        severity: "success",
      });
    }

    if (deleteContactMutation.isError) {
      setSelectedContactToDelete(undefined);
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteContactMutation.isSuccess,
    deleteContactMutation.isError,
    deleteContactMutation.isLoading,
    deleteContactMutation.data,
    deleteContactMutation.error,
  ]);

  const handleCreate = (contact: AccountContact) => {
    contact.isEmailRequired = contact.isEmailRequired.toString() === "true";
    contact.isSmsRequired = contact.isSmsRequired.toString() === "true";
    contact.isLoginRequired = contact.isLoginRequired
      ? contact.isLoginRequired?.toString() === "true"
      : false;

    createContactMutation.mutate({
      accountId: selectedAccountId || 0,
      orgId: selectedOrgId,
      contact,
    });
    setIsCreateContactComponentActive(false);
  };

  const handleEdit = (
    selectedContact: AccountContact,
    updatedContact: AccountContact
  ) => {
    if (selectedContact !== undefined) {
      updatedContact.isEmailRequired =
        updatedContact.isEmailRequired.toString() === "true";
      updatedContact.isSmsRequired =
        updatedContact.isSmsRequired.toString() === "true";
      updatedContact.isLoginRequired = updatedContact.isLoginRequired
        ? updatedContact.isLoginRequired.toString() === "true"
        : false;

      const changedProps = objectDiff<AccountContact>(
        selectedContact,
        updatedContact
      );

      if (Object.keys(changedProps).length !== 0) {
        updateContactMutation.mutate({
          accountId: selectedAccountId || 0,
          orgId: selectedOrgId,
          id: selectedContact.id,
          ...changedProps,
        });
      } else {
        setToastOptions({
          open: true,
          message: "No changes detected",
          severity: "error",
        });
      }
    }
  };

  const handleDelete = () => {
    if (selectedContactToDelete) {
      deleteContactMutation.mutate({
        accountId: selectedAccountId || 0,
        orgId: selectedOrgId,
        id: selectedContactToDelete.id,
      });
    }
  };

  return (!fetchAccountInfoQuery.isLoading &&
    !fetchAccountInfoQuery.isRefetching &&
    fetchAccountInfoQuery.isSuccess &&
    contactList.length === 0 &&
    !isCreateContactComponentActive) ||
    fetchAccountInfoQuery.error ? (
    <Grid
      size={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      Nothing to show
    </Grid>
  ) : (
    <Grid
      container
      direction={"column"}
      sx={{
        width: "100%",
        height: "100%",
        direction: "row",
        borderRadius: "10px",
      }}
    >
      <Grid
        container
        size={12}
        direction={"column"}
        sx={{
          flexGrow: 1,
          alignItems: "center",
          px: "1px",
        }}
      >
        <Collapse
          sx={{ width: "100%" }}
          in={isCreateContactComponentActive}
          timeout={500}
        >
          <div>
            <CreateContact handleCreate={handleCreate} />
          </div>
        </Collapse>
        {updateContactMutation.isLoading ||
        deleteContactMutation.isLoading ||
        fetchAccountInfoQuery.isFetching ||
        fetchAccountInfoQuery.isLoading ? (
          <>
            <Skeleton
              variant="rounded"
              sx={{
                height: 75.19,
                width: "100%",
                borderRadius: "10px",
                mt: "8px",
                mb: 2,
              }}
            />
            <Skeleton
              variant="rounded"
              sx={{
                height: 75.19,
                width: "100%",
                borderRadius: "10px",
                mb: 2,
              }}
            />
            <Skeleton
              variant="rounded"
              sx={{
                height: 75.19,
                width: "100%",
                borderRadius: "10px",
                mb: 2,
              }}
            />
          </>
        ) : (
          contactList && (
            <ContactList
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              list={contactList || []}
            />
          )
        )}
      </Grid>
    </Grid>
  );
};
