import { Box, Grid2 as Grid } from "@mui/material";
import { SelectBox, TextField } from "../../common";
import { ChargeInformationRow, ModalForm } from "../../../types";
import { useValidateForm } from "../../../hooks";
import { editWashChargeSchema } from "../../../validations";
import { washTypesDropDownAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { WASH_FREQUENCIES } from "../../../constants";

type Props = ModalForm<
  ChargeInformationRow,
  { washTypeId: number } & Omit<ChargeInformationRow, "washType">
>;

export const WashChargeForm = ({ id, defaultValues, handleEdit }: Props) => {
  const [washTypesDropDown] = useAtom(washTypesDropDownAtom);
  const { register, handleSubmit, errors } =
    useValidateForm(editWashChargeSchema);

  const handleEditWrapper = (
    data: { washRate: any; washTypeId: number } & Omit<
      ChargeInformationRow,
      "washRate" | "id" | "washType"
    >
  ) => {
    handleEdit({
      ...data,
      washRate: Number(data.washRate),
      id: defaultValues ? defaultValues.id : 0,
    });
  };

  return (
    <Box
      onSubmit={handleSubmit(handleEditWrapper)}
      component={"form"}
      id={id}
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        size={12}
        direction={"column"}
        gap={3}
        sx={{
          borderRadius: "10px",
          p: 3,
          mb: 2,
          width: "100%",
        }}
      >
        <Grid size={12}>
          {washTypesDropDown && (
            <SelectBox
              label="Wash Type"
              name="washTypeId"
              dropdown={washTypesDropDown}
              register={register}
              error={errors["washTypeId"]}
              defaultValue={defaultValues && defaultValues.id}
              valueAsNumber={true}
            />
          )}
        </Grid>
        <Grid size={12}>
          <TextField
            type={"number"}
            label="Wash Rate"
            name="washRate"
            register={register}
            error={errors["washRate"]}
            defaultValue={defaultValues && defaultValues.washRate}
          />
        </Grid>
        <Grid size={12}>
          <SelectBox
            label="Frequency"
            name="frequency"
            dropdown={WASH_FREQUENCIES}
            register={register}
            error={errors["frequency"]}
            defaultValue={defaultValues && defaultValues.frequency}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="Instructions"
            name="instructions"
            register={register}
            error={errors["instructions"]}
            defaultValue={defaultValues && defaultValues.instructions}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
