import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Button, DateRangePicker, SelectBox } from "../../common";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import {
  DateRange,
  DropdownItem,
  FilterItem,
  WashInventoryFilters,
} from "../../../types";
import {
  selectedOrgIdAtom,
  userAtom,
  washFilterStateAtom,
} from "../../../atoms";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import {
  washInventorySelectedDateRangeAtom,
  washInventoryFiltersAtom,
} from "../../../atoms/washInventoryAtoms";
import { getDateRange } from "../../../utils/dateUtil";
import { DATE_RANGE_OPTIONS } from "../../../constants";
import { Moment } from "moment";
import {
  useFetchAccountsQuery,
  useFetchOrganizationsQuery,
  useFetchWashTypesQuery,
} from "../../../api";
import {
  AccountModel,
  LeadUserModel,
  OrganizationModel,
  WashTypeModel,
} from "../../../models";
import { useFetchLeadsQuery } from "../../../api/userService";
import Loading from "../../common/loading/Loading";
import { Filter } from "../../common/filter";
import { theme } from "../../../utils";
import { MdOutlineFilterAlt } from "react-icons/md";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Role } from "../../../enums";

type WashInventoryToolBarProps = {
  onRefresh?: () => void;
  onDownload?: () => void;
};

export default function WashInventoryToolBar({
  onRefresh,
  onDownload,
}: WashInventoryToolBarProps) {
  const [user] = useAtom(userAtom);
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);

  const [selectedOrgIdFilter, setSelectedOrgIdFilter] = useState(
    user?.orgId || 0
  );

  const fetchWashTypesQuery = useFetchWashTypesQuery(
    selectedOrgIdFilter,
    selectedOrgIdFilter ? true : false
  );
  const fetchLeadUsersQuery = useFetchLeadsQuery(
    selectedOrgIdFilter,
    selectedOrgIdFilter ? true : false
  );
  const fetchAccountsQuery = useFetchAccountsQuery(selectedOrgIdFilter, {
    enabled: selectedOrgIdFilter ? true : false,
  });
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });

  const [washInventoryFilters, setWashInventoryFilters] = useAtom(
    washInventoryFiltersAtom
  );
  const [selectedRange, setSelectedRange] = useAtom(
    washInventorySelectedDateRangeAtom
  );
  const [washTypeDropDown, setWashTypeDropDown] = useState<DropdownItem[]>([]);
  const [leadUserDropDown, setLeadUserDropDown] = useState<DropdownItem[]>([]);
  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const [accountsDropdown, setAccountsDropdown] = useState<DropdownItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterOpen = Boolean(anchorEl);

  const filters = useMemo<FilterItem[]>(() => {
    const baseFilters: FilterItem[] = [
      {
        type: "list",
        label: "User",
        values: leadUserDropDown,
        keyword: "userId",
      },
      {
        type: "list",
        label: "Wash Type",
        values: washTypeDropDown,
        keyword: "washTypeId",
      },
      {
        type: "text",
        label: "Vehicle Number",
        keyword: "vehicleNumber",
      },
    ];

    if (user?.roleCode === Role.SuperAdmin) {
      return [
        {
          type: "list",
          label: "Organization",
          values: organizationsDropdown,
          keyword: "orgId",
        },
        {
          type: "list",
          label: "Account",
          values: accountsDropdown,
          keyword: "accountId",
        },
        ...baseFilters,
      ];
    }
    if (user?.roleCode === Role.Admin) {
      return [
        {
          type: "list",
          label: "Account",
          values: accountsDropdown,
          keyword: "accountId",
        },
        ...baseFilters,
      ];
    }

    return baseFilters;
  }, [
    user?.roleCode,
    leadUserDropDown,
    washTypeDropDown,
    organizationsDropdown,
    accountsDropdown,
  ]);

  const [filterValues, setFilterValues] = useAtom(washFilterStateAtom);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(
    Object.keys(filterValues).length || 0
  );

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const dateRanges: DropdownItem[] = [
    ...DATE_RANGE_OPTIONS.map((option) => ({
      key: option,
      value: option.replace(/\s+/g, "").replace(/^\w/, (c) => c.toLowerCase()),
    })),
  ];

  const handleApplyFilters = () => {
    setAppliedFiltersCount(Object.keys(filterValues).length);
    const appliedFilters: WashInventoryFilters = { ...washInventoryFilters };
    filters.forEach((filter) => {
      const filterKeyword = filter.keyword as keyof WashInventoryFilters;
      const filterValue = filterValues[filter.keyword];
      if (filterKeyword === "orgId") {
        setSelectedOrgId(filterValue);
      }
      if (filterValue !== undefined && filterValue !== null) {
        appliedFilters[filterKeyword] = filterValue;
      } else {
        delete appliedFilters[filterKeyword];
      }
    });
    setWashInventoryFilters(appliedFilters);
    handleFilterClose();
  };

  useEffect(() => {
    if (user?.orgId) {
      setSelectedOrgIdFilter(user.orgId);
    }
  }, [user]);

  useEffect(() => {
    handleApplyFilters();
    return () => {
      const appliedFilters: WashInventoryFilters = {};
      filters.forEach((filter) => {
        const filterKeyword = filter.keyword as keyof WashInventoryFilters;
        const filterValue = filterValues[filter.keyword];
        if (filterKeyword === "orgId") {
          if (filterValue !== undefined && filterValue !== null) {
            appliedFilters[filterKeyword] = filterValue;
          } else {
            delete appliedFilters[filterKeyword];
          }
        }
      });
      setFilterValues({});
      setWashInventoryFilters({ ...washInventoryFilters, ...appliedFilters });
    };
  }, []);

  useEffect(() => {
    if (user?.roleCode === Role.Admin || user?.roleCode === Role.SuperAdmin) {
      setSelectedOrgIdFilter(filterValues["orgId"]);
      setFilterValues((prevValues) => {
        const { orgId } = prevValues;
        return orgId ? { orgId } : {};
      });
    } else setSelectedOrgIdFilter(selectedOrgId);
  }, [filterValues["orgId"]]);

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
      const orgId = fetchOrganizationsQuery.data[0].orgId;
      setSelectedOrgId(orgId);
      setSelectedOrgIdFilter(orgId);
      const filters = {
        ...filterValues,
        orgId: orgId,
      };
      setFilterValues(filters);
      setAppliedFiltersCount(Object.keys(filters).length);
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      const dropdown = AccountModel.buildAccountsDropdown(
        fetchAccountsQuery.data
      );
      setAccountsDropdown(dropdown);
    } else if (fetchAccountsQuery.isError) {
      setAccountsDropdown([]);
    }
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.data,
    fetchAccountsQuery.isError,
  ]);

  useEffect(() => {
    if (fetchWashTypesQuery.isSuccess) {
      const dropdown: DropdownItem[] = WashTypeModel.buildWashTypeDropDown(
        fetchWashTypesQuery.data
      );
      setWashTypeDropDown(dropdown);
    } else if (fetchWashTypesQuery.isError) {
      setWashTypeDropDown([]);
    }
  }, [
    fetchWashTypesQuery.isSuccess,
    fetchWashTypesQuery.isError,
    fetchWashTypesQuery.data,
  ]);

  useEffect(() => {
    if (fetchLeadUsersQuery.isSuccess) {
      const dropdown: DropdownItem[] = LeadUserModel.buildUserDropDown(
        fetchLeadUsersQuery.data
      );
      setLeadUserDropDown(dropdown);
    } else if (fetchLeadUsersQuery.isError) {
      setLeadUserDropDown([]);
    }
  }, [
    fetchLeadUsersQuery.isSuccess,
    fetchLeadUsersQuery.isError,
    fetchLeadUsersQuery.data,
  ]);

  const handleDateChange = (selectedDateRange: string) => {
    setSelectedRange(selectedDateRange);
    if (selectedDateRange !== "customDate") {
      const dateRange: DateRange = getDateRange(selectedDateRange);
      setWashInventoryFilters({
        ...washInventoryFilters,
        fromDate: dateRange.startDate.toString(),
        toDate: dateRange.endDate.toString(),
      });
    }
  };

  const handleCustomDateChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (startDate && endDate)
      setWashInventoryFilters({
        ...washInventoryFilters,
        fromDate: startDate?.format("YYYY-MM-DD"),
        toDate: endDate?.format("YYYY-MM-DD"),
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid2 container alignItems="center" sx={{ marginLeft: "20px" }}>
          <Grid2>
            <SelectBox
              label="Date"
              name="date"
              dropdown={dateRanges}
              value={selectedRange}
              onChange={(e) => handleDateChange(e.target.value)}
              sx={{ width: 170, height: 30 }}
            />
          </Grid2>
          {selectedRange === "customDate" && (
            <Grid2 size={{ lg: 6, sm: 12, xs: 6 }} alignSelf={"end"}>
              <DateRangePicker
                start={null}
                end={null}
                onDateChange={(startDate, endDate) => {
                  handleCustomDateChange(startDate, endDate);
                }}
              />
            </Grid2>
          )}
        </Grid2>
        <Grid2
          container
          alignItems="center"
          sx={{ m: 1 }}
          display="flex"
          justifyContent="space-between"
        >
          <Grid2>
            <Button
              label={"Refresh"}
              variantType={"secondary"}
              icon={AutorenewOutlinedIcon}
              onClick={onRefresh}
              sx={{ width: "120px", height: "30px" }}
            />
          </Grid2>
          <Grid2>
            <Button
              label={"Download"}
              variantType={"secondary"}
              icon={FileDownloadOutlinedIcon}
              sx={{ width: "120px", height: "30px" }}
              onClick={onDownload}
            />
          </Grid2>
          <Grid2>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "60px",
                height: "30px",
                width: "50px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={handleFilterClick}
            >
              <Badge badgeContent={appliedFiltersCount} color="secondary">
                <MdOutlineFilterAlt
                  style={{ fontSize: "22px", color: "white" }}
                />
              </Badge>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <>
          <Filter
            onApplyFilters={handleApplyFilters}
            filters={filters}
            onClose={handleFilterClose}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            preservedFilters={["orgId"]}
          />
          {(fetchWashTypesQuery.isLoading ||
            fetchAccountsQuery.isLoading ||
            fetchLeadUsersQuery.isLoading) && (
            <Loading
              message=" "
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2,
                backgroundColor: "rgba(255, 255, 255, 0.6)",
              }}
            />
          )}
        </>
      </Popover>
    </Box>
  );
}
