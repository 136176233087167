import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useAtom } from "jotai";
import cn from "classnames";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  activeMenuItemAtom,
  drawerOpenAtom,
  contentWidthAtom,
  isCreateAccountModeAtom,
  selectedAccountIdAtom,
  selectedAccountAtom,
  selectedOrgIdAtom,
  isCreateOrganizationModeAtom,
  accountDetailSelectedAtom,
} from "../../atoms";
import { Route } from "../../types";
import { theme } from "../../utils";
import { Link } from "react-router-dom";
import { sprayzAppLogoIconWhite, sprayzAppLogoWhite } from "../../assets";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.secondary.main,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: theme.palette.secondary.main,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type SuperAdminSidebarProps = {
  menu: Route[];
};

export const SuperAdminSidebar = ({ menu }: SuperAdminSidebarProps) => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const [open, setOpen] = useAtom(drawerOpenAtom);
  const [, setContentWidth] = useAtom(contentWidthAtom);
  const [, setIsCreateAccountMode] = useAtom(isCreateAccountModeAtom);
  const [, setIsCreateOrganizationMode] = useAtom(isCreateOrganizationModeAtom);
  const sidebarRef = useRef<HTMLInputElement>(null);
  const [, setActiveMenuItem] = useAtom(activeMenuItemAtom);
  const [activeMenuItem] = useAtom(activeMenuItemAtom);
  const [, setSelectedAccountId] = useAtom(selectedAccountIdAtom);
  const [, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setSelectedAccount] = useAtom(selectedAccountAtom);
  const [, setAccountDetailSelected] = useAtom(accountDetailSelectedAtom);

  useEffect(() => {
    setTimeout(() => {
      const sidebarWidth = sidebarRef.current?.getBoundingClientRect().width;
      const calculatedWidth = `calc(100vw - ${sidebarWidth}px)`;
      setContentWidth(calculatedWidth);
    }, 500);
  }, [isMobile]);

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  const handleNavigate = (item: Route) => {
    setSelectedAccountId(undefined);
    if (item.name !== "accounts") {
      setSelectedOrgId(0);
    }
    setIsCreateAccountMode(false);
    setIsCreateOrganizationMode(false);
    setActiveMenuItem(item);
    setSelectedAccount(undefined);
    setAccountDetailSelected(false);
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }} ref={sidebarRef}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 6,
          }}
        >
          <Box
            component="img"
            sx={{
              width: open ? 168 : 30,
            }}
            src={open ? sprayzAppLogoWhite : sprayzAppLogoIconWhite}
          />
        </DrawerHeader>
        <List
          sx={{
            py: 6,
          }}
        >
          {menu.map(
            (item, index) =>
              item.isVisible !== false && (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <Link
                    to={item.path}
                    onClick={() => {
                      handleNavigate(item);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        position: "relative",
                      }}
                    >
                      {item.name === activeMenuItem?.name && (
                        <Box
                          sx={{
                            height: "100%",
                            backgroundColor: theme.palette.accent.main,
                            width: "4px",
                            position: "absolute",
                            left: 0,
                          }}
                        ></Box>
                      )}
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon &&
                          React.cloneElement(item.icon, {
                            style: {
                              fontSize: "23px",
                              color:
                                item.name === activeMenuItem?.name
                                  ? "white"
                                  : "#a1a1a1",
                            },
                          })}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.displayName}
                        sx={{ opacity: open ? 1 : 0 }}
                        className={cn("sadm-dashboard-menu-item", {
                          active: item.name === activeMenuItem?.name,
                        })}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              )
          )}
        </List>
      </Drawer>
    </Box>
  );
};
