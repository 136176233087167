import { atom } from "jotai";
import { Route } from "../types";
import { BusinessInfo } from "../types";

export const drawerOpenAtom = atom(false);
export const activeMenuItemAtom = atom<Route>();
export const accountInfoAtom = atom<BusinessInfo[]>([]);
export const organizationInfoAtom = atom<BusinessInfo[]>([]);
export const organizationNotExistsFlagAtom = atom(
  (get) => get(organizationInfoAtom).length === 0
);
export const setActiveMenuItemFromPathAtom = atom<
  null,
  [path: string, menus: Route[]],
  void
>(null, (get, set, path, menus) => {
  const menu = menus.find((e) => e.path.split("/")[1] === path.split("/")[1]);

  if (menu) {
    set(activeMenuItemAtom, menu);
  }
});
export const contentWidthAtom = atom("100vw");
