import React, { useState } from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ConfirmationModal } from "../../modal";
import { theme } from "../../../../utils";

type ActionIconsProps = {
  confirmationModalTitle?: string;
  confirmationModalMessage?: string;
  onEdit: (rowData: any) => void;
  onDelete: (rowData: any) => void;
  rowData: any;
};

export const ActionIcons: React.FC<ActionIconsProps> = ({
  confirmationModalTitle = "Delete Item",
  confirmationModalMessage = "Are you sure you want to delete this item?",
  onEdit,
  onDelete,
  rowData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleConfirmDelete = () => {
    onDelete(rowData);
    handleCloseModal();
  };

  return (
    <>
      <Box
        sx={{
          display: "flow",
          width: "50px",
        }}
      >
        <Tooltip title="Edit">
          <IconButton onClick={() => onEdit(rowData)} aria-label="edit">
            <EditOutlinedIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={handleOpenModal} aria-label="delete">
            <DeleteOutlineOutlinedIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={confirmationModalTitle}
        message={confirmationModalMessage}
      />
    </>
  );
};
