import { useMutation, useQuery } from "react-query";
import { client } from "./axios";
import { SignedUrlInfo } from "../types";
import { DEFAULT_QUERY_OPTIONS } from "../constants";

export const useFetchSignedUrlForUpload = (
  path: string,
  fetchOnMount: boolean = false
) => {
  const fetchSignedUrl = async (): Promise<SignedUrlInfo> => {
    const response = await client.get<any>(path);
    return response.data;
  };

  return useQuery(["upload", path], fetchSignedUrl, {
    enabled: fetchOnMount,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useFetchSignedUrlUploadStatus = (
  statusUrl: string,
  signedUrlKey: string
) => {
  return useQuery(
    ["uploadStatus", signedUrlKey],
    async () => {
      const response = await client.get(`${statusUrl}?uuid=${signedUrlKey}`);
      return response.data;
    },
    {
      enabled: !!signedUrlKey,
      retry: false,
    }
  );
};

export const useUploadFileToS3Mutation = () => {
  const mutation = useMutation(
    async (data: { file: File; signedUrl: string }) => {
      const { file, signedUrl } = data;

      const response = await client.put(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
          Authorization: false,
        },
      });
      return response;
    }
  );

  return mutation;
};
