import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  Box,
  Typography,
  Button,
  Link,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { LoginCredentials } from "../../types";
import { loginSchema } from "../../validations";
import { useValidateForm } from "../../hooks";
import { useLoginMutation } from "../../api";
import { userAtom, selectedOrgIdAtom } from "../../atoms";
import { useNavigate } from "react-router-dom";
import { TextField } from "../common";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getDashboardPath } from "../../utils";
import { Link as RouterLink } from "react-router-dom";
import { AuthPageLayout } from "../layouts";

const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [, setUserData] = useAtom(userAtom);
  const [, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const { register, handleSubmit, errors } = useValidateForm(loginSchema);
  const { mutate, isSuccess, isError, data, error, isLoading } =
    useLoginMutation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setUserData(data);
      setSelectedOrgId(data.orgId);
      setLoading(false);
      navigate(getDashboardPath(data));
    }

    if (isError) {
      setErrorMessage("Invalid username or password");
      setLoading(false);
    }

    if (isLoading) {
      setLoading(true);
    }
  }, [isSuccess, isError, data, error, isLoading]);

  const handleLogin = async ({ username, password }: LoginCredentials) => {
    mutate({ username, password });
  };

  return (
    <AuthPageLayout
      content={
        <Box component="form" noValidate onSubmit={handleSubmit(handleLogin)}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              mb: 1,
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            Welcome Back
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              mb: 3,
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            Please enter your login details
          </Typography>
          <Grid size={12} container spacing={4}>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <TextField
                labelVariant="body2"
                labelColor="textSecondary"
                label="Username"
                name="username"
                register={register}
                error={errors["username"]}
                required
                labelSx={{
                  marginBottom: "0px",
                }}
                autoComplete="username"
                autoFocus
              />
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <TextField
                labelVariant="body2"
                labelColor="textSecondary"
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                register={register}
                error={errors["password"]}
                required
                labelSx={{
                  marginBottom: "0px",
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff fontSize="small" />
                          ) : (
                            <Visibility fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              mb: 0,
              mt: 1,
            }}
          >
            <Link
              component={RouterLink}
              to="/forgot-password"
              variant="body2"
              sx={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Forgot Password?
            </Link>
          </Box>
          <Box
            sx={{
              paddingTop: "1rem",
              width: "100%",
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                mt: 1,
                py: 1,
                width: "100%",
                borderRadius: 8,
                textTransform: "none",
                fontWeight: "bold",
                mb: 2,
              }}
            >
              {loading ? "Signing In..." : "Sign In"}
            </Button>
          </Box>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              padding: "0.8rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {errorMessage && (
              <Typography
                variant="body2"
                align="center"
                sx={{
                  color: "#da2902",
                  position: "absolute",
                  top: 0,
                }}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>
        </Box>
      }
    />
  );
};

export default Login;
