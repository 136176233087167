import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"];
    button: Palette["primary"];
  }

  interface PaletteOptions {
    accent?: PaletteOptions["primary"];
    button?: PaletteOptions["primary"];
    error?: PaletteOptions["primary"];
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: "jaf-cupidus",
  },
  palette: {
    primary: {
      main: "#3e8bd2",
      light: "#3e8bd233",
    },
    secondary: {
      main: "#01214f",
      light: "#fff",
    },
    accent: {
      main: "#9ed642",
    },
    button: {
      main: "#ffffff",
      light: "#3E8BD2",
    },
    error: {
      main: "#f44336",
    },
  },
});
