import {
  Box,
  Divider,
  Grid2 as Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { InvoicePaymentStatus, PaymentMode } from "../../../enums";
import { InvoicePaymentStatusChip } from "../../common/chips";
import { PdfViewer } from "../../common/pdf-viewer";
import { DropdownItem, InvoiceOverviewInfo } from "../../../types";
import { addDays, formatDate, hasDatePassed } from "../../../utils";
import { Button, SelectBox, TextField } from "../../common";
import { useState } from "react";
import { isReceivePaymentFormActiveAtom } from "../../../atoms";
import { useAtom } from "jotai";

type InvoiceOverviewProps = {
  data: InvoiceOverviewInfo;
};

const paymentOptionsDropdown: DropdownItem[] = [
  { key: "Cash", value: PaymentMode.Cash },
  { key: "Check", value: PaymentMode.Check },
  { key: "Debit / Credit card", value: PaymentMode.Card },
];

export const InvoiceOverview = ({ data }: InvoiceOverviewProps) => {
  const dueDate = formatDate(addDays(data.invoiceDate, 15));
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(
    paymentOptionsDropdown[0].value
  );
  const [isReceivePaymentFormActive] = useAtom(isReceivePaymentFormActiveAtom);

  return (
    <Grid
      container
      size={12}
      direction={{ xs: "column", md: "row" }}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      spacing={3}
    >
      <Grid
        container
        size={{ xs: 12, md: 6 }}
        sx={{ px: 3 }}
        gap={3}
        direction="column"
      >
        <Grid
          container
          size={12}
          sx={{ height: "fit-content", display: "flex", gap: 1 }}
        >
          <InvoicePaymentStatusChip
            // TODO: check if paid
            status={
              hasDatePassed(dueDate)
                ? InvoicePaymentStatus.Overdue
                : InvoicePaymentStatus.Pending
            }
          ></InvoicePaymentStatusChip>
        </Grid>
        <Grid container size={12} spacing={3}>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Total Amount
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                $ {data.invoiceAmount}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Wash Charges
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                $ {data.totalWashCharge}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Misc Charges
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                $ {data.totalMiscCharge}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Due Date
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                {dueDate}
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ lg: 4, md: 6, xs: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Paid On
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                -
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {isReceivePaymentFormActive && (
          <>
            <Divider />
            <Grid container size={12} spacing={3} direction={"column"}>
              <Grid size={4}>
                <SelectBox
                  label="Payment Options"
                  name="paymentOption"
                  dropdown={paymentOptionsDropdown}
                  value={selectedPaymentOption}
                  onChange={(e) => {
                    setSelectedPaymentOption(e.target.value);
                  }}
                />
              </Grid>
              {selectedPaymentOption === PaymentMode.Cash && (
                <Grid
                  container
                  size={12}
                  sx={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Grid
                    size={8}
                    sx={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <TextField
                      type={"number"}
                      label="Enter amount"
                      name="paymentOption"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    size={4}
                    sx={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <Button
                      label={"Pay"}
                      icon={PaymentIcon}
                      sx={{
                        width: "fit-content",
                        borderRadius: 0,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        container
        size={{ xs: 12, md: 6 }}
        sx={{
          boxShadow: "0px 0px 14px 0px #00000040",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PdfViewer url={"/sample-files/invoice-sample.pdf"} />
      </Grid>
    </Grid>
  );
};
