import { Role } from "../enums";
import { Route, User } from "../types";
import {
  AdminDashboardRoute,
  AdminInvoiceDetailsRoute,
  ChangePasswordRoute,
  CustomerDashboardRoute,
  CustomerInvoiceDetailsRoute,
  LoginRoute,
  SadmDashboardRoute,
  SadmInvoiceDetailsRoute,
} from "../routes";

export const getDashboardPath = (user: User | null): string => {
  let path = LoginRoute.path;

  if (!user) {
    return path;
  }

  const { id, orgId, roleCode, isPasswordReset } = user;

  if (isPasswordReset) {
    path = ChangePasswordRoute.path;
    return path
      .replace(":orgId", orgId.toString())
      .replace(":userId", id.toString());
  }

  switch (roleCode) {
    case Role.SuperAdmin:
      return SadmDashboardRoute.path;
    case Role.Admin:
      return AdminDashboardRoute.path;
    default:
      return CustomerDashboardRoute.path;
  }
};

export const buildQueryString = <T>(options: T) => {
  const queryParams = [];

  if (!options) return "";

  for (const key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      const value = options[key];
      if (value !== undefined) {
        queryParams.push(`${key}=${value}`);
      }
    }
  }

  return "?" + queryParams.join("&");
};

export const getInvoiceDetailsRoute = (user: User): Route => {
  if (user.roleCode === Role.SuperAdmin) {
    return SadmInvoiceDetailsRoute;
  } else if (user.roleCode === Role.Admin) {
    return AdminInvoiceDetailsRoute;
  } else {
    return CustomerInvoiceDetailsRoute;
  }
};
