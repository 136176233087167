import { Box, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import cn from "classnames";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  accountsAtom,
  contentWidthAtom,
  customerDashboardDrawerOpenAtom,
  selectedAccountAtom,
  selectedOrgIdAtom,
} from "../../atoms";
import { theme } from "../../utils";
import { Route } from "../../types";
import { selectedAccountIdAtom, activeMenuItemAtom } from "../../atoms";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import {
  defaultProfileImage,
  sprayzAppLogoIconWhite,
  sprayzAppLogoWhite,
} from "../../assets";
import { Link } from "react-router-dom";
import { useFetchAccountsQuery } from "../../api";
import { AutoRetryImage } from "../common/image/AutoRetryImage";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.secondary.main,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: theme.palette.secondary.main,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type CustomerSidebarProps = {
  menu: Route[];
};

export const CustomerSidebar = ({ menu }: CustomerSidebarProps) => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const sidebarRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeMenuItem, setActiveMenuItem] = useAtom(activeMenuItemAtom);
  const [open, setOpen] = useAtom(customerDashboardDrawerOpenAtom);
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const [selectedAccount, setSelectedAccount] = useAtom(selectedAccountAtom);
  const [, setContentWidth] = useAtom(contentWidthAtom);
  const [currentAccountId, setCurrentAccountId] = useAtom(
    selectedAccountIdAtom
  );
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const isMenuOpen = Boolean(anchorEl);

  const fetchAccountsQuery = useFetchAccountsQuery(selectedOrgId, {
    enabled: selectedOrgId ? true : false,
  });

  useEffect(() => {
    if (selectedOrgId) {
      fetchAccountsQuery.refetch();
    }
  }, [selectedOrgId]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      setAccounts(fetchAccountsQuery.data);
    }

    if (fetchAccountsQuery.isError) {
      setAccounts([]);
    }
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.isError,
    fetchAccountsQuery.data,
    fetchAccountsQuery.isLoading,
  ]);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSelectAccount = (id: number) => {
    const selected = accounts.find((account) => account.accountId === id);
    if (selected) {
      setSelectedAccount(selected);
      setCurrentAccountId(id);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    setTimeout(() => {
      const sidebarWidth = sidebarRef.current?.getBoundingClientRect().width;
      const calculatedWidth = `calc(100vw - ${sidebarWidth}px)`;
      setContentWidth(calculatedWidth);
    }, 500);
  }, [isMobile]);

  useEffect(() => {
    setCurrentAccountId(accounts[0]?.accountId);
    setSelectedAccount(accounts[0]);
  }, [accounts]);

  const handleNavigate = (item: Route) => {
    setActiveMenuItem(item);
  };

  return (
    <Box sx={{ display: "flex" }} ref={sidebarRef}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: "1px solid #e5e5e58c",
              width: "100%",
            }}
          >
            {selectedAccount?.logo && (
              <AutoRetryImage
                sx={{
                  width: open ? 60 : 54,
                  height: open ? 60 : 54,
                  objectFit: "contain",
                }}
                src={selectedAccount.logo}
                alt={`${selectedAccount?.accountName} Logo`}
                defaultImage={defaultProfileImage}
              />
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleToggle}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#fff",
                  lineHeight: "42px",
                  textAlign: "center",
                }}
              >
                {open ? selectedAccount?.accountName : ""}
              </Typography>

              <KeyboardArrowDownOutlined
                sx={{
                  transform: isMenuOpen
                    ? "rotate(180deg)"
                    : open
                    ? "rotate(0deg)"
                    : "rotate(270deg)",
                  transition: "transform 0.3s ease-in-out",
                  marginLeft: open ? "8px" : "38px",
                  color: "#fff",
                }}
              />
            </Box>
            {!open && (
              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    width: "200px",
                    backgroundColor: theme.palette.secondary.main,
                    color: "#fff",
                    top: "100px !important",
                    left: "64px !important",
                  },
                  "& .css-99wn1p-MuiList-root-MuiMenu-list": {
                    padding: "0px",
                  },
                }}
              >
                {accounts.map((account) => (
                  <MenuItem
                    key={account.accountId}
                    onClick={() => handleSelectAccount(account.accountId)}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                      },
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                  >
                    <span>
                      {account.accountName.length > 15
                        ? `${account.accountName.slice(0, 15)}...`
                        : account.accountName}
                    </span>
                    <span
                      style={{
                        color:
                          account.accountId === currentAccountId
                            ? "#fff"
                            : account.status === "active"
                            ? theme.palette.accent.main
                            : theme.palette.error.main,
                      }}
                    >
                      {account.accountId === currentAccountId
                        ? "Current"
                        : account.status &&
                          account.status.charAt(0).toUpperCase() +
                            account.status.slice(1)}
                    </span>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Box>
        </DrawerHeader>
        <List
          sx={{
            py: 2,
          }}
        >
          {!isMenuOpen
            ? menu.map(
                (item, index) =>
                  item.isVisible !== false && (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <Link
                        to={item.path}
                        onClick={() => {
                          handleNavigate(item);
                        }}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            position: "relative",
                          }}
                        >
                          {item.name === activeMenuItem?.name && (
                            <Box
                              sx={{
                                height: "100%",
                                backgroundColor: theme.palette.accent.main,
                                width: "4px",
                                position: "absolute",
                                left: 0,
                              }}
                            ></Box>
                          )}
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {item.icon &&
                              React.cloneElement(item.icon, {
                                style: {
                                  fontSize: "23px",
                                  color:
                                    item.name === activeMenuItem?.name
                                      ? "white"
                                      : "#a1a1a1",
                                },
                              })}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.displayName}
                            sx={{ opacity: open ? 1 : 0 }}
                            className={cn("sadm-dashboard-menu-item", {
                              active: item.name === activeMenuItem?.name,
                            })}
                          />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  )
              )
            : accounts.map((account, index) => (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      position: "relative",
                    }}
                    onClick={() => {
                      handleSelectAccount(account.accountId);
                    }}
                  >
                    {account.accountId === currentAccountId && open && (
                      <Box
                        sx={{
                          height: "100%",
                          backgroundColor: theme.palette.accent.main,
                          width: "4px",
                          position: "absolute",
                          left: 0,
                        }}
                      ></Box>
                    )}
                    <ListItemText
                      primary={
                        account.accountName.length > 15
                          ? `${account.accountName.slice(0, 15)}...`
                          : account.accountName
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                      className={cn("sadm-dashboard-menu-item", {
                        active: account.accountId === currentAccountId,
                      })}
                    />
                    <ListItemText
                      primary={
                        account.accountId === currentAccountId
                          ? "Current"
                          : account.status &&
                            account.status.charAt(0).toUpperCase() +
                              account.status.slice(1)
                      }
                      sx={{
                        opacity: open ? 1 : 0,
                        display: "flex",
                        flexDirection: "row-reverse",
                        color:
                          account.accountId === currentAccountId
                            ? "#fff"
                            : account.status === "active"
                            ? theme.palette.accent.main
                            : theme.palette.error.main,
                      }}
                      className={cn("sadm-dashboard-menu-item", {
                        active: account.accountId === currentAccountId,
                      })}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
        </List>
        <Box
          component="img"
          sx={{
            width: open ? 168 : 30,
            objectFit: "contain",
            position: "relative",
            bottom: 0,
            top: 0,
            right: "50%",
            left: "50%",
            marginTop: "auto",
            marginBottom: "50px",
            transform: "translateX(-50%)",
          }}
          src={open ? sprayzAppLogoWhite : sprayzAppLogoIconWhite}
        />
      </Drawer>
    </Box>
  );
};
