import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  Paper,
  Typography,
  Avatar,
  styled,
  Skeleton,
} from "@mui/material";
import { IoSend } from "react-icons/io5";
import { formatDate } from "../../utils";
import { UserTooltip } from "../common";
import { Message } from "../../types";

const MessagesArea = styled(Box)({
  flex: 1,
  overflowY: "auto",
  padding: "20px",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#bdbdbd",
    borderRadius: "3px",
  },
});

interface MessageBubbleProps {
  isOwn: boolean;
}

const MessageBubble = styled(Paper)<MessageBubbleProps>(({ isOwn }) => ({
  padding: "12px 16px",
  marginBottom: "12px",
  maxWidth: "100%",
  borderRadius: "16px",
  backgroundColor: isOwn ? "#1976d2" : "#ffffff",
  color: isOwn ? "#ffffff" : "#000000",
  alignSelf: isOwn ? "flex-end" : "flex-start",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  position: "relative",
  animation: "fadeIn 0.3s ease-in",
  "@keyframes fadeIn": {
    from: { opacity: 0, transform: "translateY(10px)" },
    to: { opacity: 1, transform: "translateY(0)" },
  },
}));

const MessageContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  marginBottom: "20px",
});

type ChatProps = {
  isLoading?: boolean;
  messages?: Message[];
  messagePlaceholder?: string;
  handleSend: (message: string) => void;
};

export const Chat = ({
  isLoading,
  messages,
  messagePlaceholder = "Type a message",
  handleSend,
}: ChatProps) => {
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Box sx={{ height: "100%" }}>
      <MessagesArea
        sx={{
          height: "100%",
        }}
      >
        {isLoading || !messages
          ? Array(4)
              .fill(0)
              .map((e, index) => (
                <MessageContainer
                  key={index}
                  sx={{
                    flexDirection: "row",
                  }}
                >
                  <Skeleton variant="rounded" width={"100%"} height={60} />
                </MessageContainer>
              ))
          : messages.map((message, index) => (
              <MessageContainer
                key={index}
                sx={{
                  flexDirection: message.isOwn ? "row-reverse" : "row",
                }}
              >
                <UserTooltip
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Avatar
                        src={message.profilePic}
                        alt={`${message.isOwn ? "User" : "Contact"} profilePic`}
                        sx={{ width: 25, height: 25 }}
                      />
                      <Typography variant="body1">
                        {message.userName}
                      </Typography>
                    </Box>
                  }
                  arrow
                  placement={message.isOwn ? "top-end" : "top-start"}
                >
                  <Avatar
                    src={message.profilePic}
                    alt={`${message.isOwn ? "User" : "Contact"} profilePic`}
                    sx={{ width: 40, height: 40 }}
                  />
                </UserTooltip>
                <Box sx={{ maxWidth: "100%" }}>
                  <MessageBubble isOwn={message.isOwn}>
                    <Typography variant="body1">{message.text}</Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        display: "block",
                        textAlign: "right",
                        mt: 1,
                        opacity: 0.7,
                      }}
                    >
                      {formatDate(message.timestamp, "YYYY/MM/DD, HH:mm")}
                    </Typography>
                  </MessageBubble>
                </Box>
              </MessageContainer>
            ))}

        <div ref={messagesEndRef} />
      </MessagesArea>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          padding: "16px",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
        }}
        component="form"
        onClick={(e) => {
          e.preventDefault();
          if (newMessage.trim()) {
            handleSend(newMessage);
            setNewMessage("");
          }
        }}
        role="region"
        aria-label="Message input area"
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder={messagePlaceholder}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          size="small"
          aria-label="Message input field"
        />
        <IconButton
          color="primary"
          type="submit"
          aria-label="Send message"
          sx={{
            backgroundColor: "#1976d2",
            color: "#ffffff",
            "&:hover": { backgroundColor: "#1565c0" },
          }}
        >
          <IoSend />
        </IconButton>
      </Box>
    </Box>
  );
};
