import { useQuery } from "react-query";
import { config } from "../config";
import { SuperAdminDashboardResponse } from "../types";
import { client } from "./axios";

export const useFetchSuperAdminDashboardQuery = (
  orgId: number,
  cutOffDate?: string,
  fetchOnMount: boolean = true
) => {
  const fetchSuperAdminDashboard =
    async (): Promise<SuperAdminDashboardResponse> => {
      let path = config.api.reportService.getSuperAdminDashboard.replace(
        "{orgId}",
        orgId.toString()
      );
      if (cutOffDate) path += `?cutOffDate=` + cutOffDate;
      const response = await client.get<any>(path);
      return response.data.response;
    };

  return useQuery(["user", orgId], fetchSuperAdminDashboard, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useFetchUserDashboardQuery = (
  orgId: number,
  accountId: number,
  cutOffDate?: string,
  fetchOnMount: boolean = true
) => {
  const fetchUserDashboard = async (): Promise<SuperAdminDashboardResponse> => {
    let path = config.api.reportService.getUserDashboard
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());
    if (cutOffDate) path += `?cutOffDate=` + cutOffDate;
    const response = await client.get<any>(path);
    return response.data.response;
  };

  return useQuery(["user", orgId, accountId], fetchUserDashboard, {
    retry: false,
    enabled: fetchOnMount,
  });
};
