import React from "react";
import { Box, Typography } from "@mui/material";
import Loading from "../loading/Loading";
import { Modal } from "./Modal";
import { Button } from "../button";
import { downloadFile } from "../../../utils";

type PdfViewModalProps = {
  open: boolean;
  onClose: () => void;
  linkToPdfData: string;
  loader: boolean;
  fileName: string;
};

export const PreviewModal: React.FC<PdfViewModalProps> = ({
  open,
  onClose,
  linkToPdfData,
  loader,
  fileName,
}) => {
  const downloadPdf = () => {
    downloadFile(linkToPdfData, fileName);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={""}
      content={
        <Box
          sx={{
            bgcolor: "background.paper",
          }}
        >
          {loader ? (
            <Loading message="Loading..." sx={{ minHeight: "80vh" }} />
          ) : (
            <Box mt={2}>
              <Box
                display="flex"
                flexDirection={{
                  sm: "column",
                  lg: "row",
                  md: "column",
                  xs: "column",
                }}
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography>
                  If you are unable to view the PDF, please download it from
                  here.
                </Typography>
                <Button
                  onClick={downloadPdf}
                  label={"Download PDF"}
                  sx={{ height: "30px", mt: 1 }}
                />
              </Box>

              <iframe
                style={{
                  width: "100%",
                  height: "600px",
                  border: "none",
                }}
                src={linkToPdfData}
                title="PDF Preview"
              />
            </Box>
          )}
        </Box>
      }
      sx={{
        modal: { width: { lg: "60%", xs: "80%", sm: "80%" }, height: "90vh" },
      }}
      primaryButtonText={""}
    />
  );
};
