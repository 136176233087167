import { useMutation, useQuery } from "react-query";
import { config } from "../config";
import {
  ApiError,
  ChangePasswordPayload,
  CustomerInfo,
  ForgotPasswordPayload,
  LeadUser,
} from "../types";
import { client } from "./axios";
import { AxiosError, AxiosResponse } from "axios";

export const useFetchLeadsQuery = (
  orgId: number,
  fetchOnMount: boolean = true
) => {
  const fetchLeads = async (): Promise<LeadUser[]> => {
    const path = config.api.userService.getAllLeads.replace(
      "{orgId}",
      orgId.toString()
    );
    const response = await client.get<any>(path);
    return response.data.response;
  };

  return useQuery(["user", orgId], fetchLeads, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useFetchUserQuery = (
  orgId: number,
  userId: number,
  fetchOnMount: boolean = true
) => {
  const fetchUser = async (): Promise<CustomerInfo> => {
    const path = config.api.userService.getUser
      .replace("{orgId}", orgId.toString())
      .replace("{userId}", userId.toString());
    const response = await client.get<any>(path);
    return response.data.response[0];
  };

  return useQuery(["user", orgId, userId], fetchUser, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useUpdateUserProfileMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    { orgId: number } & CustomerInfo,
    unknown
  >(async (data: { orgId: number } & CustomerInfo) => {
    const path = config.api.userService.updateUser
      .replace("{orgId}", data.orgId.toString())
      .replace("{userId}", data.id.toString());

    const response = await client.patch(path, data);
    return response;
  });

  return mutation;
};

export const useUpdateUserProfilePicMutation = () => {
  const mutation = useMutation(
    async ({
      id,
      orgId,
      profilePic,
    }: {
      id: number;
      orgId: number;
      profilePic: string;
    }) => {
      const path = config.api.userService.updateUserProfilePic
        .replace("{orgId}", orgId.toString())
        .replace("{userId}", id.toString());

      const response = await client.post(path, { profilePic });
      return response;
    }
  );

  return mutation;
};

export const useChangeUserPasswordMutation = () => {
  const mutation = useMutation(
    async ({
      orgId,
      id,
      data,
    }: {
      orgId: number;
      id: number;
      data: ChangePasswordPayload;
    }) => {
      const path = config.api.userService.changePassword
        .replace("{orgId}", orgId.toString())
        .replace("{id}", id.toString());

      const response = await client.post(path, data);
      return response;
    }
  );

  return mutation;
};

export const useForgotUserPasswordMutation = () => {
  const mutation = useMutation(async (data: ForgotPasswordPayload) => {
    const path = config.api.userService.forgotPassword;
    const response = await client.post(path, data);
    return response;
  });

  return mutation;
};

export const useValidateResetPasswordToken = (
  token: string,
  fetchOnMount: boolean = true
) => {
  const fetchValidateResetPasswordToken = async (): Promise<{
    orgId: number;
    userId: number;
  }> => {
    const path =
      config.api.userService.validateResetPasswordToken + "?token=" + token;
    const response = await client.get<any>(path);
    return response.data;
  };

  return useQuery(["user"], fetchValidateResetPasswordToken, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useResetUserPasswordMutation = () => {
  const mutation = useMutation(
    async ({
      orgId,
      userId,
      data,
    }: {
      orgId: number;
      userId: number;
      data: ChangePasswordPayload;
    }) => {
      const path = config.api.userService.resetPassword
        .replace("{orgId}", orgId.toString())
        .replace("{id}", userId.toString());
      const response = await client.post(path, data);
      return response;
    }
  );

  return mutation;
};
