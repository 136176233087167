export const objectDiff = <T extends Record<string, unknown>>(
  obj1?: Partial<T>,
  obj2?: Partial<T>
): Partial<T> => {
  if (!obj2) {
    return {};
  }

  if (!obj1) {
    return obj2;
  }

  return Object.keys(obj2).reduce((acc, prop) => {
    if (
      Object.prototype.hasOwnProperty.call(obj1, prop) &&
      obj1[prop] !== obj2[prop]
    ) {
      return { ...acc, [prop]: obj2[prop] };
    }
    return acc;
  }, {});
};
