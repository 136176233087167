import { Grid2 as Grid } from "@mui/material";
import { TextField } from "../../common";
import { Button } from "../../common";
import { OrganizationIntegrationsInfo } from "../../../types";
import { useValidateForm } from "../../../hooks";
import { editQuickbooksIntegrationSchema } from "../../../validations";
import { useState } from "react";

type EditQuickbooksIntegrationInfoProps = {
  data: OrganizationIntegrationsInfo;
  handleEdit: (data: any) => void;
  handleDiscard: () => void;
};

export const EditQuickbooksIntegrationInfo = ({
  data,
  handleEdit,
  handleDiscard,
}: EditQuickbooksIntegrationInfoProps) => {
  const [hasChanges, setHasChanges] = useState(false);

  const { register, handleSubmit, errors, reset } = useValidateForm(
    editQuickbooksIntegrationSchema
  );

  const handleChange = () => {
    setHasChanges(true);
  };

  return (
    <Grid
      container
      component={"form"}
      onSubmit={handleSubmit(handleEdit)}
      noValidate
      size={{ sm: 12, md: 6 }}
      direction={"column"}
      spacing={4}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        p: 4,
        position: "relative",
        justifyContent: "space-between",
      }}
    >
      <Grid container size={12}>
        <Grid container size={12}>
          <TextField
            label="Quickbooks Client ID"
            name="quickBooksClientId"
            register={register}
            error={errors["quickBooksClientId"]}
            defaultValue={data.quickbooks?.quickBooksClientId}
            onChange={handleChange}
          />
        </Grid>
        <Grid container size={12}>
          <TextField
            label="Quickbooks Realm ID"
            name="quickBooksRealmId"
            register={register}
            error={errors["quickBooksRealmId"]}
            defaultValue={data.quickbooks?.quickBooksRealmId}
            onChange={handleChange}
          />
        </Grid>
        <Grid container size={12}>
          <TextField
            label="Quickbooks Secret ID"
            name="quickBooksSecretId"
            register={register}
            error={errors["quickBooksSecretId"]}
            defaultValue={data.quickbooks?.quickBooksSecretId}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        size={{ xs: 12 }}
        flexDirection={{ xs: "column", md: "row" }}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Grid size={{ xs: 12, md: 6 }}>
          <Button
            label="Discard"
            variantType="secondary"
            sx={{
              padding: "0.5rem",
              width: "100%",
            }}
            onClick={() => {
              reset();
              handleDiscard();
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Button
            type={hasChanges ? "submit" : undefined}
            disabled={!hasChanges}
            label={"Save Changes"}
            sx={{
              padding: "0.5rem",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
