import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/material";
import { DatePicker } from "./DatePicker";
import { useState } from "react";
import { Moment } from "moment";

type DateRangePickerProps = {
  start: Moment | null;
  end: Moment | null;
  onDateChange: (startDate: Moment | null, endDate: Moment | null) => void;
};

export const DateRangePicker = ({
  start,
  end,
  onDateChange,
}: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const handleDateChange = (
    newStartDate: Moment | null,
    newEndDate: Moment | null
  ) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onDateChange(newStartDate, newEndDate);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
          <DatePicker
            label=""
            placeHolder="From"
            value={start}
            onChange={(newStartDate) => handleDateChange(newStartDate, endDate)}
            name={""}
          />
          <DatePicker
            label=""
            placeHolder="To"
            value={end}
            onChange={(newEndDate) => handleDateChange(startDate, newEndDate)}
            name={""}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
