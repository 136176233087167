import React from "react";
import { Container, Box, Divider } from "@mui/material";
import logoImg from "../../assets/images/logo-sprayz.png";
import loginImg from "../../assets/images/login_img.png";

type AuthPageLayoutProps = {
  content: React.ReactNode;
};

export const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({ content }) => {
  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        px: 2,
        py: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: { xs: "100%", md: "80%", lg: "80%" },
          boxShadow: 3,
          borderRadius: 2,
          overflow: "hidden",
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "3rem 1rem",
            mx: { xs: 2, md: 4, lg: 12 },
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              width: "102%",
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <img
              src={logoImg}
              alt="Logo"
              style={{ height: "60px", width: "auto" }}
            />
          </Box>
          <Divider sx={{ width: "100%", mb: 5 }} />
          {content}
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flex: 1,
            backgroundImage: `url(${loginImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Box>
    </Container>
  );
};
