export const EditIcon = ({
  width = "27",
  height = "27",
  style = {},
  active = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M15.5294 6.05882L7.41176 14.1765V19.5882H12.8235L20.9412 11.4706M15.5294 6.05882L19.5882 2L25 7.41176L20.9412 11.4706M15.5294 6.05882L20.9412 11.4706M10.1176 3.35294H2V25H23.6471V16.8824"
        stroke={active ? "#3e8bd2" : "#a1a1a1"}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
