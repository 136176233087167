import { Box, Grid2 as Grid } from "@mui/material";
import { AccountMenuItem } from "../../types";
import { AccountProfile } from "../profile";
import {
  accountActiveMenuItemAtom,
  isCreateLocationComponentActiveAtom,
  isCreateContactComponentActiveAtom,
  selectedAccountIdAtom,
  isCreateAccountModeAtom,
} from "../../atoms";
import { useAtom } from "jotai";
import { Contact } from "../contact";
import { Billing } from "../billing";
import { Location } from "../location";
import { useEffect } from "react";
import { PriceDetails } from "../price-details";
import { Fleet } from "../fleet";
import { AccountAccordion } from "./AccountAccordion";
import { Invoice } from "../invoice";
import { useLocation, useParams } from "react-router-dom";
import { SadmCreateAccountRoute } from "../../routes";

const accountMenus: AccountMenuItem[] = [
  {
    name: "profile",
    displayName: "Profile",
    component: <AccountProfile />,
  },
  {
    name: "contact",
    displayName: "Contact",
    component: <Contact />,
    allowCreate: true,
  },
  {
    name: "billing",
    displayName: "Billing",
    component: <Billing />,
  },
  {
    name: "location",
    displayName: "Location",
    component: <Location />,
    allowCreate: true,
  },
  {
    name: "fleet",
    displayName: "Fleet",
    component: <Fleet />,
  },
  {
    name: "priceDetails",
    displayName: "Price Details",
    component: <PriceDetails />,
  },
  {
    name: "invoices",
    displayName: "Invoices",
    component: <Invoice />,
  },
];

export const accountMenuDefault: AccountMenuItem = accountMenus[0];

export const AccountDetail = () => {
  const [, setSelectedAccountId] = useAtom(selectedAccountIdAtom);
  const [, setIsCreateAccountMode] = useAtom(isCreateAccountModeAtom);
  const [activeMenuItem] = useAtom(accountActiveMenuItemAtom);
  const [, setLocationCreateComponentActive] = useAtom(
    isCreateLocationComponentActiveAtom
  );
  const [, setContactCreateComponentActive] = useAtom(
    isCreateContactComponentActiveAtom
  );
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.split("/").pop() === SadmCreateAccountRoute.path) {
      setIsCreateAccountMode(true);
    } else {
      setSelectedAccountId(Number(params.accountId));
    }
  }, [params]);

  useEffect(() => {
    if (activeMenuItem.name === "location") {
      setLocationCreateComponentActive(false);
    } else if (activeMenuItem.name === "contact") {
      setContactCreateComponentActive(false);
    }
  }, [activeMenuItem]);

  return (
    <Box
      sx={{
        minHeight: "715px",
        display: "flex",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Grid
        container
        direction={"column"}
        spacing={2}
        gap={0}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          direction: "row",
          py: "1rem",
        }}
      >
        {accountMenus.map((menu, index) => (
          <AccountAccordion key={index} menu={menu} />
        ))}
      </Grid>
    </Box>
  );
};
