import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  SelectChangeEvent,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { BusinessInfoTile } from "../common/tiles";
import { useAtom } from "jotai";
import {
  selectedOrgIdAtom,
  organizationsDropdownItemsAtom,
  selectedAccountIdAtom,
  userAtom,
  toastOptionsAtom,
} from "../../atoms";
import { ChangeEvent, useEffect, useState } from "react";
import { BusinessViewToolbar } from "../toolbar";
import {
  useDeleteAccountMutation,
  useFetchAccountsQuery,
  useFetchOrganizationsQuery,
} from "../../api";
import { AccountModel, OrganizationModel } from "../../models";
import { Role } from "../../enums";
import { ConfirmationModal } from "../common";
import { theme } from "../../utils";
import { useNavigate } from "react-router-dom";
import { BusinessInfo } from "../../types";
import { ROUTE_CREATE_ACCOUNT } from "../../routes";

export const AccountLayout = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [user] = useAtom(userAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useAtom(
    selectedAccountIdAtom
  );
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [accounts, setAccounts] = useState<BusinessInfo[]>();
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [organizationsDropdown, setOrganizationsDropdown] = useAtom(
    organizationsDropdownItemsAtom
  );
  const fetchAccountsQuery = useFetchAccountsQuery(selectedOrgId);
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode !== Role.Admin,
  });
  const deleteAccountMutation = useDeleteAccountMutation();
  const filteredAccounts = (accounts || []).filter((item) =>
    item.name.toLowerCase().includes(searchTerm)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
      setSelectedOrgId(fetchOrganizationsQuery.data[0].orgId);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      const accounts = AccountModel.buildAccountBusinessInfo(
        fetchAccountsQuery.data
      );
      setAccounts(accounts);
    }
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.isError,
    fetchAccountsQuery.data,
    fetchAccountsQuery.error,
    fetchAccountsQuery.isLoading,
  ]);

  useEffect(() => {
    if (deleteAccountMutation.isSuccess) {
      fetchAccountsQuery.refetch();

      setToastOptions({
        open: true,
        message: "Account deleted successfully",
        severity: "success",
      });
    }

    if (deleteAccountMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteAccountMutation.isSuccess,
    deleteAccountMutation.isError,
    deleteAccountMutation.isLoading,
    deleteAccountMutation.data,
    deleteAccountMutation.error,
  ]);

  const handleClick = (accountId: number) => {
    navigate(accountId.toString());
  };

  const handleCreate = () => {
    navigate(ROUTE_CREATE_ACCOUNT);
  };

  const handleChangeDropdown = (event: SelectChangeEvent) => {
    setSelectedOrgId(Number(event.target.value));
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setSelectedAccountId(undefined);
    setIsModalOpen(false);
  };

  const handleClickDelete = (id: number) => {
    setSelectedAccountId(id);
    handleOpenModal();
  };

  const handleConfirmDelete = () => {
    if (selectedAccountId) {
      deleteAccountMutation.mutate({
        accountId: selectedAccountId,
        orgId: selectedOrgId,
      });
      handleCloseModal();
    }
  };

  return (
    <>
      <Box
        sx={{
          height: "fit-content",
          minHeight: "80vh",
          display: "flex",
          width: "100%",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <BusinessViewToolbar
          addLabel={"account"}
          dropdown={organizationsDropdown}
          dropdownValue={selectedOrgId}
          dropdownOnChange={handleChangeDropdown}
          handleCreate={handleCreate}
          handleSearch={handleSearch}
        />
        <Grid
          container
          direction={"row"}
          spacing={2}
          sx={{
            width: "100%",
            height: "100%",
            flexGrow: 1,
            alignItems:
              fetchAccountsQuery.isLoading ||
              deleteAccountMutation.isLoading ||
              fetchAccountsQuery.isRefetching ||
              (fetchAccountsQuery.isSuccess && !accounts) ||
              fetchAccountsQuery.isError
                ? "center"
                : "none",
            alignContent:
              fetchAccountsQuery.isLoading ||
              deleteAccountMutation.isLoading ||
              fetchAccountsQuery.isRefetching ||
              (fetchAccountsQuery.isSuccess && !accounts) ||
              fetchAccountsQuery.isError
                ? "none"
                : "baseline",
          }}
        >
          {fetchAccountsQuery.isLoading ||
          fetchAccountsQuery.isFetching ||
          fetchAccountsQuery.isRefetching ||
          deleteAccountMutation.isLoading ||
          (fetchAccountsQuery.isSuccess && !accounts) ? (
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (fetchAccountsQuery.isSuccess &&
              accounts &&
              accounts.length === 0) ||
            !accounts ||
            fetchAccountsQuery.isError ? (
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              Nothing to show
            </Grid>
          ) : (
            filteredAccounts.map((item, index) => (
              <Grid key={index} size={{ lg: 4, md: 6, sm: 6, xs: 12, xl: 3 }}>
                <BusinessInfoTile
                  data={item}
                  handleClick={handleClick}
                  handleDelete={handleClickDelete}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={"Delete Account"}
        message={"Are you sure that you want to delete this account?"}
      />
    </>
  );
};
