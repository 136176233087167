import { atom } from "jotai";
import { OrganizationMenuItem } from "../types";
import { organizationMenuDefault } from "../components/organization";
import { activeMenuItemAtom } from "./superAdminDashboardAtoms";

export const organizationActiveMenuItemAtom = atom<OrganizationMenuItem>(
  organizationMenuDefault
);
export const isCreateOrganizationModeAtom = atom(false);
export const organizationDetailSelectedAtom = atom(false);
export const isOrganizationProfileBodyEditModeAtom = atom(false);
export const isOrganizationProfileModeAtom = atom((get) => {
  const activeMenuItem = get(activeMenuItemAtom);

  return activeMenuItem?.name === "organizations";
});
