import * as React from "react";
import MuiTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabItem } from "../../../types";
import { theme } from "../../../utils";
import { CircularProgress } from "@mui/material";

type TabsProps = {
  tabItems: TabItem[];
  value: number;
  isLoading?: boolean;
  defaultTab?: string;
  onTabChange: (value: number) => void;
};

export const Tabs = ({
  tabItems,
  value,
  isLoading,
  defaultTab = "Default",
  onTabChange,
}: TabsProps) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  if (isLoading)
    return (
      <Box
        sx={{
          width: "100%",
          minHeight: "48px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CircularProgress size={"1.5rem"} />
      </Box>
    );

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.paper,
      }}
    >
      <MuiTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {defaultTab && tabItems.length === 0 && (
          <Tab
            label={defaultTab}
            sx={{
              fontWeight: "700",
              color: "#000000 !important",
              pb: 0,
              textTransform: "none",
            }}
          />
        )}
        {tabItems.map((item, index) => (
          <Tab
            key={index}
            label={item.key}
            sx={{
              fontWeight: item.value === value ? "700" : "400",
              color:
                item.value === value
                  ? theme.palette.primary.main
                  : "#000000 !important",
              pb: 0,
              textTransform: "none",
            }}
            value={item.value}
          />
        ))}
      </MuiTabs>
    </Box>
  );
};
